import { Cloud } from "../../constants/action-constants/CloudActionConstants";

const initialState = {
  //pre signed url
  preSignedUrl: "",
  preSignedUrlLoading: false,
  preSignedUrlError: "",

  // Video upload to s3
  videoUploadToS3Loading: false,
  videoUploadToS3Error: "",

  // label detection job id
  labelDetectionJobIdLoading: false,
  labelDetectionJobId: "",
  labelDetectionJobIdError: "",

  // label detection results
  labelDetectionResults: {},
  labelDetectionLoading: false,
  labelDetectionError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Cloud.GET_PRESIGNED_URL:
      return {
        ...state,
        preSignedUrlLoading: true,
      };

    case Cloud.GET_PRESIGNED_URL_SUCCESS:
      return {
        ...state,
        preSignedUrl: action.payload,
        preSignedUrlLoading: false,
      };

    case Cloud.GET_PRESIGNED_URL_FAILURE:
      return {
        ...state,
        preSignedUrlError: action.payload,
        preSignedUrlLoading: false,
      };

    case Cloud.UPLOAD_VIDEO_TO_S3:
      return {
        ...state,
        videoUploadToS3Loading: true,
      };

    case Cloud.UPLOAD_VIDEO_TO_S3_SUCCESS:
      return {
        ...state,
        videoUploadToS3Loading: false,
      };

    case Cloud.UPLOAD_VIDEO_TO_S3_FAILURE:
      return {
        ...state,
        videoUploadToS3Error: action.payload,
        videoUploadToS3Loading: false,
      };

    case Cloud.START_LABEL_DETECTION:
      return {
        ...state,
        labelDetectionJobIdLoading: true,
      };

    case Cloud.START_LABEL_DETECTION_SUCCESS:
      return {
        ...state,
        labelDetectionJobId: action.payload,
        labelDetectionJobIdLoading: false,
      };

    case Cloud.START_LABEL_DETECTION_FAILURE:
      return {
        ...state,
        labelDetectionJobIdError: action.payload,
        labelDetectionJobIdLoading: false,
      };

    case Cloud.GET_LABEL_DETECTION_RESULTS:
      return {
        ...state,
        labelDetectionLoading: true,
      };

    case Cloud.GET_LABEL_DETECTION_RESULTS_SUCCESS:
      return {
        ...state,
        labelDetectionResults: action.payload,
        labelDetectionLoading: false,
      };

    case Cloud.GET_LABEL_DETECTION_RESULTS_FAILURE:
      return {
        ...state,
        labelDetectionError: action.payload,
        labelDetectionLoading: false,
      };

    default:
      return state;
  }
};
