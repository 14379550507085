import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  selectTgAttribute,
  unSelectTgAttribute,
} from "../../actions/campaign-planning/CampaignPlanningActions";

// Utils And Constants
import { DataLoading, HeaderWithActionButton } from "./PlanningUtils";
import { DefaultTgName } from "../../../constants/GeneralConstants";
import { TargetGroupKeys } from "./TabConstants";

// Components
import CheckBox from "../../../components/check-box/CheckBox";

// construct Resulting PoiLayers and TgBean
function constructResPoiLayersAndTgBean(
  selectedTgAttributesMap = {},
  poiNameToLayerMap = {},
  tgList = []
) {
  if (Object.keys(selectedTgAttributesMap).length === 0) {
    return { resPoiLayers: [], tgBean: {} };
  }

  // all selected tg attributes into single array from map
  const allTgAttributeSelected = [];
  Object.values(selectedTgAttributesMap).forEach((tgAttr) =>
    allTgAttributeSelected.push(...tgAttr)
  );

  // data required
  const resPoiLayers = [];
  const tgBeanLayers = [];

  // getting filter "resPoiLayers"
  allTgAttributeSelected.forEach((eachTgAttr) => {
    const { tgAttrId, tgId = "" } = eachTgAttr;

    // Other than "Behavioral"
    if (tgId !== TargetGroupKeys.behavioral) {
      Object.keys(poiNameToLayerMap).forEach((poiNameToLayerKey) => {
        if (poiNameToLayerKey.includes(tgAttrId)) {
          const layerInfo = poiNameToLayerMap[poiNameToLayerKey] || {};
          const { poiTypeId } = layerInfo || {};

          // push to resPoiLayers
          resPoiLayers.push(poiTypeId);

          // push to tgBeanLayers
          tgBeanLayers.push(layerInfo);
          return;
        }
      });
      return;
    }

    // for "Behavioral"
    const selectedTg = tgList.find((eachTg) => eachTg.id === tgAttrId);
    const { layers } = selectedTg || {};
    const selectedTgLayers = layers ?? [];

    selectedTgLayers.forEach((layer) => {
      const { attributeId, attributeType, displayType, poiTypeId, type } =
        layer || {};

      // push to resPoiLayers
      resPoiLayers.push(poiTypeId);

      // required layer info for tgBean
      const requiredLayerInfo = {
        attributeId,
        attributeType,
        displayType,
        poiTypeId,
        type,
      };

      // push to tgBeanLayers
      tgBeanLayers.push(requiredLayerInfo);
    });
  });

  // construct tgBean
  const tgBean = {
    name: DefaultTgName,
    layers: tgBeanLayers,
  };

  // return resPoiLayers & tgBean
  return { resPoiLayers, tgBean };
}

// get Selected Tg Attributes
function getSelectedTgAttributes(targetGroupData = [], selectedTgId = "") {
  const tgAttributes = [];

  if (targetGroupData.length === 0 || !selectedTgId) {
    return tgAttributes;
  }

  targetGroupData.forEach((tg) => {
    const { id, attributes = [] } = tg;
    if (id === selectedTgId) {
      tgAttributes.push(...attributes);
    }
  });

  return tgAttributes;
}

// TgListSection
function TgListSection({
  targetGroupData = [],
  selectedTgId,
  selectedTgAttributesMap = {},
  handleSelectTg = () => {},
}) {
  return (
    <div className="px-3">
      {targetGroupData.map((targetGroup) => {
        const { id, name, attributes = [] } = targetGroup;
        const isTgSelected = selectedTgId === id;
        const selectedClassName = isTgSelected ? "bg-alt rounded-lg" : "";
        const isTgAttributesPresent = attributes.length > 0;
        const selectedTg = selectedTgAttributesMap[id] || [];
        return (
          <div
            key={id}
            className={`d-flex align-items-center justify-content-between p-2 cursor-pointer my-2 ${selectedClassName}`}
            onClick={() => handleSelectTg(id, name)}
          >
            <p className="mb-0">{name}</p>
            <p className="mb-0">
              {selectedTg.length > 0 && (
                <span className="mr-2">({selectedTg.length})</span>
              )}
              {isTgAttributesPresent && (
                <i
                  className={`fa ${
                    isTgSelected
                      ? "fa-angle-down text-primary"
                      : "fa-angle-right"
                  }`}
                />
              )}
            </p>
          </div>
        );
      })}
    </div>
  );
}

// TgAttributesListSection
function TgAttributesListSection({
  targetGroupData = [],
  selectedTgAttributesMap = {},
  selectedTgId,
  selectedTgName,
}) {
  const dispatch = useDispatch();

  // tg attr for selected tg
  const tgAttrForTgSelected = selectedTgAttributesMap[selectedTgId] || [];
  const selectedTgAttrIds = tgAttrForTgSelected.map(
    (tgAttr) => tgAttr.tgAttrId
  );

  // selected Tg Attributes
  const selectedTgAttributes = getSelectedTgAttributes(
    targetGroupData,
    selectedTgId
  );

  // handle Checkbox Select TgAttr
  function handleCheckboxSelectTgAttr(e, id, name) {
    const { checked } = e.target;

    // construct tg attribute
    const tgAttribute = {
      tgId: selectedTgId,
      tgName: selectedTgName,
      tgAttrId: id,
      tgAttrName: name,
    };

    // dispatch to select/unselect attr
    const dispatchFn = checked ? selectTgAttribute : unSelectTgAttribute;
    dispatch(dispatchFn(tgAttribute));
  }

  return (
    <div className="col-6 px-0 border-left">
      <h5 className="my-1 px-3 py-2">{selectedTgName}</h5>
      <hr className="my-0" />

      {selectedTgAttributes.map((attr) => {
        const { id, name } = attr;
        const isTgAttrSelected = selectedTgAttrIds.includes(id);
        const selectedClassName = isTgAttrSelected ? "bg-alt rounded-lg" : "";
        return (
          <div
            key={id}
            className={`d-flex align-items-center justify-content-between p-2 cursor-pointer m-2 ${selectedClassName}`}
          >
            <p className="mb-0" title={name}>
              {name}
            </p>
            <CheckBox
              boxStyle="mb-0"
              checkBoxKey={id}
              showLabel={false}
              checked={selectedTgAttrIds.includes(id)}
              onChange={(e, id) => handleCheckboxSelectTgAttr(e, id, name)}
            />
          </div>
        );
      })}
    </div>
  );
}

// Target Groups List
function TargetGroupsList({
  targetGroupData = [],
  selectedTgAttributesMap = {},
  isTgSectionLoading = false,
}) {
  // States
  const [selectedTgId, setSelectedTgId] = useState("");
  const [selectedTgName, setSelectedTgName] = useState("");

  // handle select tg
  function handleSelectTg(id, name) {
    setSelectedTgId(id);
    setSelectedTgName(name);
  }

  // Loading
  if (isTgSectionLoading) {
    return <DataLoading dataType="Target Group Attributes" />;
  }

  return (
    <div className="w-100 d-flex border rounded-lg overflow-hidden">
      <div className="col-6 px-0 border-right">
        <h5 className="my-1 px-3 py-2">Target Group Attributes</h5>
        <hr className="my-0" />

        {/* TG List */}
        <TgListSection
          targetGroupData={targetGroupData}
          selectedTgId={selectedTgId}
          selectedTgAttributesMap={selectedTgAttributesMap}
          handleSelectTg={handleSelectTg}
        />
      </div>

      {/* Tg Attributes */}
      {selectedTgId && (
        <TgAttributesListSection
          targetGroupData={targetGroupData}
          selectedTgAttributesMap={selectedTgAttributesMap}
          selectedTgId={selectedTgId}
          selectedTgName={selectedTgName}
        />
      )}
    </div>
  );
}

// get Formatted Behavioral Tg items
function getBehavioralTg(tgList = []) {
  if (tgList.length === 0) {
    return [];
  }

  const formattedList = tgList.map((tg) => {
    const { id, name } = tg;
    return { labelId: id, label: name, children: [] };
  });

  return {
    labelId: TargetGroupKeys.behavioral,
    label: TargetGroupKeys.behavioral,
    children: formattedList,
  };
}

/**
 * construct tg data
 * @param {*} tgAttrList : tg attributes list
 * @returns =>
 * { id: "gender",
 *   name: "Gender",
 *   attributes: [ { id: "male", name: "Male" }, { id: "female", name: "Female" } ],
 * }
 */
function constructTargetGroupData(data = []) {
  if (data.length === 0) {
    return [];
  }

  const constructedData = data.map((eachData) => {
    const { children = [], label, labelId } = eachData;
    return {
      id: labelId,
      name: label,
      attributes: constructTargetGroupData(children),
    };
  });

  return constructedData;
}

/**
 * TG TAB
 */
export default function TargetGroupTab({
  handleTabHeaderBtnClick = () => {},
  tabHeaderBtnLoading = false,
}) {
  // TG List Data & Loading
  const tgList = useSelector((state) => state.orgTargetGroup.tgList);
  const tgListLoading = useSelector(
    (state) => state.orgTargetGroup.targetGroupLoading
  );

  // Tg Attr
  const tgAttrLoading = useSelector(
    (state) => state.orgTargetGroup.isGetTgAttrLoading
  );
  const poiNameToLayerMap = useSelector(
    (state) => state.orgTargetGroup.poiNameToLayerMap
  );
  const tgAttrList = useSelector(
    (state) => state.orgTargetGroup.dataListWithIncomeGroup
  );

  // formatting the tgList for rendering
  const behavioralTg = getBehavioralTg(tgList);

  // final tg data
  const targetGroupData = constructTargetGroupData([
    ...tgAttrList,
    behavioralTg,
  ]);

  // selected tg attr map
  const selectedTgAttributesMap = useSelector(
    (state) => state.campaignPlanning.selectedTgAttributesMap
  );
  const isTgSelected = Object.keys(selectedTgAttributesMap).length > 0;

  // section loading
  const isTgSectionLoading = tgAttrLoading || tgListLoading;

  // handle Heatmap Button Click
  function handleGenerateHeatmapClick() {
    // construct resPoiLayers & TgBean
    const { resPoiLayers, tgBean } = constructResPoiLayersAndTgBean(
      selectedTgAttributesMap,
      poiNameToLayerMap,
      tgList
    );

    // callback fn
    handleTabHeaderBtnClick(resPoiLayers, tgBean);
  }

  return (
    <>
      {/* Header Section */}
      <HeaderWithActionButton
        title="Target Group"
        buttonText="Generate Heatmap"
        onButtonClick={handleGenerateHeatmapClick}
        disabled={!isTgSelected}
        buttonLoading={tabHeaderBtnLoading}
      />

      {/* List */}
      <TargetGroupsList
        targetGroupData={targetGroupData}
        selectedTgAttributesMap={selectedTgAttributesMap}
        isTgSectionLoading={isTgSectionLoading}
      />
    </>
  );
}
