// Constants
import { PoiCountByPoiType } from "../constants/PoiCountByPoiTypeConstants";

// get Total Poi Count For Tg poiTypes
export function getTotalPoiCountForTgPoiTypes(tgInfo) {
  const poiTypesMap = tgInfo.poiTypesMap || {};

  let totalPoiCount = 0;
  Object.keys(poiTypesMap).forEach((poiTypeId) => {
    const poiTypePoiCount = PoiCountByPoiType[poiTypeId] || 0;
    totalPoiCount = totalPoiCount + poiTypePoiCount;
  });

  return totalPoiCount;
}

// get Total Poi Count For All Poi Types
export function getTotalPoiCountForAllPoiTypes() {
  const totalPoiCountForAllPoiTypes = Object.values(PoiCountByPoiType).reduce(
    (acc, curr) => acc + curr,
    0
  );

  return totalPoiCountForAllPoiTypes;
}
