import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

// Constants
import { Status } from "../../../constants/GeneralConstants";

// Components
import {
  filterDataList,
  getSelectedIndex,
  getTitle,
  isSubSectionOpen,
  getTgTag,
} from "../../../components/nested-checklist/nestedCheckListUtil";

// Page Components
/**
 * @param {{isSelected: boolean, tag: string}} props
 */
function Tag({ isSelected, selectedCount, totalCount }) {
  return (
    <div>
      {(isSelected || selectedCount > 0) && (
        <span>{`${selectedCount}/${totalCount}`}</span>
      )}
      <FontAwesomeIcon
        className="element-container__icon"
        icon={faAngleRight}
      />
    </div>
  );
}

function SelectBrandButton({ data, onAddButton }) {
  const { labelId, status } = data;

  // Functions
  function onClickAction(e, status) {
    e.stopPropagation();
    onAddButton(labelId, status);
  }

  if (status === Status.CHECKED) {
    return (
      <button
        type="button"
        className="added-button btn shadow-none btn-outline-success"
        onClick={(e) => {
          onClickAction(e, Status.UNCHECKED);
        }}
      >
        <i className="fa fa-check"></i> <b>Added</b>
      </button>
    );
  }
  return (
    <button
      type="button"
      className="add-button btn shadow-none btn-outline-primary"
      onClick={(e) => onClickAction(e, Status.CHECKED)}
    >
      <b>Add</b>
    </button>
  );
}

function ListElement({ data, index, onLabelClick, onAddButton }) {
  const key = `${data.label}_${index}`;

  const { label, isSelected } = data;
  const hasChildren = data.children.length > 0;
  const { checkedCount, totalCount } = getTgTag(data.children);
  //const loading = isLoading(data, label, brandLoadingMap);

  return (
    <div
      key={key}
      className={`d-flex justify-content-between py-2 ${
        isSelected ? "bg-alt" : ""
      }`}
      onClick={() => {
        onLabelClick && onLabelClick(label);
      }}
    >
      <div className="form-check">{label}</div>
      {data.level === 3 && (
        <SelectBrandButton data={data} onAddButton={onAddButton} />
      )}
      {/* {loading && <Spinner className="poi__spinner ml-1" />} */}
      {hasChildren && (
        <Tag
          isSelected={isSelected}
          selectedCount={checkedCount}
          totalCount={totalCount}
        />
      )}
    </div>
  );
}

function CollapseList({ data, index, onLabelClick, onAddButton }) {
  const [rightArrow, setRightArrow] = useState(true);
  const dataLabel = data.label;
  function changeCollapseIcon() {
    rightArrow ? setRightArrow(false) : setRightArrow(true);
  }
  return (
    <div
      key={`${dataLabel}_${index}`}
      className="panel-group"
      id={`accordion_${index}`}
    >
      <div className="panel panel-default">
        <div className="panel-heading p-2 border">
          <FontAwesomeIcon
            className="collapse__icon"
            icon={rightArrow ? faAngleRight : faAngleDown}
            data-toggle="collapse"
            data-parent={`#accordion_${index}`}
            href={`#collapse_${index}`}
            onClick={changeCollapseIcon}
          />
          <span className="collapse__display px-1">{dataLabel}</span>
        </div>
        <div
          id={`collapse_${index}`}
          className="panel-collapse p-2 collapse in"
        >
          {data.children.map((eachData) => (
            <ListElement
              key={eachData.label}
              data={eachData}
              index={index}
              onLabelClick={onLabelClick}
              onAddButton={onAddButton}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

/**
 * @param {{
 *    dataList: {label: string, isSelected: boolean, status: string}[],
 *    onLabelClick: (label: string) => void,
 *    onStatusChange: (label: string, isChecked: boolean) => void,
 * }} props
 */
function List({ dataList, onLabelClick, onAddButton }) {
  if (!dataList || !dataList.length) {
    return null;
  }

  return dataList.map((data, index) => {
    const { type } = data;
    if (type) {
      return (
        <CollapseList
          key={index}
          index={index}
          data={data}
          onLabelClick={onLabelClick}
          onAddButton={onAddButton}
        />
      );
    }
    return (
      <ListElement
        key={index}
        data={data}
        index={index}
        onLabelClick={onLabelClick}
        onAddButton={onAddButton}
      />
    );
  });
}

function CreateTgForm({ data, title, path, setSelected, setAdded }) {
  const dataList = filterDataList(data, path);
  const selectedIndex = getSelectedIndex(dataList);
  const subSectionOpen = isSubSectionOpen(selectedIndex, dataList);
  const titleToBeDisplayed = getTitle(selectedIndex, dataList);

  return (
    <div className="list border">
      <div className="d-flex">
        <div className={`${title ? "col-12 px-3" : "col-4 px-0 border-right"}`}>
          {title && <h3 className="py-2 border-bottom">{title}</h3>}
          {/* TG listing */}
          <List
            dataList={dataList}
            onLabelClick={(label) => setSelected(selectedIndex, label)}
            onAddButton={(labelId, status) => {
              setAdded(labelId, status);
            }}
          />
        </div>

        {/* nested listing of attributes */}
        {subSectionOpen && (
          <CreateTgForm
            data={dataList}
            title={titleToBeDisplayed}
            path={selectedIndex}
            setSelected={setSelected}
            setAdded={setAdded}
          />
        )}
      </div>
    </div>
  );
}

CreateTgForm.defaultProps = {
  path: {},
};

export default CreateTgForm;
