// constants
import { PoiTypes } from "../constants/GeneralConstants";

// sdk
import AtlasClient from "../sdk/AtlasClient";

// export async function getLocationSummaryFn(latitude, longitude, distance) {
//   const url = getLocationSummaryUrl
//     .replace(":lat", latitude)
//     .replace(":lng", longitude)
//     .replace(":radius", distance);
//   return sendGetRequest(url);
// }

export async function getSummaryGeoNear(latitude, longitude, radius) {
  return await AtlasClient.summaryGeoNear(latitude, longitude, radius);
}

export async function getPointsGeoNearNearest(latitude, longitude, radius) {
  const poiFilters = {
    types: PoiTypes,
  };
  return await AtlasClient.pointsGeoNearNearest(
    latitude,
    longitude,
    radius,
    poiFilters
  );
}
