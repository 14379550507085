import { ActionTypes } from "../../constants/ActionConstants";

export const updateCampaignDuration = (dateObj) => ({
  type: ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_PLAN_DATES,
  payload: { startDate: dateObj.startDate, endDate: dateObj.endDate },
});

export const getBasicCampaignDetails = (campaignId) => ({
  type: ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO,
  payload: { campaignId },
});

export const resetCampaignDetails = () => ({
  type: ActionTypes.CampaignDetails.RESET_CAMPAIGN_DETAILS,
});

export const updateCampaignState = (campaignId, state, history) => ({
  type: ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE,
  payload: { campaignId, state, history },
});

export const getCampaignList = (searchText, pageNo, pageSize) => ({
  type: ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST,
  payload: { searchText, pageNo, pageSize },
});
