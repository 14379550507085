import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import {
  getSummaryGeoNear,
  getPointsGeoNearNearest,
} from "../../apis/LocationSummaryAPI";

// Constants
import { LocationSummary } from "../../constants/action-constants/LocationSummaryActionConstants";

export function* getLocationSummary(action) {
  try {
    const { latitude, longitude, distance } = action.payload;

    const summaryGeoNear = yield getSummaryGeoNear(
      latitude,
      longitude,
      distance
    );
    const nearByPois = yield getPointsGeoNearNearest(
      latitude,
      longitude,
      distance
    );
    const locationSummaryInfo = { ...summaryGeoNear, nearByPois };

    // dispatching action
    yield put({
      type: LocationSummary.GET_LOCATION_SUMMARY_SUCCESS,
      payload: locationSummaryInfo,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: LocationSummary.GET_LOCATION_SUMMARY_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(LocationSummary.GET_LOCATION_SUMMARY, getLocationSummary),
  ]);
}
