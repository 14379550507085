import { Buyer } from "../../constants/action-constants/BuyerActionConstants";

export const getBuyersList = (keyWord = "", pageNumber, pageSize) => ({
  type: Buyer.GET_BUYERS_LIST,
  payload: { keyWord, pageNumber, pageSize },
});

export const clearBuyersList = () => ({
  type: Buyer.CLEAR_BUYERS_LIST,
});

export const getBuyerDetails = (buyerId) => ({
  type: Buyer.GET_BUYER_DETAILS,
  payload: { buyerId },
});

export const createBuyer = (buyerBean) => ({
  type: Buyer.CREATE_BUYER,
  payload: { buyerBean },
});

export const updateBuyer = (buyerId, buyerBean) => ({
  type: Buyer.UPDATE_BUYER,
  payload: { buyerId, buyerBean },
});

export const getUsersByBuyerId = (buyerId) => ({
  type: Buyer.GET_USERS_BY_BUYER_ID,
  payload: { buyerId },
});

export const clearBuyerUsersList = () => ({
  type: Buyer.CLEAR_BUYER_USERS,
});

export const openAddAndEditBuyerForm = (processDataObject) => ({
  type: Buyer.OPEN_ADD_AND_EDIT_BUYER_FORM,
  payload: { processDataObject },
});

export const closeAddAndEditBuyerForm = () => ({
  type: Buyer.CLOSE_ADD_AND_EDIT_BUYER_FORM,
});
