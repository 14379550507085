import { useDispatch, useSelector } from "react-redux";

// Actions
import { closeViewMediaImagesForm } from "../../actions/media-selection/MediaSelectionActions";

// Constants
import { ProohFormDataTargets } from "../../constants/GeneralConstants";

// Components
import Carousel from "../../../components/carousel/Carousel";

/**
 * Media Images View Form
 */
export default function MediaImagesViewForm() {
  const dispatch = useDispatch();

  // data
  const processDataObject = useSelector(
    (state) => state.mediaSelection.processDataObject
  );
  const { media } = processDataObject || {};
  const { fileItems = [], title } = media || {};

  const urls =
    fileItems && fileItems.length
      ? fileItems.map((fileItem) => fileItem.originalUrl)
      : [];

  return (
    <div
      className="modal fade"
      id={ProohFormDataTargets.viewMediaImagesFrom}
      data-backdrop="static"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">Media Images</h4>
          </div>

          {/* modal body */}
          <div className="modal-body px-5">
            <p className="text-truncate" title={title}>
              Name :: {title}
            </p>
            <Carousel urls={urls} canDownloadImage={false} />
          </div>

          {/* Form Footer */}
          <div className="modal-footer border-0 px-5">
            <button
              type="button"
              className="btn btn-outline-primary rounded-lg shadow-none"
              data-dismiss="modal"
              onClick={() => dispatch(closeViewMediaImagesForm())}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
