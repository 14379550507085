import { getItem } from "../../utils/localstorage";
import { OrgType } from "../../constants/OrgTypeConstants";
import { LocalStorageKeys } from "../../constants/GeneralConstants";
import { checkIsBuyerUser } from "../../utils/BuyerUtils";

// Validates whether the OriginalComponent is to be rendered or not
// If the Organization Type id Media Agency then will render the component..
export const isAgencySpecific = (OriginalComponent) => {
  const organizationType = getItem(LocalStorageKeys.ORG_TYPE);

  if (organizationType === OrgType.MEDIA_AGENCY) {
    return OriginalComponent;
  }
  return null;
};

// Validates whether the OriginalComponent is to be rendered or not
// If the user belong to buyer then don't render the component..
export const isBuyerUserSpecific = (OriginalComponent) => {
  const isBuyerUser = checkIsBuyerUser();

  if (!isBuyerUser) {
    return OriginalComponent;
  }

  return null;
};
