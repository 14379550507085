import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

// Actions
import {
  getTargetGroups,
  getTgInfo,
  resetTgGroup,
} from "../../../actions/org/OrgTargetGroupActions";
import { getRegionNames } from "../../../actions/regions/RegionActions";
import { openTargetGroupForm } from "../../actions/campaign-planning/TargetGroupFormActions";
import { resetCityRoadStretches } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";
import { resetTgSpecificOts } from "../../actions/campaign-planning/TgSpecificOtsActions";
import {
  getRegionsData,
  getTgSpecificOtsForSegments,
  getTgSpecificOtsForCity,
  resetCampaignPlanning,
} from "../../actions/campaign-planning/CampaignPlanningActions";
import { resetCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { resetCampaignPlanDetails } from "../../../actions/campaign-plan/CampaignPlanActions";
import { removeTgHeatMapData } from "../../../actions/geo-data/GeoDataActions";
import { openPoiSelectionForm } from "../../actions/campaign-planning/PoiSelectionFormActions";
import { clearSelectedRegionAndZonesMap } from "../../actions/campaign-planning/ZonesAndSubZonesActions";
import { resetBrandInfo } from "../../../actions/map-view/poi/PoiSelectionActions";
import { clearRouteTypesSelection } from "../../../actions/org/OrgRouteTypesActions";

// Constants and Utils
import { defaultPagination } from "../../../constants/UrlConstants";
import { useRestoreCampaignPlan } from "../../utils/HooksUtil";
import {
  DefaultTgName,
  FormDataTargets,
} from "../../../constants/GeneralConstants";
import {
  useGetPoiHierarchy,
  useGetRegionListByType,
  useGetRouteTypes,
  useGetTgAttributes,
  useGetTouchPoints,
  useUpdateRoadStretchesSelection,
} from "../../../mavin/utils/hooks/HooksUtil";
import { getMergedCityId } from "../../../pages/map-view/CityInfoTempFixUtil";
import { useSortableData } from "./SortingUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";
import SingleCitySelectionForm from "../../../mavin/components/single-city-selection-form/SingleCitySelectionForm";
import TargetGroupForm from "../../../components/target-group-form/TargetGroupForm";

// Page Sections
import PlanningMapView from "./PlanningMapView";
import PlanningFooterSection from "./PlanningFooterSection";
import PlanningHeaderSection from "./PlanningHeaderSection";
import RoadStretchTable from "./RoadStretchTable";
import CostAndOtsSection from "./CostAndOtsSection";
import { AddCaptiveAreaSection, LoadingRoadStretches } from "./PlanningUtils";
import PoiAndCaptiveAreaForm from "./AddPoiAreaForm";
import { PoiSelectionForm } from "./PoiSelectionForm";
import PlanningMapTabsSection from "./PlanningMapTabsSection";
import TabContentPreviewForm from "./TabContentPreviewForm";

// Page-Component
function TargetGroupFormSection() {
  const selectedTgName = useSelector((state) => state.orgTargetGroup.tgName);

  //tg select loader
  const tgSelectLoading = useSelector((state) => state.tgSpecificOts.loading);

  const dispatch = useDispatch();
  function openTgFormFunction() {
    dispatch(openTargetGroupForm());
  }

  return (
    <div>
      <p className="mb-1">{"Target-Group"}</p>
      <div className="d-flex">
        <button
          type="button"
          className="btn border rounded-lg shadow-none px-2"
          data-toggle="modal"
          data-target={`#${FormDataTargets.targetGroupForm}`}
          onClick={openTgFormFunction}
        >
          {selectedTgName ? selectedTgName : "Select TG"}
        </button>
        {tgSelectLoading && <Spinner className="ml-2" />}
      </div>
    </div>
  );
}

function PoiSelectionFormSection() {
  const dispatch = useDispatch();

  // Fetching Selected Pois
  const getSelectedPoisLoading = useSelector(
    (state) => state.poiSelectionForm.getSelectedPoisLoading
  );

  return (
    <div className="ml-3">
      <p className="mb-1 ml-3">{"Poi"}</p>
      <div className="d-flex">
        <button
          type="button"
          className="btn border rounded-lg shadow-none px-2"
          data-toggle="modal"
          data-target="#poi-selection-form"
          onClick={() => dispatch(openPoiSelectionForm())}
        >
          {"Select Poi"}
        </button>
        {getSelectedPoisLoading && <Spinner className="ml-2" />}
      </div>
    </div>
  );
}

function getQueryParams(search) {
  const queryCityId = new URLSearchParams(search).get("_city");
  const existingCitiesString = new URLSearchParams(search).get("_extCities");
  let selectedCitiesMap = "";
  if (existingCitiesString) {
    selectedCitiesMap = existingCitiesString
      .split(",")
      .reduce((acc, eachCity) => {
        acc[eachCity] = true;
        return acc;
      }, {});
  }

  const existingTgId = new URLSearchParams(search).get("_tg");
  return { queryCityId, selectedCitiesMap, existingTgId };
}

/**
 * Page
 */
function CampaignPlanningPage() {
  const dispatch = useDispatch();

  const { campaignId } = useParams();
  const search = useLocation().search;

  const { queryCityId, selectedCitiesMap, existingTgId } =
    getQueryParams(search);

  // State
  const [searchedStretches, setSearchedStretches] = useState([]);

  // for "Captive-Area" & "TG setup-Form"
  // const openAddCaptiveAreaForm = useSelector(
  //   (state) => state.poiFormModal.openModal
  // );
  // const openTgSetupForm = useSelector(
  //   (state) => state.targetGroupFormModal.openModal
  // );

  // for "SINGLE City-Selection-form"
  const openSingleCitySelectionForm = useSelector(
    (state) => state.singleCitySelectionModal.openModal
  );

  // For Poi-Selection-Form Model
  const openPoiSelectionForm = useSelector(
    (state) => state.poiSelectionForm.openModal
  );

  const isTabContentPreviewFormVisible = useSelector(
    (state) => state.tabContentPreviewForm.openModal
  );

  // for "region-data"
  const regionsDataLoading = useSelector(
    (state) => state.campaignPlanning.regionLoading
  );
  const regionsData = useSelector(
    (state) => state.campaignPlanning.regionsData
  );
  const { center, bbox: cityBBox } = regionsData || {};

  // TgSpecific Ots
  const tgSpecificOts =
    useSelector((state) => state.tgSpecificOts.tgSpecificOts) || {};

  // baseOts
  const baseOts = useSelector((state) => state.planningRoadStretches.baseOts);

  // for "road-stretch"
  const roadStretchesDataMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // "road-stretches" with tgSpecific Ots
  const newRoadStretchesData = Object.values(roadStretchesDataMap).map(
    (item) => ({
      ...item,
      ...tgSpecificOts[item.id],
    })
  );

  // road-stretches sorting details
  const [sortedRoadStretchesData, requestRoadStretchSort] =
    useSortableData(searchedStretches);

  const roadStretchLoading = useSelector(
    (state) => state.planningRoadStretches.loading
  );

  // for road-segment
  const roadSegmentDataMap = useSelector(
    (state) => state.planningRoadSegments.roadSegmentDetailsMap
  );

  // road-segments with tgSpecific Ots
  const newRoadSegmentsData = Object.values(roadSegmentDataMap).map((item) => ({
    ...item,
    ...tgSpecificOts[item.id],
  }));

  // road-segments sorting details
  const [sortedRoadSegmentsData, requestRoadSegmentsSort] =
    useSortableData(newRoadSegmentsData);

  // for exact lat-long
  const poiData = useSelector((state) => state.planningPoi.poiDetails) || {};

  // "IDs required for "TGSelector" component
  const segmentIds = Object.keys(roadSegmentDataMap) || [];

  // regions name list
  const regionsName = useSelector((state) => state.region.regionsName) || [];
  const regionNamesLoading = useSelector((state) => state.region.namesLoading);

  // TG list Loading
  const tgListLoading = useSelector(
    (state) => state.orgTargetGroup.targetGroupLoading
  );

  // Get CityId once city is selected
  const cityId = useSelector((state) => state.campaignPlanning.cityId);

  const pageNo = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSize;

  // Dispatch
  useEffect(() => {
    // Reset Actions
    dispatch(resetCityRoadStretches());
    dispatch(resetTgSpecificOts());
    dispatch(resetCampaignPlanning());
    dispatch(resetTgGroup());
    dispatch(resetCampaignDetails());
    dispatch(resetCampaignPlanDetails());
    dispatch(removeTgHeatMapData());
    dispatch(clearSelectedRegionAndZonesMap());
    dispatch(resetBrandInfo());
    dispatch(clearRouteTypesSelection());

    dispatch(getRegionNames());
    dispatch(getTargetGroups(false, "", pageNo, pageSize, DefaultTgName));
  }, [dispatch]);

  // Custom useEffect
  useRestoreCampaignPlan(campaignId, queryCityId);
  useGetPoiHierarchy(getMergedCityId(cityId));
  useGetTgAttributes(getMergedCityId(cityId));
  useGetRegionListByType(getMergedCityId(cityId));
  useGetRouteTypes(getMergedCityId(cityId));
  useGetTouchPoints(getMergedCityId(cityId));

  // TODO :: remove only when permanently not needed.
  // this will trigger whenever road stretches are filtered
  // useUpdateRoadStretchesSelection(getMergedCityId(cityId));

  // Functions
  function onSubmitCitySelectionForm(cityId) {
    dispatch(resetCityRoadStretches());
    dispatch(getRegionsData(cityId));
  }

  // Submit TG form function
  function tgFormDispatchFn(tgId) {
    dispatch(removeTgHeatMapData());
    dispatch(getTgInfo(tgId));

    // for only "Road-Segments"
    if (segmentIds?.length > 0) {
      dispatch(getTgSpecificOtsForSegments(tgId, segmentIds));
      return;
    }

    // for All "Road-Stretches" inside City
    dispatch(getTgSpecificOtsForCity(tgId, cityId));
  }

  // page loading
  if (regionNamesLoading || tgListLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <>
      <div className="campaign-planning">
        <div className="planning-layout-left">
          {/* Header Section */}
          <PlanningHeaderSection
            regionsData={regionsData}
            newRoadStretchesData={newRoadStretchesData}
            setSearchedStretches={setSearchedStretches}
          />

          {/* Road Stretch Container */}
          <div className="planning-road-container pb-4">
            <RoadStretchTable
              roadStretchData={Object.values(sortedRoadStretchesData)}
              baseOts={baseOts}
              roadStretchLoading={roadStretchLoading}
              regionsDataLoading={regionsDataLoading}
              requestRoadStretchSort={requestRoadStretchSort}
              requestRoadSegmentsSort={requestRoadSegmentsSort}
            />
          </div>

          {/* Footer Section */}
          <div className="fixed-bottom px-0 bg-white w-47">
            <CostAndOtsSection />
            <PlanningFooterSection selectedCitiesMap={selectedCitiesMap} />
          </div>
        </div>

        {/* map view */}
        <div className="planning-layout-right bg-alt">
          <PlanningMapView
            cityId={cityId}
            center={center}
            roadStretchData={Object.values(newRoadStretchesData)}
            segmentData={Object.values(roadSegmentDataMap)}
            poiData={poiData}
          />

          <PlanningMapTabsSection cityId={cityId} cityBBox={cityBBox} />
        </div>
      </div>

      {/* MODALS */}
      {/* {openAddCaptiveAreaForm && <PoiAndCaptiveAreaForm />} */}

      {/* TG-selection-form */}
      {/* {openTgSetupForm && (
        <TargetGroupForm
          existingTgId={existingTgId}
          segmentIds={segmentIds}
          dispatchFn={tgFormDispatchFn}
        />
      )} */}

      {/* CitySelection form */}
      {openSingleCitySelectionForm && (
        <SingleCitySelectionForm
          regionsName={regionsName}
          selectedCitiesMap={selectedCitiesMap}
          onSubmit={onSubmitCitySelectionForm}
          selectedCityId={cityId}
        />
      )}

      {/* Poi-Selection form */}
      {openPoiSelectionForm && (
        <PoiSelectionForm cityId={getMergedCityId(cityId)} />
      )}

      {/* Selected Content Preview Form */}
      {isTabContentPreviewFormVisible && <TabContentPreviewForm />}
    </>
  );
}

export default CampaignPlanningPage;
