import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import {
  getFrequentlyOptedLocationsFn,
  getZonesFn,
} from "../apis/ZonesAndSubZonesAPI";

// Constants and Utils
import { ZonesAndSubZones } from "../constants/action-constants/ZonesAndSubZonesActionConstants";
import { getErrorMessage } from "../../utils/util";

export function* getZones(action) {
  const { regionId } = action.payload;
  try {
    const data = yield getZonesFn(regionId);
    const { items, pagination } = data || {};

    yield put({
      type: ZonesAndSubZones.GET_ZONES_SUCCESS,
      payload: { zones: items, pagination },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ZonesAndSubZones.GET_ZONES_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* getFrequentlyOptedLocations(action) {
  const { cityId, pageNo, pageSize } = action.payload;
  try {
    const data = yield getFrequentlyOptedLocationsFn(cityId, pageNo, pageSize);
    const { items, pagination } = data || {};
    yield put({
      type: ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS_SUCCESS,
      payload: { frequentlyOptedLocations: items, pagination },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(ZonesAndSubZones.GET_ZONES, getZones),
    takeLatest(
      ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS,
      getFrequentlyOptedLocations
    ),
  ]);
}
