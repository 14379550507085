/*!
 * ATLAS SDK v2.0.0
 */
const Atlas = (function () {
  ("use strict");

  // Util Functions

  /**
   * String formating
   */
  String.prototype.format = function () {
    var s = this;
    var i = arguments.length;

    while (i--) {
      s = s.replace(new RegExp("\\{" + i + "\\}", "gm"), arguments[i]);
    }

    return s;
  };

  // Constants
  const defaultHostURL = "https://atlas.timeleap.io";
  const defaultApiVersion = "v1";

  /**
   * Simple ATLAS Namespace
   */
  function Atlas(inHostUrl, inApiVersion, enableLogs) {
    // init
    this.hostURL = inHostUrl || defaultHostURL;
    this.apiVersion = inApiVersion || defaultApiVersion;
    this.logEnabled = enableLogs || false;

    this.baseApiURL = "{0}/api/{1}".format(this.hostURL, this.apiVersion);
  }

  // Private Methods / Functions
  // --------------------------------------------------------------------------

  /**
   */
  function formatQueryParams(params) {
    if (!params) {
      return "";
    }

    let qryStr = "";
    for (const key in params) {
      const paramVal = params[key];

      if (typeof paramVal !== "object" && paramVal) {
        qryStr += `${key}=${paramVal}&`;
      } else if (typeof paramVal === "object" && paramVal && paramVal.length) {
        // Array of values
        paramVal.forEach((val) => {
          qryStr += `${key}=${val}&`;
        });
      }
    }

    return qryStr ? qryStr.slice(0, -1) : qryStr;
  }

  // Fetch Utilities

  /**
   * ATLAS :: Call API
   */
  Atlas.prototype._callAPI = async function (
    apiURI = "",
    method = "GET",
    body = {}
  ) {
    //   // Sanity Checks
    if (!apiURI) {
      return new Error("Invalid API URL passed");
    }

    const reqURL = `${this.baseApiURL}${apiURI}`;

    // Logs Method and Req URL
    if (this.logEnabled) {
      console.log(method + " :: " + reqURL);
    }

    const apiConfig = {
      method: method,
    };

    // setting up headers for post and put method
    if (["POST", "PUT"].includes(method)) {
      if (this.logEnabled) {
        console.log("Request body : ", body);
      }

      apiConfig.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      apiConfig.mode = "no-cors";
      apiConfig.body = JSON.stringify(body);
    }
    // Api Call
    const response = await (await fetch(reqURL, apiConfig)).json();
    if (this.logEnabled) {
      console.log(response);
    }

    // Sending Json
    return response;
  };

  /**
   * ATLAS :: Call API :: getJSON - GET Method
   */
  Atlas.prototype._getJSON = function (apiURI) {
    return this._callAPI(apiURI, "GET");
  };

  /**
   * ATLAS :: Call API :: getJSON - POST Method
   */
  Atlas.prototype._postJSON = function (apiURI, body) {
    return this._callAPI(apiURI, "POST", body);
  };

  // Prototype Methods
  // --------------------------------------------------------------------------

  /**
   * Enables Console Log
   */
  Atlas.prototype.enableLog = function () {
    console.log("Atlas Client :: Logs are enabled !");
    this.logEnabled = true;
  };

  /**
   * Disables Console Log
   */
  Atlas.prototype.disableLog = function () {
    console.log("Atlas Client :: Logs are disabled !");
    this.logEnabled = false;
  };

  // ATLAS :: Point APIs
  // --------------------------------------------------------------------------

  Atlas.prototype.pointById = function (pointId) {
    if (!pointId) {
      return new Error("Invalid Point ID passed.");
    }

    // URI
    var uriFormat = "/point/{0}";
    var apiURI = uriFormat.format(pointId);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.pointsGeoNear = function (lat, lng, radius, poiFilters = {}) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    if (!radius) {
      return new Error("Invalid Radius passed");
    }

    const { types = [], poiTypes = [], brandIds = [] } = poiFilters;

    // Query Params
    const params = {
      lat,
      lng,
      radius,
      type: types,
      poi_type: poiTypes,
      brand: brandIds,
    };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/point/geo/near?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.pointsGeoNearNearest = function (
    lat,
    lng,
    radius,
    poiFilters = {}
  ) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    if (!radius) {
      return new Error("Invalid Radius passed");
    }

    const { types = [], poiTypes = [], brandIds = [] } = poiFilters;

    if (types?.length < 1) {
      return new Error("Invalid Types. Atleast one Type is needed");
    }

    // Query Params
    const params = {
      lat,
      lng,
      radius,
      type: types,
      poi_type: poiTypes,
      brand: brandIds,
    };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/point/geo/near/nearest?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.pointsGeoWithin = function (boundary, poiFilters = {}) {
    if (!boundary) {
      return new Error("Invalid Inputs.");
    }

    const { types = [], poiTypes = [], brandIds = [] } = poiFilters;

    // Query Params
    const params = { type: types, poi_type: poiTypes, brand: brandIds };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/point/geo/within?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._postJSON(apiURI, boundary);
  };

  Atlas.prototype.pointsGeoWithinBBox = function (nw, se, poiFilters = {}) {
    if (!nw || !se) {
      return new Error("Invalid BBox passed.");
    }

    const { types = [], poiTypes = [], brandIds = [] } = poiFilters;

    // Query Params
    const params = {
      nw,
      se,
      type: types,
      poi_type: poiTypes,
      brand: brandIds,
      pn: 1,
      ps: 1000,
    };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/point/geo/within/bbox?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  // ATLAS API :: Region APIs
  // --------------------------------------------------------------------------

  Atlas.prototype.regionById = function (regionId) {
    if (!regionId) {
      return new Error("Invalid Region ID passed.");
    }

    // URI
    var uriFormat = "/region/{0}";
    var apiURI = uriFormat.format(regionId);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.regionChildPoints = function (regionId) {
    if (!regionId) {
      return new Error("Invalid Region ID passed.");
    }

    // URI
    var uriFormat = "/region/{0}/points";
    var apiURI = uriFormat.format(regionId);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.regionChildRegions = function (regionId) {
    if (!regionId) {
      return new Error("Invalid Region ID passed.");
    }

    // URI
    var uriFormat = "/region/{0}/regions";
    var apiURI = uriFormat.format(regionId);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.regionChildTraces = function (regionId) {
    if (!regionId) {
      return new Error("Invalid Region ID passed.");
    }

    // URI
    var uriFormat = "/region/{0}/traces";
    var apiURI = uriFormat.format(regionId);

    return this._getJSON(apiURI);
  };

  // Geo intersect

  Atlas.prototype.regionsGeoIntersect = function (lat, lng, types) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    // Query Params
    const params = { lat, lng, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/region/geo/intersect?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  // ATLAS :: Trace APIs
  // --------------------------------------------------------------------------

  Atlas.prototype.traceById = function (traceId) {
    if (!traceId) {
      return new Error("Invalid Trace ID passed");
    }

    // URI
    var uriFormat = "/trace/{0}";
    var apiURI = uriFormat.format(traceId);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.tracesGeoNear = function (lat, lng, radius, types) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    if (!radius) {
      return new Error("Invalid Radius passed");
    }

    // Query Params
    const params = { lat, lng, radius, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/trace/geo/near?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.tracesGeoWithin = function (boundary, types) {
    if (!boundary) {
      return new Error("Invalid Boundary Input.");
    }

    // Query Params
    const params = { type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/trace/geo/within?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._postJSON(apiURI, boundary);
  };

  Atlas.prototype.tracesGeoWithinBBox = function (nw, se, types) {
    if (!nw || !se) {
      return new Error("Invalid BBox passed.");
    }

    // Query Params
    const params = { nw, se, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/trace/geo/within/bbox?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.tracesGeoIntersect = function (lat, lng, radius, types) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    if (!radius) {
      return new Error("Invalid Radius passed");
    }

    // Query Params
    const params = { lat, lng, radius, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/trace/geo/intersect?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  // ATLAS :: Road APIs
  // --------------------------------------------------------------------------

  Atlas.prototype.roadById = function (roadId) {
    if (!roadId) {
      return new Error("Invalid Road ID passed");
    }

    // URI
    var uriFormat = "/road/{0}";
    var apiURI = uriFormat.format(roadId);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.roadsGeoNear = function (lat, lng, radius, types) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    if (!radius) {
      return new Error("Invalid Radius passed");
    }

    // Query Params
    const params = { lat, lng, radius, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/road/geo/near?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.roadsGeoWithin = function (boundary, types) {
    if (!boundary) {
      return new Error("Invalid Boundary Input.");
    }

    // Query Params
    const params = { type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/road/geo/within?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._postJSON(apiURI, boundary);
  };

  Atlas.prototype.roadsGeoWithinBBox = function (nw, se, types) {
    if (!nw || !se) {
      return new Error("Invalid BBox passed.");
    }

    // Query Params
    const params = { nw, se, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/road/geo/within/bbox?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.roadsGeoIntersect = function (lat, lng, radius, types) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    if (!radius) {
      return new Error("Invalid Radius passed");
    }

    // Query Params
    const params = { lat, lng, radius, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/road/geo/intersect?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  // ATLAS :: Geo Summary APIs
  // ----------------------------------------------------------------------------

  Atlas.prototype.summaryGeoNear = function (lat, lng, radius, types) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    if (!radius) {
      return new Error("Invalid Radius passed");
    }

    // Query Params
    const params = { lat, lng, radius, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/stats/geo/near?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.summaryGeoWithin = function (boundary) {
    if (!boundary) {
      return new Error("Invalid Boundary Input.");
    }

    // URI
    var apiURI = "/stats/geo/within";

    return this._postJSON(apiURI, boundary);
  };

  Atlas.prototype.summaryGeoWithinBBox = function (nw, se, types) {
    if (!nw || !se) {
      return new Error("Invalid BBox passed.");
    }

    // Query Params
    const params = { nw, se, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/stats/geo/within/bbox?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  // ATLAS :: Heatmap APIs
  // ----------------------------------------------------------------------------

  Atlas.prototype.heatmapGeoNear = function (lat, lng, radius, types) {
    if (!lat || !lng) {
      return new Error("Invalid Latitude or Longitude passed");
    }

    if (!radius) {
      return new Error("Invalid Radius passed");
    }

    // Query Params
    const params = { lat, lng, radius, type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/heatmap/geo/near?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  Atlas.prototype.heatmapGeoWithin = function (boundary, types) {
    if (!boundary) {
      return new Error("Invalid Boundary Input.");
    }

    // Query Params
    const params = { type: types };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/heatmap/geo/within?type={0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._postJSON(apiURI, boundary);
  };

  Atlas.prototype.heatmapGeoWithinBBox = function (nw, se, types, prop) {
    if (!nw || !se) {
      return new Error("Invalid BBox passed.");
    }

    // Query Params
    const params = {
      nw,
      se,
      // type: types, // previous (remove at last)
      poi_type: types, // new
      prop,
    };
    const qryParamsStr = formatQueryParams(params);

    // URI
    var uriFormat = "/heatmap/geo/within/bbox?{0}";
    var apiURI = uriFormat.format(qryParamsStr);

    return this._getJSON(apiURI);
  };

  // Return the ATLAS Object
  // ------------------------------------------------------------------------

  return Atlas;
})();

// Create new Client
const AtlasClient = new Atlas();

// Export
export default AtlasClient;
