export const Buyer = {
  // To get buyers list
  GET_BUYERS_LIST: "GET_BUYERS_LIST",
  GET_BUYERS_LIST_SUCCESS: "GET_BUYERS_LIST_SUCCESS",
  GET_BUYERS_LIST_FAILURE: "GET_BUYERS_LIST_FAILURE",

  // Clear Buyers
  CLEAR_BUYERS_LIST: "CLEAR_BUYERS_LIST",

  // Buyer Details
  GET_BUYER_DETAILS: "GET_BUYER_DETAILS",
  GET_BUYER_DETAILS_SUCCESS: "GET_BUYER_DETAILS_SUCCESS",
  GET_BUYER_DETAILS_FAILURE: "GET_BUYER_DETAILS_FAILURE",

  // Create Buyer
  CREATE_BUYER: "CREATE_BUYER",
  CREATE_BUYER_SUCCESS: "CREATE_BUYER_SUCCESS",
  CREATE_BUYER_FAILURE: "CREATE_BUYER_FAILURE",

  // Update Buyer
  UPDATE_BUYER: "UPDATE_BUYER",
  UPDATE_BUYER_SUCCESS: "UPDATE_BUYER_SUCCESS",
  UPDATE_BUYER_FAILURE: "UPDATE_BUYER_FAILURE",

  // To get buyer users by buyer id
  GET_USERS_BY_BUYER_ID: "GET_USERS_BY_BUYER_ID",
  GET_USERS_BY_BUYER_ID_SUCCESS: "GET_USERS_BY_BUYER_ID_SUCCESS",
  GET_USERS_BY_BUYER_ID_FAILURE: "GET_USERS_BY_BUYER_ID_FAILURE",

  // To clear the users state
  CLEAR_BUYER_USERS: "CLEAR_BUYER_USERS",

  // Add/Edit Buyer Form
  OPEN_ADD_AND_EDIT_BUYER_FORM: "OPEN_ADD_AND_EDIT_BUYER_FORM",
  CLOSE_ADD_AND_EDIT_BUYER_FORM: "CLOSE_ADD_AND_EDIT_BUYER_FORM",
};
