import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import {
  getBuyersList,
  openAddAndEditBuyerForm,
} from "../../../actions/buyer/BuyerActions";

// Utils and Constants
import { ProohFormDataTargets } from "../../constants/GeneralConstants";
import { formatText } from "../../../common-utils/string-utils/StringUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import SearchInput from "../../../components/search-input/SearchInput";
import Spinner from "../../../components/spinner/Spinner";
import Pagination from "../../../components/pagination/Pagination";
import TableHeaders from "../../../components/table/TableHeaders";
import NoTableDataMessage from "../../../components/table/NoTableDataMessage";
import AddAndEditBuyerForm from "./AddAndEditBuyerForm";

// Page-Constants
const tableHeaders = [
  {
    title: {
      displayName: "Name",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Description",
      className: "col-4",
    },
  },
  {
    title: {
      displayName: "Created On",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Last Updated",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Action",
      className: "col-2",
    },
  },
];

// Page Components
function BuyerActionsCell({ buyer = {} }) {
  const dispatch = useDispatch();

  const { id } = buyer || {};

  const updateBuyerLoadingMap = useSelector(
    (state) => state.buyer.updateBuyerLoadingMap
  );
  const updateBuyerLoading = updateBuyerLoadingMap[id];

  if (updateBuyerLoading) {
    return (
      <td>
        <Spinner className="spinner-border-sm" />
      </td>
    );
  }

  return (
    <td>
      <button
        className="btn btn-link shadow-none p-0"
        data-toggle="modal"
        data-target={`#${ProohFormDataTargets.addAndEditBuyerForm}`}
        onClick={() => dispatch(openAddAndEditBuyerForm({ buyer }))}
      >
        <i className="fa fa-pen text-primary px-2"></i>
        Edit
      </button>
    </td>
  );
}

function BuyerUpdatedOnCell({ updatedOn }) {
  return <td>{new Date(updatedOn).toDateString()}</td>;
}

function BuyerCreationOnCell({ createdOn }) {
  return <td>{new Date(createdOn).toDateString()}</td>;
}

function BuyerDescriptionCell({ description = "" }) {
  return (
    <td>
      <div className="text-truncate" title={description}>
        <span>{formatText(description)}</span>
      </div>
    </td>
  );
}

function BuyerNameCell({ id, name }) {
  return (
    <td>
      <Link to={constructRedirectPath(`/buyer/${id}/details`)}>{name}</Link>
    </td>
  );
}

function BuyerListTableRow({ buyer = {} }) {
  const { id, name, description, createdOn, updatedOn } = buyer || {};

  return (
    <tr>
      <BuyerNameCell id={id} name={name} />
      <BuyerDescriptionCell description={description} />
      <BuyerCreationOnCell createdOn={createdOn} />
      <BuyerUpdatedOnCell updatedOn={updatedOn} />
      <BuyerActionsCell buyer={buyer} />
    </tr>
  );
}

// Buyer List Table
function BuyerListTable({
  buyersList = [],
  pagination = {},
  onClickPagination = () => {},
}) {
  // loading
  const buyerListLoading = useSelector((state) => state.buyer.buyerListLoading);

  // pagination
  const { totalCount, pageNo, pageSize } = pagination || {};

  // table loading
  if (buyerListLoading) {
    return (
      <div className="d-flex justify-content-center bg-alt border p-1">
        <Spinner />
        <span className="p-2">Buyers Loading, Please Wait...</span>
      </div>
    );
  }

  // check buyers present or not
  const isBuyersPresent = buyersList.length > 0;

  return (
    <div className="table-responsive">
      <table className="table table-fixed">
        {/* Table Header */}
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        {/* Table Body */}
        <tbody>
          {!isBuyersPresent && (
            <NoTableDataMessage colSpan={5} message="No Buyers Present" />
          )}

          {isBuyersPresent &&
            buyersList.map((buyer) => (
              <BuyerListTableRow key={buyer.id} buyer={buyer} />
            ))}
        </tbody>
      </table>

      {/* Table-pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={onClickPagination}
      />
    </div>
  );
}

function TableActionsSection({ searchText, setSearchText }) {
  const dispatch = useDispatch();

  // Functions
  function onClickSearch() {
    dispatch(getBuyersList(searchText));
  }

  function onSearchTextChange(e) {
    setSearchText(e.target.value);
  }

  return (
    <div className="table-header">
      <SearchInput
        placeholder={"Search Buyers"}
        onClickSearch={onClickSearch}
        onTextChange={onSearchTextChange}
      />
    </div>
  );
}

/**
 * Buyer List Page
 */
export default function BuyerListPage() {
  const dispatch = useDispatch();

  // State
  const [searchText, setSearchText] = useState("");

  // State : Data
  const buyersList = useSelector((state) => state.buyer.buyersList);
  const pagination = useSelector((state) => state.buyer.buyerListPagination);

  // create & update buyer
  const createBuyerLoading = useSelector(
    (state) => state.buyer.createBuyerLoading
  );

  // Add/Edit Buyer Modal
  const openAddEditBuyerModal = useSelector(
    (state) => state.buyer.openAddEditBuyerModal
  );

  useEffect(() => {
    dispatch(getBuyersList());
  }, [dispatch]);

  // handle pagination
  function onClickPagination(pageNumber, pageSize) {
    dispatch(getBuyersList(searchText, pageNumber, pageSize));
  }

  const pageTitle = "Buyers";
  const pageActions = (
    <button
      className="btn btn-primary"
      data-toggle="modal"
      data-target={`#${ProohFormDataTargets.addAndEditBuyerForm}`}
      onClick={() => dispatch(openAddAndEditBuyerForm())}
      disabled={createBuyerLoading}
    >
      Create new Buyer{" "}
      {createBuyerLoading && <Spinner className="spinner-border-sm" />}
    </button>
  );

  return (
    <div className="content-wrapper">
      {/** Page Header */}
      <PageHeader title={pageTitle} actions={pageActions} shadow={true} />

      {/** Page Content */}
      <div className="page-content">
        {/** Table Header actions */}
        <TableActionsSection
          searchText={searchText}
          setSearchText={setSearchText}
        />

        {/** Road-Stretch Table component */}
        <BuyerListTable
          buyersList={buyersList}
          pagination={pagination}
          onClickPagination={onClickPagination}
        />
      </div>

      {/* MODALS */}
      {openAddEditBuyerModal && <AddAndEditBuyerForm />}
    </div>
  );
}
