import {
  createMediaUrl,
  generateCampaignMediaMetricsUrl,
  generateMediaIndicesUrl,
  getCampaignMediaByCityUrl,
  getCampaignMediaByIdUrl,
  getRoadTrafficUrl,
  updateCampaignMediaByIdUrl,
  uploadSiteImagesUrl,
  deleteCampaignMediaUrl,
  mapSellerMediaToCampaignUrl,
  getCampaignMediaUrl,
  downloadMediaPdfReportUrl,
  generateAppMediaIndicesUrl,
} from "../urls/CampaignMediaURL";

// Constants and Utils..
import {
  deleteRequest,
  downloadFile,
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";
import { getToken } from "../utils/token";
import { CampaignMediaReportStatus } from "../constants/GeneralConstants";

// SDK
import AtlasClient from "../sdk/AtlasClient";

export async function createCampaignMedia(campaignId, cityId, mediaList) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    createMediaUrl.replace(":campaignId", campaignId),
    mediaList,
    true,
    "POST",
    headerParams
  );
}

export async function uploadSiteImages(campaignId, mediaId, files) {
  const formData = new FormData();
  formData.append("type", "IMAGE");
  Object.values(files).forEach((file) => formData.append("files", file));

  return sendCreateOrUpdateRequest(
    uploadSiteImagesUrl
      .replace(":campaignId", campaignId)
      .replace(":mediaId", mediaId),
    formData
  );
}

export async function findMavinMetrics(campaignId, cityId) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    generateCampaignMediaMetricsUrl
      .replace(":campaignId", campaignId)
      .replace(":cityId", cityId),
    {},
    true,
    "PUT",
    headerParams
  );
}

export async function getMediaById(campaignId, mediaId) {
  const url = getCampaignMediaByIdUrl
    .replace(":id", campaignId)
    .replace(":mediaId", mediaId);
  return sendGetRequest(url);
}

export async function getTrafficInfoByRoadId(roadId) {
  return await AtlasClient.roadById(roadId);
}

export async function deleteCampaignMedia(campaignId, campaignMediaId) {
  const url = deleteCampaignMediaUrl
    .replace(":campaignId", campaignId)
    .replace(":campaignMediaId", campaignMediaId);
  return deleteRequest(url);
}

export async function mapSellerMediaToCampaign(campaignId, mediaIds) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    ids: mediaIds.join(),
  };
  const url = mapSellerMediaToCampaignUrl.replace(":campaignId", campaignId);
  return sendCreateOrUpdateRequest(url, {}, true, "POST", headerParams, params);
}

export async function updateMavinMetrics(campaignId, mediaId, reportStatus) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const requestUrl =
    reportStatus === CampaignMediaReportStatus.APPROXIMATED
      ? generateAppMediaIndicesUrl
      : generateMediaIndicesUrl;

  return sendCreateOrUpdateRequest(
    requestUrl.replace(":id", campaignId).replace(":mediaId", mediaId),
    {},
    true,
    "PUT",
    headerParams
  );
}

export async function updateCampaignMediaDuration(
  campaignId,
  mediaId,
  requestBody
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    updateCampaignMediaByIdUrl
      .replace(":id", campaignId)
      .replace(":mediaId", mediaId),
    requestBody,
    true,
    "PUT",
    headerParams
  );
}

export async function getCampaignMediaByCity(
  campaignId,
  cityId,
  pageNo,
  pageSize,
  types
) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    pn: pageNo,
    ps: pageSize,
  };

  if (types) {
    params._type = types;
  }

  return sendGetRequest(
    getCampaignMediaByCityUrl
      .replace(":id", campaignId)
      .replace(":cityId", cityId),
    params,
    true,
    headerParams
  );
}

export async function getCampaignMedias(campaignId, pageNo, pageSize) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    pn: pageNo,
    ps: pageSize,
  };

  const url = getCampaignMediaUrl.replace(":campaignId", campaignId);
  return sendGetRequest(url, params, true, headerParams);
}

export async function downloadMediaPdfReportFn(campaignId, mediaId, radius) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  const radiusInKm = radius ? radius / 1000 : 1;

  return downloadFile(
    downloadMediaPdfReportUrl
      .replace(":campaignId", campaignId)
      .replace(":mediaId", mediaId),
    true,
    headerParams,
    { radiusInKm }
  );
}

export async function getKypTraffic(roadId) {
  const url = getRoadTrafficUrl.replace(":routeId", roadId);
  return sendGetRequest(url);
}
