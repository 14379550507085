import { useDispatch, useSelector } from "react-redux";

// Actions
import { setHoveredStretchId } from "../../actions/campaign-planning/CampaignPlanningActions";
import {
  selectMultiRoadStretches,
  unSelectMultiRoadStretches,
} from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";

// Utils
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { calEstPrice } from "../../utils/PriceFactorUtil";
import { getStretchImpressions } from "../../utils/campaignPlanningUtil";
import { AddCaptiveAreaSection, StretchSelectionButton } from "./PlanningUtils";
import { SortingBtn } from "./SortingUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";
import { ProgressBar as HeaderProgressBar } from "../../../components/progress-bar/ProgressBar";
import TableHeaders from "../../../components/table/TableHeaders";
import NoTableDataMessage from "../../../components/table/NoTableDataMessage";
import CheckBox from "../../../components/check-box/CheckBox";

// Table Headers
function tableHeaders(
  roadStretchData = [],
  originalCityStretchesCount,
  selectedStretchesCount,
  requestRoadSegmentsSort,
  requestRoadStretchSort
) {
  const oneRoadStretchInfo = roadStretchData?.[0] || {};
  const { targetGroupId } = oneRoadStretchInfo || {};

  return [
    {
      element: (
        <RoadStretchHeader
          originalCityStretchesCount={originalCityStretchesCount}
          selectedStretchesCount={selectedStretchesCount}
        />
      ),
    },
    {
      element: <OtsHeader targetGroupId={targetGroupId} />,
    },
    {
      title: {
        displayName: "Avg. MOP",
        className: "col-2 align-middle",
      },
    },
    {
      element: (
        <ActionHeader
          requestRoadSegmentsSort={requestRoadSegmentsSort}
          requestRoadStretchSort={requestRoadStretchSort}
        />
      ),
    },
  ];
}

function RoadStretchHeader({
  originalCityStretchesCount,
  selectedStretchesCount,
}) {
  const stretchCountData = [
    { label: "Selected Stretches", count: selectedStretchesCount },
    { label: "City Total Stretches", count: originalCityStretchesCount },
  ];

  return (
    <th className="col-3 align-middle">
      {stretchCountData.map((data, i) => {
        const { label, count } = data || {};
        const formattedCount = toLocaleString(count) || 0;
        return <p key={i} className="mb-0">{`${label} (${formattedCount})`}</p>;
      })}

      {/* captive area button */}
      {/* <AddCaptiveAreaSection /> */}
    </th>
  );
}

function OtsHeader({ targetGroupId }) {
  return (
    <th className="col-5 pr-4 align-middle">
      {targetGroupId && (
        <div className="row no-gutters align-items-center">
          <span className="col-5">Imp TG</span>
          <div className="col-7">
            <HeaderProgressBar className="bg-light-green" percentage={40} />
          </div>
        </div>
      )}

      <div className="row no-gutters align-items-center">
        <span className="col-5">Imp Gen</span>
        <div className="col-7">
          <HeaderProgressBar className="bg-light-blue" percentage={100} />
        </div>
      </div>
    </th>
  );
}

function checkTableAllStretchesSelected(
  stretchesIds = [],
  selectedStretchesIds = []
) {
  if (stretchesIds.length === 0 || selectedStretchesIds.length === 0) {
    return false;
  }

  const isIncludesAll = stretchesIds.every((id) =>
    selectedStretchesIds.includes(id)
  );

  return isIncludesAll;
}

// Stretches Select All Button
function StretchesSelectAllButton() {
  const dispatch = useDispatch();

  // road stretches map
  const roadStretchesDataMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // selected stretches map
  const stretchSelectedMap = useSelector(
    (state) => state.planningRoadStretches.selectedStretches
  );

  const stretchesIds = Object.keys(roadStretchesDataMap);
  const selectedStretchesIds = Object.keys(stretchSelectedMap);

  // checking all table stretches are selected or not
  const isTableAllStretchesSelected = checkTableAllStretchesSelected(
    stretchesIds,
    selectedStretchesIds
  );

  function onCheckboxChange(e) {
    const { checked } = e.target || {};

    // multi un select stretches
    if (!checked) {
      dispatch(unSelectMultiRoadStretches(roadStretchesDataMap));
      return;
    }

    // multi select stretches
    dispatch(selectMultiRoadStretches(roadStretchesDataMap));
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <span className="mr-2 ml-1">Select All</span>
      <CheckBox
        boxStyle="mb-0 p-0"
        inputBoxStyle="m-0"
        showLabel={false}
        checked={isTableAllStretchesSelected}
        disabled={stretchesIds.length === 0}
        onChange={onCheckboxChange}
      />
    </div>
  );
}

function ActionHeader({ requestRoadSegmentsSort, requestRoadStretchSort }) {
  return (
    <th className="col-2 align-middle text-right">
      {/* select/unselect all */}
      <StretchesSelectAllButton />

      {/* sorting */}
      <SortingBtn
        requestRoadStretchSort={requestRoadStretchSort}
        requestRoadSegmentsSort={requestRoadSegmentsSort}
      />
    </th>
  );
}

function ProgressBar({ baseOts, currentOts, className }) {
  const width = currentOts ? (currentOts / baseOts) * 100 : 0;
  const widthPercentage = width + "%";

  return (
    <span
      className={`progress progress-bar ${className}`}
      role="progressbar"
      style={{ width: widthPercentage }}
      aria-valuenow={width}
      aria-valuemin="0"
      aria-valuemax="100"
    ></span>
  );
}

function ImpressionInfoCell({ stretchInfo, baseOts, dayCount }) {
  const {
    otsAvg,
    otsLitAvg,
    // genericOtsLit,
    // genericOts,
    targetOtsLit,
    targetOts,
    targetGroupId,
  } = stretchInfo || {};

  const estImpressions = otsLitAvg ?? otsAvg;
  const targetImpressions = targetOtsLit ?? targetOts;

  // for TG-specific-impressions
  // const genericImpressions = genericOtsLit ?? genericOts;
  // const specificImpressions = targetOtsLit ?? targetOts;

  // if (genericImpressions || specificImpressions) {
  //   return (
  //     <td className="col-5 pr-4 align-middle">
  //       <div className="row no-gutters">
  //         <span
  //           className="col-5"
  //           title={toStringWithRoundUp(dayCount * specificImpressions)}
  //         >
  //           {specificImpressions
  //             ? toStringWithRoundUp(dayCount * specificImpressions)
  //             : "NA"}
  //         </span>
  //         <div className="col-7">
  //           <ProgressBar
  //             baseOts={baseOts}
  //             currentOts={specificImpressions}
  //             className="bg-light-green"
  //           />
  //         </div>
  //       </div>

  //       <div className="row no-gutters">
  //         <span className="col-5">
  //           {genericImpressions
  //             ? toStringWithRoundUp(dayCount * genericImpressions)
  //             : "NA"}
  //         </span>
  //         <div className="col-7">
  //           <ProgressBar
  //             baseOts={baseOts}
  //             currentOts={genericImpressions}
  //             className="bg-light-blue"
  //           />
  //         </div>
  //       </div>
  //     </td>
  //   );
  // }

  return (
    <td className="col-5 pr-4 align-middle">
      {/* tg specific imp */}
      {targetGroupId && (
        <div className="row no-gutters align-items-center">
          <span className="col-5">
            {targetImpressions
              ? toStringWithRoundUp(dayCount * targetImpressions)
              : "NA"}
          </span>
          <div className="col-7">
            <ProgressBar
              baseOts={baseOts}
              currentOts={targetImpressions}
              className="bg-light-green"
            />
          </div>
        </div>
      )}

      {/* est imp */}
      <div className="row no-gutters align-items-center">
        <span className="col-5">
          {estImpressions
            ? toStringWithRoundUp(dayCount * estImpressions)
            : "NA"}
        </span>
        <div className="col-7">
          <ProgressBar
            baseOts={baseOts}
            currentOts={estImpressions}
            className="bg-light-blue"
          />
        </div>
      </div>
    </td>
  );
}

function StretchInfoCell({ stretchInfo, dayCount, isTopFiveStretch = false }) {
  const dispatch = useDispatch();

  // data
  const { id, name, mediaCount, targetGroupId } = stretchInfo || {};
  const { genericImpressions, specificImpressions } =
    getStretchImpressions(stretchInfo);

  // changing stretch color if the tg is applied and stretch belong to top five tgOts
  const stretchNameColor =
    targetGroupId && isTopFiveStretch ? "text-danger" : "";

  // function
  function handleStretchHover(isHover) {
    const hoveredStretchId = isHover ? id : "";
    dispatch(setHoveredStretchId(hoveredStretchId));
  }

  // Total Impression ==> After selecting TG
  if (specificImpressions) {
    const totalImpressions = specificImpressions + genericImpressions;
    return (
      <td className="col-3 align-middle">
        <p
          className={`mb-0 text-truncate stretch-info-cell ${stretchNameColor}`}
          title={name}
          onMouseEnter={() => handleStretchHover(true)}
          onMouseLeave={() => handleStretchHover(false)}
        >
          {name}
        </p>
        <div className="d-flex sub-text">
          <span>Sites: {mediaCount}</span>
          <span className="px-1">|</span>
          <span>
            Total Imp:{" "}
            {totalImpressions
              ? toStringWithRoundUp(dayCount * totalImpressions)
              : "NA"}
          </span>
        </div>
      </td>
    );
  }

  // Total Impressions ==> Before selecting TG
  return (
    <td className="col-3 align-middle">
      <p
        className={`mb-0 text-truncate stretch-info-cell ${stretchNameColor}`}
        title={name}
        onMouseEnter={() => handleStretchHover(true)}
        onMouseLeave={() => handleStretchHover(false)}
      >
        {name}
      </p>
      <div className="d-flex sub-text">
        <span>Sites: {mediaCount}</span>
        <span className="px-1">|</span>
        <span>
          Total Imp:{" "}
          {genericImpressions
            ? toStringWithRoundUp(dayCount * genericImpressions)
            : "NA"}
        </span>
      </div>
    </td>
  );
}

function EstimatedPriceCell({ stretchInfo }) {
  const { avgMOP } = stretchInfo;
  const formattedAvgMOP =
    avgMOP === 0 ? 0 : formatText(toStringWithRoundUp(avgMOP));

  return (
    <td className="col-2 align-middle">
      <p className="mb-0">{formattedAvgMOP}</p>
    </td>
  );
}

function RoadStretchTableRow({ index, stretchInfo, baseOts, dayCount }) {
  const isTopFiveStretch = index < 5;

  return (
    <tr>
      {/* stretch info cell */}
      <StretchInfoCell
        stretchInfo={stretchInfo}
        dayCount={dayCount}
        isTopFiveStretch={isTopFiveStretch}
      />

      {/* impression info cell */}
      <ImpressionInfoCell
        stretchInfo={stretchInfo}
        baseOts={baseOts}
        dayCount={dayCount}
      />

      {/* estimated price cell */}
      <EstimatedPriceCell stretchInfo={stretchInfo} />

      {/* stretch selection button */}
      <StretchSelectionButton stretchInfo={stretchInfo} elementStyle="col-2" />
    </tr>
  );
}

function RoadStretchTableBody({ roadStretchData, baseOts }) {
  // Duration
  const duration = useSelector(
    (state) => state.campaignPlanning.durationInDays
  );

  // RoadStretch data is empty return null
  if (roadStretchData.length === 0) {
    return <NoTableDataMessage colSpan={4} message="No Stretches Present" />;
  }

  // Road stretch table row
  return (
    <>
      {roadStretchData.map((stretchInfo, index) => (
        <RoadStretchTableRow
          key={stretchInfo.id}
          index={index}
          stretchInfo={stretchInfo}
          dayCount={duration}
          baseOts={baseOts}
        />
      ))}
    </>
  );
}

/**
 * Road Stretch Table
 */
function RoadStretchTable({
  roadStretchData = [],
  baseOts,
  roadStretchLoading = false,
  regionsDataLoading = false,
  requestRoadSegmentsSort,
  requestRoadStretchSort,
}) {
  // Get CityId once city is selected
  const cityId = useSelector((state) => state.campaignPlanning.cityId);

  // Selected Stretches
  const selectedStretchesMap = useSelector(
    (state) => state.planningRoadStretches.selectedStretches
  );
  const selectedStretchesCount = Object.keys(selectedStretchesMap).length;

  // original City Stretches Count
  const originalCityStretchesCount = useSelector(
    (state) => state.planningRoadStretches.originalCityStretchesCount
  );

  // Loading
  if (roadStretchLoading || regionsDataLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-4">
        <Spinner />
        <span className="ml-2">Please wait, Road Stretches are Loading...</span>
      </div>
    );
  }

  if (!cityId) {
    return null;
  }

  const stretchTableHeaders = tableHeaders(
    roadStretchData,
    originalCityStretchesCount,
    selectedStretchesCount,
    requestRoadSegmentsSort,
    requestRoadStretchSort
  );

  // stretches table
  return (
    <table className="table">
      <TableHeaders
        tableHeaders={stretchTableHeaders}
        headerClass="bg-white"
        requiredSubHeader={false}
      />

      <tbody>
        <RoadStretchTableBody
          roadStretchData={roadStretchData}
          baseOts={baseOts}
          roadStretchLoading={roadStretchLoading}
        />
      </tbody>
    </table>
  );
}

export default RoadStretchTable;
