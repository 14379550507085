import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getSharedCampaigns } from "../../../actions/campaign/CampaignActions";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import NoTableDataMessage from "../../../components/table/NoTableDataMessage";
import { CampaignRedirect } from "../components/CampaignRedirect";
import { CampaignStatus } from "../components/HeaderNav";
import { Loader, TableHeaderWithAction } from "./HomePageUtils";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Campaign Name",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Status",
      className: "text-left col-2",
    },
  },
  {
    title: {
      displayName: "No. of Sites",
      className: "text-right col-1",
    },
  },
  {
    title: {
      displayName: "Last updated",
      className: "text-right col-2",
    },
  },
  {
    title: {
      displayName: "Actions",
      className: "text-center col-1",
    },
  },
];

// Page Components
function CampaignName({ title }) {
  return <td>{title}</td>;
}

function SiteCount({ siteCount }) {
  return <td className="text-right">{siteCount}</td>;
}

function LastUpdated({ lastUpdated }) {
  const lastUpdatedDate = new Date(lastUpdated).toDateString();
  return <td className="text-right">{lastUpdatedDate}</td>;
}

function TableAction({ id, state }) {
  return (
    <td className="text-center">
      <CampaignRedirect id={id} state={state} />
    </td>
  );
}

function SharedCampaignsTableRow({ campaign = {} }) {
  const { id, title, planStatus, mediaCount, updatedOn } = campaign || {};
  return (
    <tr>
      <CampaignName title={title} />
      <td>
        <CampaignStatus state={planStatus} />
      </td>
      <SiteCount siteCount={mediaCount} />
      <LastUpdated lastUpdated={updatedOn} />
      <TableAction id={id} state={planStatus} />
    </tr>
  );
}

function SharedCampaignsTableBody({
  campaigns = [],
  campaignsLoading = false,
}) {
  const isCampaignsPresent = campaigns.length > 0;

  if (campaignsLoading) {
    return <Loader />;
  }

  if (!isCampaignsPresent) {
    return <NoTableDataMessage colSpan={5} message="No Campaigns Present" />;
  }

  return (
    <>
      {campaigns.map((campaign) => (
        <SharedCampaignsTableRow key={campaign.id} campaign={campaign} />
      ))}
    </>
  );
}

function SharedCampaignsTable() {
  const dispatch = useDispatch();

  // State
  const [searchText, setSearchText] = useState("");

  // shared campaigns list
  const sharedCampaignList = useSelector(
    (state) => state.campaign.sharedCampaignList
  );

  const campaignsLoading = useSelector(
    (state) => state.campaign.sharedCampaignLoading
  );

  // Functions
  function onClickSearch() {
    dispatch(getSharedCampaigns(searchText));
  }

  return (
    <div className="card shadow">
      <div className="card-body">
        <TableHeaderWithAction
          onClickSearch={onClickSearch}
          onSearchTextChange={({ target }) => setSearchText(target.value)}
        />

        <div className="table-responsive mt-3">
          <table className="table table-media">
            <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
            <tbody>
              <SharedCampaignsTableBody
                campaigns={sharedCampaignList}
                campaignsLoading={campaignsLoading}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

/**
 * Page
 */
export default function BuyerHomePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSharedCampaigns());
  }, [dispatch]);

  const pageActions = (
    <>
      <p className="pt-2">Version : {process.env.REACT_APP_VERSION}</p>
      {/* <p>Last updated Thursday, March 20, 2021, 10:45 PM</p> */}
    </>
  );

  return (
    <div className="content-wrapper bg-alt min-vh-100">
      {/* Page Header */}
      <PageHeader
        title={"Dashboard"}
        border={false}
        shadow={false}
        actions={pageActions}
      />

      <div className="page-content">
        <SharedCampaignsTable />
      </div>
    </div>
  );
}
