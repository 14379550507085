import { sendGetRequest } from "../utils/api/apiUtils";
import { DefaultPagination, PageSize } from "../constants/GeneralConstants";
import {
  getRegionDataByCityUrl,
  getRegionListByTypeUrl,
  getRegionNamesUrl,
} from "../urls/RegionURL";

export async function getRegionDataByCityId(cityId) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const url = getRegionDataByCityUrl.replace(":cityId", cityId);
  return sendGetRequest(url, {}, true, headerParams);
}

export async function getRegionNamesList() {
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendGetRequest(getRegionNamesUrl, {}, true, headerParams);
}

export async function getRegionListByTypeFn(type, pageNo, pageSize) {
  const headerParams = {
    "Content-Type": "application/json",
  };

  const params = {
    _type: type || "CITY",
    pn: pageNo || DefaultPagination.pageNumber,
    ps: pageSize || PageSize.regionList,
  };

  return sendGetRequest(getRegionListByTypeUrl, params, true, headerParams);
}
