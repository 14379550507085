import React from "react";

// Other Page Sections
import ProtectedRoute from "../ProtectedRoute";
import { constructRouterPath } from "../App";

// Constants and Utils
import { OrgType } from "../constants/OrgTypeConstants";
import { getItem } from "../utils/localstorage";
import { LocalStorageKeys } from "../constants/GeneralConstants";
import { checkIsBuyerUser } from "../utils/BuyerUtils";

// AAO pages
import HomePage from "./pages/home/HomePage";
import CampaignPlanningPage from "./pages/campaign-planning/CampaignPlanningPage";
import MediaSelectionPage from "./pages/media-selection/MediaSelectionPage";
import CampaignReviewOverviewPage from "./pages/campaign-review-overview/CampaignReviewOverviewPage";
import CampaignReviewPage from "./pages/campaign-review/CampaignReviewPage";
import CampaignDraftOverviewPage from "./pages/campaign-draft-overview/CampaignDraftOverviewPage";
import CampaignDraftPage from "./pages/campaign-draft/CampaignDraftPage";
import CampaignConfirmedOverviewPage from "./pages/campaign-confirmed-overview/CampaignConfirmedOverviewPage";
import CampaignConfirmedPage from "./pages/campaign-confirmed/CampaignConfirmedPage";
import CampaignScheduleOverviewPage from "./pages/campaign-schedule-overview/CampaignScheduleOverviewPage";
import CampaignSchedulePage from "./pages/campaign-schedule/CampaignSchedulePage";
import CampaignLiveOverviewPage from "./pages/campaign-live-overview/CampaignLiveOverviewPage";
import CampaignLivePage from "./pages/campaign-live/CampaignLivePage";
import CampaignCompleteOverviewPage from "./pages/campaign-complete-overview/CampaignCompleteOverviewPage";
import CampaignCompletePage from "./pages/campaign-complete/CampaignCompletePage";
import CampaignCancelOverviewPage from "./pages/campaign-cancel-overview/CampaignCancelOverviewPage";
import CampaignCancelPage from "./pages/campaign-cancel/CampaignCancelPage";
import CampaignStopOverviewPage from "./pages/campaign-stop-overview/CampaignStopOverviewPage";
import CampaignStopPage from "./pages/campaign-stop/CampaignStopPage";

// Buyer Pages
import BuyerHomePage from "./pages/home/BuyerHomePage";

// Seller page
import SellerHomePage from "./pages/home/SellerHomePage";
import SellerEarningsPage from "./pages/seller-earnings/SellerEarningsPage";
import SellerSiteEarningsPage from "./pages/seller-site-earnings/SellerSiteEarningsPage";

// Seller pitching pages
import SellerSingleSiteReportPage from "./pages/seller-single-site-report/SellerSingleSiteReportPage";

// Buyer Pages
import BuyerListPage from "./pages/buyer/BuyerListPage";
import BuyerDetailsPage from "./pages/buyer/BuyerDetailsPage";

// Error page
import ErrorPage from "../pages/error-pages/ErrorPage";

// CSS
import "./theme/ProohStyle.scss";

function getHomePage() {
  const organizationType = getItem(LocalStorageKeys.ORG_TYPE);
  const isBuyerUser = checkIsBuyerUser();

  if (isBuyerUser) {
    return <BuyerHomePage />;
  }

  if (organizationType === OrgType.MEDIA_AGENCY) {
    return <HomePage />;
  }

  if (organizationType === OrgType.MEDIA_OWNER) {
    return <SellerHomePage />;
  }

  // old logic for buyer user login
  // if (organizationType === OrgType.BRAND_OWNER) {
  //   return <BuyerHomePage />;
  // }

  return null;
}

function ProohApp() {
  return (
    <>
      {/* Dashboard Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/home", true, true)}
        component={getHomePage}
      />

      {/* Planning Page */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/planning", true, true)}
        component={CampaignPlanningPage}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/planning", true, true)}
        component={CampaignPlanningPage}
      />

      {/* Media Selection Page */}
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/media-selection",
          true,
          true
        )}
        component={MediaSelectionPage}
      />

      {/* Campaign Review Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/review", true, true)}
        component={CampaignReviewOverviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/review",
          true,
          true
        )}
        component={CampaignReviewPage}
      />

      {/* Campaign Draft Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/draft", true, true)}
        component={CampaignDraftOverviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/draft",
          true,
          true
        )}
        component={CampaignDraftPage}
        sidebar={true}
      />

      {/* Campaign Schedule Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/schedule", true, true)}
        component={CampaignScheduleOverviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/schedule",
          true,
          true
        )}
        component={CampaignSchedulePage}
        sidebar={true}
      />

      {/* Campaign Confirm Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/confirm", true, true)}
        component={CampaignConfirmedOverviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/confirm",
          true,
          true
        )}
        component={CampaignConfirmedPage}
        sidebar={true}
      />

      {/* Campaign Live Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/live", true, true)}
        component={CampaignLiveOverviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/live",
          true,
          true
        )}
        component={CampaignLivePage}
        sidebar={true}
      />

      {/* Campaign Complete Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/complete", true, true)}
        component={CampaignCompleteOverviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/complete",
          true,
          true
        )}
        component={CampaignCompletePage}
        sidebar={true}
      />

      {/* Campaign Cancel Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/cancel", true, true)}
        component={CampaignCancelOverviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/cancel",
          true,
          true
        )}
        component={CampaignCancelPage}
        sidebar={true}
      />

      {/* Campaign Stop Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/stop", true, true)}
        component={CampaignStopOverviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/stop",
          true,
          true
        )}
        component={CampaignStopPage}
        sidebar={true}
      />

      {/* Seller Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/seller/earnings", true, true)}
        component={SellerEarningsPage}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/seller/earnings/media/:mediaId",
          true,
          true
        )}
        component={SellerSiteEarningsPage}
      />

      {/* seller pitching pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/seller/pitch/single-site-report",
          true,
          true
        )}
        component={SellerSingleSiteReportPage}
        sidebar={false}
      />

      {/* Buyer pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/buyer/list", true, true)}
        component={BuyerListPage}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath("/buyer/:buyerId/details", true, true)}
        component={BuyerDetailsPage}
      />

      <ProtectedRoute exact component={ErrorPage} />
    </>
  );
}

export default ProohApp;
