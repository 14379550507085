import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

// Components
import { PlainDropdown } from "../../../components/dropdown/Dropdown";

const toohlSortingIds = {
  ascendingImp: "ascendingImp", // est
  descendingImp: "descendingImp", // est
  descendingTgImp: "descendingTgImp", // tg
};

function getSortingOptions(tgId) {
  // TOOHL Specific
  if (tgId) {
    return [
      {
        id: toohlSortingIds.descendingTgImp,
        label: (
          <span className="d-flex align-items-center">
            TG Imp <i className="fa fa-arrow-up ml-1 opacity-75" />
          </span>
        ),
      },
    ];
  }
  return [
    {
      id: toohlSortingIds.descendingImp,
      label: (
        <span className="d-flex align-items-center">
          Impressions <i className="fa fa-arrow-up ml-1 opacity-75" />
        </span>
      ),
    },
    {
      id: toohlSortingIds.ascendingImp,
      label: (
        <span className="d-flex align-items-center">
          Impressions <i className="fa fa-arrow-down ml-1 opacity-75" />
        </span>
      ),
    },
  ];

  // PROOH Specific
  // if (tgId) {
  //   return [
  //     { id: "none", label: "" },
  //     { id: "totalOts", label: "Total Imp" },
  //     { id: "otsLitSpecific", label: "Tg Imp" },
  //     { id: "otsLitSpecificPercentage", label: "Tg %" },
  //   ];
  // }
  // return [
  //   { id: "none", label: "" },
  //   { id: "totalOts", label: "Total Imp" },
  // ];
}

const sortButtonDataMap = {
  totalOts: "Total Imp",
  otsLitSpecific: "Tg Imp",
  otsLitSpecificPercentage: "Tg %",
};

function SortingBtn({ requestRoadStretchSort, requestRoadSegmentsSort }) {
  const [sortingId, setSortingId] = useState("");
  const tgId = useSelector((state) => state.tgSpecificOts.tgId);

  // automatically sorting for tgImp once tg is selected
  useEffect(() => {
    if (tgId) {
      const descTgImpId = toohlSortingIds.descendingTgImp;
      setSortingId(descTgImpId);
      requestRoadStretchSort(descTgImpId);
      requestRoadSegmentsSort(descTgImpId);
    }
  }, [tgId]);

  // function
  function handleSort(id) {
    setSortingId(id);
    const label = sortButtonDataMap[id];
    requestRoadStretchSort(id, label);
    requestRoadSegmentsSort(id, label);
  }

  const sortingOptions = getSortingOptions(tgId);

  return (
    <PlainDropdown
      items={sortingOptions}
      onItemSelect={(id) => handleSort(id)}
      className="btn shadow-none p-0 justify-content-center"
      textClassName="px-1"
      selectedId={sortingId}
      placeHolder="Apply Sort"
      dropMenuClassName="dropdown-menu-right"
    />
  );
}

// get Ots
function getOts(value) {
  const { otsAvg, otsLitAvg } = value || {};
  return otsLitAvg ? otsLitAvg : otsAvg;
}

// get Tg Ots
function getTgOts(value) {
  const { targetOtsLit, genericOts } = value || {};
  return targetOtsLit ? targetOtsLit : genericOts;
}

// get Total Ots
function getTotalOts(value) {
  const { otsAvg, otsLitAvg } = value || {};

  if (otsAvg && otsLitAvg) {
    return otsAvg + otsLitAvg;
  }

  const { genericOtsLit, genericOts, targetOtsLit, targetOts } = value || {};
  const totalOts =
    (genericOtsLit ? genericOtsLit : genericOts) +
    (targetOtsLit ? targetOtsLit : targetOts);
  return totalOts;
}

// get Tg Specific Percentage
function getTgSpecificPercentage(value) {
  const { targetOtsLit, genericOtsLit } = value || {};
  const totalOts = targetOtsLit + genericOtsLit;
  const percentage = (targetOtsLit / totalOts) * 100;
  return percentage;
}

// useSortableData
const useSortableData = (roadsData) => {
  const [sortConfig, setSortConfig] = useState(null);
  const sortedItems = useMemo(() => {
    const sortableItems = [...roadsData];

    // sort config contains id and label
    if (sortConfig !== null) {
      sortableItems.sort((valueOne, valueTwo) => {
        // TOOHL Specific Sorting Options
        // -------------------------------------------------------------------------
        // comparing for Impression "Ascending"
        if (
          sortConfig.id === toohlSortingIds.ascendingImp &&
          getOts(valueOne) < getOts(valueTwo)
        ) {
          return -1;
        }

        if (
          sortConfig.id === toohlSortingIds.ascendingImp &&
          getOts(valueOne) > getOts(valueTwo)
        ) {
          return 1;
        }

        // comparing for Impression "Descending"
        if (
          sortConfig.id === toohlSortingIds.descendingImp &&
          getOts(valueOne) < getOts(valueTwo)
        ) {
          return 1;
        }

        if (
          sortConfig.id === toohlSortingIds.descendingImp &&
          getOts(valueOne) > getOts(valueTwo)
        ) {
          return -1;
        }

        // comparing for TG-Impression "Descending"
        if (
          sortConfig.id === toohlSortingIds.descendingTgImp &&
          getTgOts(valueOne) < getTgOts(valueTwo)
        ) {
          return 1;
        }

        if (
          sortConfig.id === toohlSortingIds.descendingTgImp &&
          getTgOts(valueOne) > getTgOts(valueTwo)
        ) {
          return -1;
        }

        // PROOH Specific Sorting Options
        // -------------------------------------------------------------------------
        // comparing Tg Specific % impressions
        if (
          sortConfig.label === "Tg %" &&
          getTgSpecificPercentage(valueTwo) > getTgSpecificPercentage(valueOne)
        ) {
          return 1;
        }

        // if value one is greater than value two it returns ascending order
        if (
          sortConfig.label === "Tg %" &&
          getTgSpecificPercentage(valueTwo) < getTgSpecificPercentage(valueOne)
        ) {
          return -1;
        }

        // comparing total Impressions
        if (
          sortConfig.label === "Total Imp" &&
          getTotalOts(valueOne) < getTotalOts(valueTwo)
        ) {
          return 1;
        }

        if (
          sortConfig.label === "Total Imp" &&
          getTotalOts(valueOne) > getTotalOts(valueTwo)
        ) {
          return -1;
        }

        // comparing tg Specific
        if (
          sortConfig.label === "Tg Imp" &&
          getTgOts(valueOne) < getTgOts(valueTwo)
        ) {
          return 1;
        }

        if (
          sortConfig.label === "Tg Imp" &&
          getTgOts(valueOne) > getTgOts(valueTwo)
        ) {
          return -1;
        }

        return 0;
      });
    }
    // if the sort config is null it will return unsorted data
    return sortableItems;
  }, [roadsData, sortConfig]);

  const requestSort = (id, label) => {
    setSortConfig({ id, label });
  };

  return [sortedItems, requestSort, sortConfig];
};

export { useSortableData, SortingBtn };
