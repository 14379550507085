import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { getAllCampaigns } from "../../actions/campaign/CampaignActions";

// Components
import Spinner from "../../components/spinner/Spinner";
import Pagination from "../pagination/Pagination";
import Hamburger from "../../components/hamburger-menu/Hamburger";
import TableHeaders from "../../components/table/TableHeaders";
import NoTableDataMessage from "../table/NoTableDataMessage";

// Utils and Constants
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";
import {
  CampaignDemographicStatsStatus,
  CampaignReportStatus,
  UrlKeys,
} from "../../constants/GeneralConstants";
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// CSS
import "./CampaignTable.scss";

// Constants

const tableHeaders = [
  {
    title: {
      displayName: "Campaign Name",
    },
  },
  {
    title: {
      displayName: "Cities",
    },
  },
  {
    title: {
      displayName: "Sites",
    },
  },
  {
    title: {
      displayName: "Last updated",
    },
  },

  {
    title: {
      displayName: "Actions",
    },
  },
];

function Loader() {
  return (
    <tr className="text-center">
      <td colSpan={6}>
        <Spinner />
      </td>
    </tr>
  );
}

function CampaignActionsCell({ campaign }) {
  // Campaign Data
  const {
    id: campaignId,
    reportStatus,
    isPlan,
    isCompleted,
    isMonitoringEnabled,
    isMonitoringOnly,
    demographicStatisticsStatus,
  } = campaign;

  // Urls
  const campaignPlanOverviewPageUrl =
    RedirectTo.campaignPlanOverviewPageUrl.replace(":campaignId", campaignId);
  const monitorCampaignViewPageUrl =
    RedirectTo.monitorCampaignViewPageUrl.replace(":campaignId", campaignId);
  const campaignViewPageUrl = RedirectTo.campaignViewPageUrl.replace(
    ":campaignId",
    campaignId
  );
  const campaignGraphReportPageUrl =
    RedirectTo.graphReportsCampaignPageUrl.replace(":campaignId", campaignId);

  // is campaign report generated
  const isCampaignReportGenerated =
    reportStatus === CampaignReportStatus.GENERATED;

  // checking is mantaray specific
  const isMantaraySpecific = useUrlKeyCheck(UrlKeys.mantaraySpecific);

  // checking Demographic stats processed
  const isDemographicStatsProcessed =
    demographicStatisticsStatus === CampaignDemographicStatsStatus.PROCESSED;

  // booleans
  const isShowGraphReportColumn = !isMantaraySpecific;
  const isShowGraphReportLink =
    !isMonitoringOnly &&
    isCampaignReportGenerated &&
    isDemographicStatsProcessed;

  return (
    <td>
      <div className="d-flex justify-content-between">
        {/** Planning */}
        <div className="camp-link">
          {isPlan && !isCompleted && (
            <Link to={constructRedirectPath(campaignPlanOverviewPageUrl)}>
              Continue Planning
            </Link>
          )}
        </div>

        {/** Monitoring */}
        <div className="camp-link">
          {(isPlan || isMonitoringEnabled) && (
            <Link to={constructRedirectPath(monitorCampaignViewPageUrl)}>
              {isCompleted ? "View" : "Manage"} Monitoring
            </Link>
          )}
        </div>

        {/** Report */}
        <div className="camp-link">
          {!isMonitoringOnly && (
            <Link to={constructRedirectPath(campaignViewPageUrl)}>
              {isCampaignReportGenerated ? "View Report" : "Generate Report"}
            </Link>
          )}
        </div>

        {/** Graph Report : hiding option for mantaray */}
        {isShowGraphReportColumn && (
          <div className="camp-link">
            {isShowGraphReportLink && (
              <Link to={constructRedirectPath(campaignGraphReportPageUrl)}>
                Graph Report
              </Link>
            )}
          </div>
        )}

        <div className="d-none">
          <Hamburger>
            <button className="dropdown-item">Download Report</button>
            <button className="dropdown-item d-none">Share Link</button>
            <button className="dropdown-item">Archive</button>
          </Hamburger>
        </div>
      </div>
    </td>
  );
}

const CampaignsTableRow = ({ campaign }) => {
  return (
    <tr>
      <td>{campaign.title}</td>
      <td>{campaign.cityCount}</td>
      <td>{campaign.mediaCount}</td>
      <td>{new Date(campaign.updatedOn).toDateString()}</td>
      <CampaignActionsCell campaign={campaign} />
    </tr>
  );
};

function CampaignsTableBody({ loading, isCampaignsPresent, campaigns }) {
  if (loading) {
    return <Loader />;
  }

  //if there is no campaign
  if (!isCampaignsPresent) {
    return <NoTableDataMessage colSpan={6} message="No campaigns present" />;
  }

  return (
    <>
      {campaigns.map((campaign, i) => (
        <CampaignsTableRow key={campaign.id} campaign={campaign} i={i} />
      ))}
    </>
  );
}

// Table Component
function CampaignsTable({
  campaigns,
  isCampaignsPresent,
  loading,
  pagination,
  searchText = "",
}) {
  const dispatch = useDispatch();
  const { totalCount, pageNo, pageSize } = pagination;

  function loadCampaignList(pageNumber, pageSizeCount) {
    dispatch(getAllCampaigns(searchText, pageNumber, pageSizeCount));
  }

  return (
    <div className="table-responsive mt-3">
      <table className="table border table-reports">
        <TableHeaders
          scope={"col"}
          tableHeaders={tableHeaders}
          headerClass={"thead bg-light"}
        />
        <tbody>
          <CampaignsTableBody
            campaigns={campaigns}
            isCampaignsPresent={isCampaignsPresent}
            loading={loading}
          />
        </tbody>
      </table>

      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={loadCampaignList}
      />
    </div>
  );
}

export default CampaignsTable;
