import { OrgMedia } from "../../constants/action-constants/org/OrgMediaActionConstants";

// initial state
const initialState = {
  // Org Media Information
  // ------------------------------------------------------------------
  orgMediaInfo: {},
  roadSegmentTrafficInfo: {},
  orgMediaFileItems: [],
  orgMediaInfoLoading: false,
  orgMediaInfoError: "",

  // Add Org Media Images
  // ------------------------------------------------------------------
  addOrgMediaImagesLoading: false,
  addOrgMediaImagesError: "",

  // Org Media Pdf Report
  // -----------------------------------------------------------
  downloadOrgMediaPdfLoading: false,
  downloadOrgMediaPdfError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Org Media Information
    // ------------------------------------------------------------------
    case OrgMedia.GET_ORG_MEDIA_INFO:
      return {
        ...state,
        orgMediaInfoLoading: true,
      };

    case OrgMedia.GET_ORG_MEDIA_INFO_SUCCESS: {
      const { roadSegmentTrafficInfo, orgMediaInfo } = action.payload;
      return {
        ...state,
        orgMediaInfoLoading: false,
        orgMediaInfo: orgMediaInfo,
        roadSegmentTrafficInfo: roadSegmentTrafficInfo,
        orgMediaFileItems: orgMediaInfo.fileItems,
      };
    }

    case OrgMedia.GET_ORG_MEDIA_INFO_FAILURE:
      return {
        ...state,
        orgMediaInfoLoading: false,
        orgMediaInfoError: action.payload,
      };

    // Add org Media Images
    // ------------------------------------------------------------------
    case OrgMedia.ADD_ORG_MEDIA_IMAGES:
      return {
        ...state,
        addOrgMediaImagesLoading: true,
      };

    case OrgMedia.ADD_ORG_MEDIA_IMAGES_SUCCESS:
      return {
        ...state,
        addOrgMediaImagesLoading: false,
        sellerMediaFileItems: action.sellerMediaFileItems,
      };

    case OrgMedia.ADD_ORG_MEDIA_IMAGES_FAILURE:
      return {
        ...state,
        addOrgMediaImagesLoading: false,
        addOrgMediaImagesError: action.payload,
      };

    // Download Report PDF
    //-------------------------------------------------------------------------------------
    case OrgMedia.DOWNLOAD_ORG_MEDIA_PDF_REPORT:
      return {
        ...state,
        downloadOrgMediaPdfLoading: true,
      };

    case OrgMedia.DOWNLOAD_ORG_MEDIA_PDF_REPORT_SUCCESS:
      return {
        ...state,
        downloadOrgMediaPdfLoading: false,
      };

    case OrgMedia.DOWNLOAD_ORG_MEDIA_PDF_REPORT_FAILURE:
      return {
        ...state,
        downloadOrgMediaPdfLoading: false,
        downloadOrgMediaPdfError: action.payload,
      };

    default:
      return state;
  }
};
