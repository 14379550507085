import { ZonesAndSubZones } from "../../constants/action-constants/ZonesAndSubZonesActionConstants";

export const getZones = (regionId) => ({
  type: ZonesAndSubZones.GET_ZONES,
  payload: { regionId },
});

export const selectZone = (zone) => ({
  type: ZonesAndSubZones.SELECT_ZONE,
  payload: { zone },
});

export const unSelectZone = (zone) => ({
  type: ZonesAndSubZones.UN_SELECT_ZONE,
  payload: { zone },
});

export const getFrequentlyOptedLocations = (cityId, pageNo, pageSize) => ({
  type: ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS,
  payload: { cityId, pageNo, pageSize },
});

export const clearSelectedRegionAndZonesMap = () => ({
  type: ZonesAndSubZones.CLEAR_SELECTED_REGION_AND_ZONES_MAP,
});
