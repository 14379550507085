import { all, put, takeLatest, select } from "redux-saga/effects";
import moment from "moment";
import { toast } from "react-toastify";

// Apis
import {
  mapSellerMediaToCampaign,
  deleteCampaignMedia,
  createCampaignMedia,
  findMavinMetrics,
  getMediaById,
  uploadSiteImages,
  updateMavinMetrics,
  getCampaignMedias,
  updateCampaignMediaDuration as updateCampaignMediaDurationFunction,
  getCampaignMediaByCity as getCampaignMediaByCityFunction,
  downloadMediaPdfReportFn,
  getTrafficInfoByRoadId,
} from "../../apis/CampaignMediaAPI";

// Constants and Utils
import { ActionTypes } from "../../constants/ActionConstants";
import { CampaignCityReport } from "../../constants/action-constants/CampaignCityReportActionConstants";
import { CampaignMedia } from "../../constants/action-constants/CampaignMediaActionConstants";
import { saveFile } from "../../common-utils/file-utils/FileUtils";
import { getErrorMessage } from "../../utils/util";
import {
  PageSize,
  DATE_FORMATS,
  ReportConstants,
  FilenameExtensions,
} from "../../constants/GeneralConstants";
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import {
  getDifferenceInDays,
  getTimestampFromMomentDate,
} from "../../common-utils/date-utils/DateUtils";

/**
 * Create Media
 */

export function* createMedia(action) {
  let createdCampaignMedia = "";

  // Fetch campaignId and mediaId from payload
  const { createMediaBean, imageFiles, campaignId, cityId } = action.payload;

  // Make POST call to create campaignMedia
  try {
    createdCampaignMedia = yield createCampaignMedia(campaignId, cityId, [
      createMediaBean,
    ]);
    yield put({
      type: CampaignMedia.CAMPAIGN_MEDIA_CREATE_SUCCESS,
      payload: createdCampaignMedia.id,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: CampaignMedia.CAMPAIGN_MEDIA_CREATE_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
    return;
  }

  // Make call to upload images to created campaignMedia
  if (imageFiles.length === 0) {
    yield put({
      type: CampaignMedia.SKIP_UPLOAD_FILE_ITEMS,
    });

    // close the create media pop-up form here..
    yield put({
      type: CampaignMedia.CLOSE_CREATE_MEDIA_MODAL,
    });

    // These two actions are executed asyncronoulsy..
    // Refreshing the page as we need to display the newly created media..
    // Refreshing the citySummary as we need to update the media count info..
    yield all([
      put({
        type: CampaignMedia.CAMPAIGN_CITYVIEW,
        payload: { id: campaignId, cityId: cityId },
      }),

      put({
        type: CampaignCityReport.GET_CITY_OVERVIEW,
        payload: { campaignId, cityId },
      }),
    ]);
    return;
  }

  try {
    const fileItems = yield uploadSiteImages(
      campaignId,
      createdCampaignMedia.id,
      imageFiles
    );
    yield put({
      type: CampaignMedia.UPLOAD_FILE_ITEMS_SUCCESS,
      payload: fileItems,
    });

    // close the create media pop-up form here..
    yield put({
      type: CampaignMedia.CLOSE_CREATE_MEDIA_MODAL,
    });

    // Refreshing the city-media list as we need to display the newly created media..
    yield put({
      type: CampaignMedia.CAMPAIGN_CITYVIEW,
      payload: { id: campaignId, cityId: cityId },
    });

    // Refreshing the citySummary as we need to update the media count info..
    yield put({
      type: CampaignCityReport.GET_CITY_OVERVIEW,
      payload: { campaignId, cityId },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: CampaignMedia.UPLOAD_FILE_ITEMS_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export function* generateMavinMetrics(action) {
  // Fetch campaignId and campaignMediaIds from payload
  const { campaignId, cityId } = action.payload;

  // Make PUT call to generate Mavin Metrics...
  try {
    yield findMavinMetrics(campaignId, cityId);

    // These two actions are executed asyncronoulsy..
    // Refreshing the page as we need to display the newly created media..
    // Refreshing the citySummary as we need to update the media count info..
    yield all([
      put({
        type: CampaignMedia.CAMPAIGN_CITYVIEW,
        payload: { id: campaignId, cityId: cityId },
      }),

      put({
        type: CampaignCityReport.GET_CITY_OVERVIEW,
        payload: { campaignId, cityId },
      }),
    ]);

    yield put({
      type: CampaignMedia.GENERATE_MAVIN_METRICS_SUCCESS,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: CampaignMedia.GENERATE_MAVIN_METRICS_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

/**
 * Media details of a campaign
 */
export function* getMediaDetails(action) {
  try {
    const { campaignId, mediaId } = action.payload;

    const mediaDetails = yield getMediaById(campaignId, mediaId);

    const roadSegmentTrafficInfo = {};

    // Api to get traffic data
    if (mediaDetails.roadSegmentIds?.length > 0) {
      const roadSegmentId = mediaDetails.roadSegmentIds[0];
      const trafficInfoOfRoad = yield getTrafficInfoByRoadId(roadSegmentId);
      Object.assign(roadSegmentTrafficInfo, trafficInfoOfRoad);
    }

    yield put({
      type: CampaignMedia.GET_MEDIA_BY_ID_SUCCESS,
      payload: { mediaDetails, roadSegmentTrafficInfo },
    });
  } catch (err) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(err);

    /* istanbul ignore next */
    yield put({
      type: CampaignMedia.GET_MEDIA_BY_ID_FAILURE,
      payload: err,
    });
  }
}

export function* addMediaToCampaign(action) {
  try {
    const { campaignId, mediaIds } = action.payload;

    const result = yield mapSellerMediaToCampaign(campaignId, mediaIds);

    //dispatching action
    yield put({
      type: CampaignMedia.ADD_CAMPAIGN_MEDIA_SUCCESS,
      payload: {
        result,
      },
    });
  } catch (err) {
    yield put({
      type: CampaignMedia.ADD_CAMPAIGN_MEDIA_FAILURE,
      payload: err,
    });
  }
}

export function* uploadCampaignMediaFiles(action) {
  const { campaignId, mediaId, files } = action.payload;

  try {
    yield put({
      type: CampaignMedia.SET_FILE_ITEMS_LOADING,
    });

    const fileItems = yield uploadSiteImages(campaignId, mediaId, files);
    yield put({
      type: CampaignMedia.UPLOAD_FILE_ITEMS_SUCCESS,
      payload: fileItems,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: CampaignMedia.UPLOAD_FILE_ITEMS_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* removeMediaFromCampaign(action) {
  try {
    const { campaignId, campaignMediaId } = action.payload;

    yield deleteCampaignMedia(campaignId, campaignMediaId);

    //dispatching action
    yield put({
      type: CampaignMedia.REMOVE_CAMPAIGN_MEDIA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: CampaignMedia.REMOVE_CAMPAIGN_MEDIA_FAILURE,
      payload: err,
    });
  }
}

export function* updateCampaignMediaDuration(action) {
  try {
    const { dateObj, media } = action.payload || {};
    const { startDate, endDate } = dateObj || {};

    const {
      id: mediaId,
      campaignId,
      cityId,
      reportStatus,
      pricing,
      duration,
      sellerPrice,
    } = media;
    const { price } = pricing || {};

    // updated duration
    const startTS = getTimestampFromMomentDate(startDate);
    const endTS = getTimestampFromMomentDate(endDate);
    const updatedDuration = getDifferenceInDays(startTS, endTS) - 1;

    // updated Seller Price
    const updatedSellerPrice = sellerPrice
      ? updatedDuration * (sellerPrice / duration)
      : 0;

    // DD-MM-YYYY
    const dateFormat = DATE_FORMATS.date_month_year;

    const body = {
      ...media,
      price: price ?? 0,
      sellerPrice: updatedSellerPrice,
      startDateStr: moment(startDate).format(dateFormat),
      endDateStr: moment(endDate).format(dateFormat),
    };

    // update campaign media duration
    yield updateCampaignMediaDurationFunction(campaignId, mediaId, body);
    yield updateMavinMetrics(campaignId, mediaId, reportStatus);

    // calling Actions to update the "Campaign-City-Overview" and "City-Media-List"
    yield all([
      put({
        type: CampaignCityReport.GET_CITY_OVERVIEW,
        payload: { campaignId, cityId },
      }),
      put({
        type: CampaignMedia.CAMPAIGN_CITYVIEW,
        payload: { id: campaignId, cityId: cityId },
      }),
    ]);

    yield put({
      type: CampaignMedia.UPDATE_CAMPAIGN_MEDIA_DURATION_SUCCESS,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: CampaignMedia.UPDATE_CAMPAIGN_MEDIA_DURATION_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export function* getCampaignMediaByCity(action) {
  try {
    const {
      id,
      cityId,
      pn = 1,
      ps = PageSize.CitySummary,
      applyFilters = false,
    } = action.payload;

    let filters = [];
    if (applyFilters) {
      filters = yield select((state) =>
        Object.keys(state.filter.appliedFilters).reduce((acc, eachKey) => {
          if (!state.filter.appliedFilters[eachKey]) {
            return acc;
          }
          acc.push(eachKey);
          return acc;
        }, [])
      );
    }

    //making ajax call
    const data = yield getCampaignMediaByCityFunction(
      id,
      cityId,
      pn,
      ps,
      filters
    );

    //dispatching action
    yield put({
      type: CampaignMedia.CAMPAIGN_CITYVIEW_SUCCESS,
      data: data,
    });
  } catch (err) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(err);

    /* istanbul ignore next */
    yield put({
      type: CampaignMedia.CAMPAIGN_CITYVIEW_FAILURE,
      payload: err,
    });
  }
}

export function* getMediaOfCampaign(action) {
  try {
    const { campaignId, pageNo, pageSize } = action.payload;
    const campaignMediaInfo = yield getCampaignMedias(
      campaignId,
      pageNo,
      pageSize
    );

    yield put({
      type: ActionTypes.Media.GET_CAMPAIGN_MEDIA_SUCCESS,
      payload: { campaignMediaInfo },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: ActionTypes.Media.GET_CAMPAIGN_MEDIA_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export function* downloadMediaPdfReport(action) {
  try {
    const { campaignId, mediaId, radius, mediaTitle } = action.payload;

    const data = yield downloadMediaPdfReportFn(campaignId, mediaId, radius);

    // file name
    const pdfFileName = mediaTitle
      ? `${mediaTitle}${FilenameExtensions.pdf}`
      : ReportConstants.MEDIA_PDF_REPORT;

    // save file
    saveFile(pdfFileName, data);

    yield put({
      type: CampaignMedia.DOWNLOAD_MEDIA_PDF_REPORT_SUCCESS,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: CampaignMedia.DOWNLOAD_MEDIA_PDF_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

/**
 * Media Sagas
 */
export default function* root() {
  yield all([
    takeLatest(CampaignMedia.CAMPAIGN_MEDIA_CREATE, createMedia),
    takeLatest(CampaignMedia.GENERATE_MAVIN_METRICS, generateMavinMetrics),
    takeLatest(CampaignMedia.GET_MEDIA_BY_ID, getMediaDetails),
    takeLatest(CampaignMedia.UPLOAD_FILE_ITEMS, uploadCampaignMediaFiles),
    takeLatest(
      CampaignMedia.UPDATE_CAMPAIGN_MEDIA_DURATION,
      updateCampaignMediaDuration
    ),
    takeLatest(CampaignMedia.CAMPAIGN_CITYVIEW, getCampaignMediaByCity),
    takeLatest(CampaignMedia.ADD_CAMPAIGN_MEDIA, addMediaToCampaign),
    takeLatest(CampaignMedia.REMOVE_CAMPAIGN_MEDIA, removeMediaFromCampaign),
    takeLatest(CampaignMedia.DOWNLOAD_MEDIA_PDF_REPORT, downloadMediaPdfReport),
    takeLatest(ActionTypes.Media.GET_CAMPAIGN_MEDIA, getMediaOfCampaign),
  ]);
}
