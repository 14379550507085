import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  setTitle,
  updateCampaignDates,
} from "../../actions/campaign-planning/CampaignPlanningActions";
import { openSingleCitySelectionForm } from "../../../actions/SingleCitySelectionFormActions";
import { getRoadStretchesByCityId } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";
import {
  selectZone,
  unSelectZone,
} from "../../actions/campaign-planning/ZonesAndSubZonesActions";

// Constants and Utils
import {
  getDifferenceInDays,
  getTimestamp,
} from "../../../common-utils/date-utils/DateUtils";
import {
  DATE_FORMATS,
  FormDataTargets,
} from "../../../constants/GeneralConstants";
import { MediaTypes } from "./TabConstants";
import { getSelectedFreqOptLocations } from "./CampaignPlanningUtils";

// Components
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import TitleEditor from "../../../components/title-editor/TitleEditor";
import { DropdownWithCheckboxes } from "../../../components/dropdown/Dropdown";

// Page Functions
function constructFrequentlyOptedLocations(frequentlyOptedLocations = []) {
  if (frequentlyOptedLocations.length === 0) {
    return [];
  }

  const constructedFOL = frequentlyOptedLocations.map((location) => {
    const { subZoneName } = location || {};
    return {
      id: subZoneName,
      label: subZoneName,
      ...location,
    };
  });

  return constructedFOL;
}

function getInitialDateAndDuration(startTimestamp, endTimestamp) {
  if (!startTimestamp && !endTimestamp) {
    return { startDateObj: "", duration: "", endDateObj: "" };
  }

  const duration = getDifferenceInDays(startTimestamp, endTimestamp);

  return {
    startDateObj: new Date(startTimestamp),
    endDateObj: new Date(endTimestamp),
    duration,
  };
}

// Frequently Opted Locations
function FrequentlyOptedLocations({ regionsData = {} }) {
  const dispatch = useDispatch();

  // State
  const [isSubZoneSelectTriggered, setIsSubZoneSelectTriggered] =
    useState(false);

  // region data
  const { id: selectedCityId, name: selectedCityName } = regionsData || {};

  // Freq Opt Locations
  const frequentlyOptedLocations = useSelector(
    (state) => state.zonesAndSubZones.frequentlyOptedLocations
  );
  const constructedFreqOptLocations = constructFrequentlyOptedLocations(
    frequentlyOptedLocations
  );

  // Selected Region & Zones Map
  const selectedRegionAndZonesMap = useSelector(
    (state) => state.zonesAndSubZones.selectedRegionAndZonesMap
  );

  const regionSelectedZones = selectedRegionAndZonesMap[selectedCityId] || [];
  const selectedZoneIds = regionSelectedZones.map((zone) => zone.zoneId);
  const selectedFOL = getSelectedFreqOptLocations(
    selectedRegionAndZonesMap,
    selectedCityId
  );

  // Selected Media types
  const selectedMediaTypesMap = useSelector(
    (state) => state.campaignPlanning.selectedMediaTypes
  );
  const selectedMediaTypes = MediaTypes.filter(
    (mediaType) => selectedMediaTypesMap[mediaType]
  );

  // Selected Route Types
  const selectedArterialRoutesMap = useSelector(
    (state) => state.orgRouteTypes.selectedArterialRoutes
  );
  const selectedArterialRoutes = Object.keys(selectedArterialRoutesMap);

  useEffect(() => {
    if (isSubZoneSelectTriggered) {
      dispatch(
        getRoadStretchesByCityId(
          selectedCityId,
          "",
          selectedArterialRoutes,
          selectedMediaTypes,
          selectedZoneIds,
          selectedFOL
        )
      );
      setIsSubZoneSelectTriggered(false);
    }
  }, [isSubZoneSelectTriggered, dispatch]);

  // construct Zone With Sub-Zones
  function constructZoneWithSubZones(subZone = {}, checked = false) {
    const { zoneId, zoneName, subZoneName } = subZone || {};

    const isZoneAlreadySelected = selectedZoneIds.includes(zoneId);
    const zoneData = {
      cityId: selectedCityId,
      cityName: selectedCityName,
      zoneId: zoneId,
      zoneName: zoneName,
    };

    // if zone already not selected
    if (!isZoneAlreadySelected) {
      return { ...zoneData, subZones: [subZoneName] };
    }

    const selectedZone =
      regionSelectedZones.find((zone) => zone.zoneId === zoneId) || {};
    const { subZones } = selectedZone || {};
    const prevSubZones = subZones || [];

    // if checked then append subZone, else remove subZone
    if (checked) {
      const updatedSubZones = [...prevSubZones, subZoneName];
      return { ...zoneData, subZones: updatedSubZones };
    } else {
      const clonedPrevSubZones = [...prevSubZones];
      const subZoneIndex = prevSubZones.findIndex(
        (subZone) => subZone === subZoneName
      );
      clonedPrevSubZones.splice(subZoneIndex, 1);
      return { ...zoneData, subZones: clonedPrevSubZones };
    }
  }

  // handle Checkbox Change
  function handleCheckboxChange(option, e) {
    const { checked } = e.target || {};

    // construct zone with FOL(subZones)
    const constructedZone = constructZoneWithSubZones(option, checked);
    const { subZones = [] } = constructedZone || {};
    const isAnySubZonePresent = subZones.length > 0;

    // store the selection of zone as well
    const dispatchFn = isAnySubZonePresent ? selectZone : unSelectZone;
    dispatch(dispatchFn(constructedZone));

    // this is used to call the stretch api
    setIsSubZoneSelectTriggered(true);
  }

  // header text
  const headerText = "Freq. Opt. Locations";

  return (
    <div className="mx-2">
      <p className="mb-0 text-truncate" title={headerText}>
        {headerText}
      </p>
      <DropdownWithCheckboxes
        options={constructedFreqOptLocations}
        placeHolder="Select Area"
        dropMenuClassName="dropdown-fixed-height min-w-200"
        boxStyle="d-flex align-items-center"
        handleCheckboxChange={handleCheckboxChange}
        selectedItems={selectedFOL}
      />
    </div>
  );
}

// Date And Duration Section
function DateAndDurationSection() {
  const dispatch = useDispatch();

  // Selector
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { startTimestamp, endTimestamp } = campaignPlan || {};
  const {
    startDateObj,
    endDateObj,
    duration: planDuration,
  } = getInitialDateAndDuration(startTimestamp, endTimestamp);

  // State
  const [duration, setDuration] = useState(planDuration);

  // DatePicker Style
  const styleObject = {
    autoApply: true,
    border: true,
    buttonClassName: "min-w-150 px-0",
  };

  // duration string
  const durationString = duration ? `(${duration} Days)` : "";

  // Functions
  function onDateSelect(selectedDate) {
    const { startDate, endDate } = selectedDate || {};
    const startTs = getTimestamp(startDate, DATE_FORMATS.date_month_year);
    const endTs = getTimestamp(endDate, DATE_FORMATS.date_month_year);
    const dayCount = getDifferenceInDays(startTs, endTs) - 1;
    setDuration(dayCount);
    dispatch(updateCampaignDates({ startDate, endDate }, dayCount));
  }

  // header text
  const headerText = `Start Date ~ End Date ${durationString}`;

  return (
    <div className="d-flex">
      <div>
        <p className="mb-0 text-truncate" title={headerText}>
          {headerText}
        </p>
        <BootstrapDateRangePicker
          initialStartDate={startDateObj}
          initialEndDate={endDateObj}
          onApplyDates={onDateSelect}
          styleObject={styleObject}
          // minDate={new Date()}
        />
      </div>
    </div>
  );
}
function SingleCitySelectionSelector() {
  const dispatch = useDispatch();

  const regionsData = useSelector(
    (state) => state.campaignPlanning.regionsData
  );
  const { name: selectedCityName } = regionsData || {};

  // header text
  const headerText = "Select City";

  return (
    <div>
      <p className="mb-0 text-truncate" title={headerText}>
        {headerText}
      </p>
      <button
        type="button"
        className="btn border rounded-lg shadow-none px-2 dropdown-toggle"
        data-toggle="modal"
        data-target={`#${FormDataTargets.singleCitySelectionForm}`}
        onClick={() => dispatch(openSingleCitySelectionForm())}
      >
        {selectedCityName ? selectedCityName : "Select City"}
      </button>
    </div>
  );
}

function SearchStretchSection({
  newRoadStretchesData = [],
  setSearchedStretches = () => {},
}) {
  const [searchedText, setSearchedText] = useState("");

  // whenever stretches are filtered,
  // update the table stretches and set search string as empty
  useEffect(() => {
    setSearchedText("");
    setSearchedStretches(newRoadStretchesData);
  }, [JSON.stringify(newRoadStretchesData)]);

  // whenever search happen, it will trigger and filter stretches
  useEffect(() => {
    const filteredStretches = newRoadStretchesData.filter((stretch) =>
      stretch.name.toLowerCase().includes(searchedText)
    );
    setSearchedStretches(filteredStretches);
  }, [searchedText]);

  // header text
  const headerText = "Search Stretches";

  return (
    <div className="ml-2 max-w-175">
      <p className="mb-0 text-truncate" title={headerText}>
        {headerText}
      </p>
      <input
        type="search"
        className="form-control shadow-none rounded-lg"
        placeholder={"Type Stretch Name"}
        value={searchedText}
        onChange={({ target }) => setSearchedText(target.value)}
      />
    </div>
  );
}

/**
 * Section
 */
function PlanningHeaderSection({
  regionsData = {},
  newRoadStretchesData = [],
  setSearchedStretches = () => {},
}) {
  const dispatch = useDispatch();

  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);
  const { title } = campaign || {};

  const campaignPlanTitle = useSelector(
    (state) => state.campaignPlanning.campaignTitle
  );

  const campaignTitle = campaignPlanTitle ? campaignPlanTitle : title;

  return (
    <>
      <div className="d-flex align-items-center m-3">
        <TitleEditor
          title={campaignTitle}
          onTitleChange={(titleStr) => dispatch(setTitle(titleStr))}
          inputBoxClass="rounded-lg"
          titleClass="px-0 mb-0 h3"
        />
      </div>
      <hr className="my-0"></hr>
      <div className="d-flex align-items-center justify-content-between px-3 py-2">
        <SingleCitySelectionSelector />
        <FrequentlyOptedLocations regionsData={regionsData} />
        <DateAndDurationSection />
        <SearchStretchSection
          newRoadStretchesData={newRoadStretchesData}
          setSearchedStretches={setSearchedStretches}
        />
      </div>
      <hr className="my-0"></hr>
    </>
  );
}

export default PlanningHeaderSection;
