import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import {
  getCampaignPlanOverview,
  changeCampaignPlanStatus,
} from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getCampaignMedia } from "../../../actions/campaign-media/CampaignMediaActions";

// Utils and Constants
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import { defaultPagination } from "../../../constants/UrlConstants";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import { isBuyerUserSpecific } from "../../../components/hoc/MediaAgencySpecific";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import {
  CampaignDetails,
  CancelCampaign,
  ScheduleCampaign,
} from "../components/HeaderNav";
import CityDetailsTable from "../components/CityDetailsTable";
import CampaignViewCards from "../components/CampaignViewCards";
import { CampaignState } from "../components/planningConstants";

function getRedirectUrl(
  campaignId,
  isMakeCampaignLive,
  isMakeCampaignCompleted
) {
  // page redirect urls
  const scheduleOverviewPageUrl = `/campaign/${campaignId}/schedule`;
  const liveOverviewPageUrl = `/campaign/${campaignId}/live`;
  const completeOverviewPageUrl = `/campaign/${campaignId}/complete`;

  // if date are completed/past date, send to completed page
  if (isMakeCampaignCompleted) {
    return completeOverviewPageUrl;
  }

  // if date are live date, send to live page
  if (isMakeCampaignLive) {
    return liveOverviewPageUrl;
  }

  // if date after future date, send to schedule page
  return scheduleOverviewPageUrl;
}

// Page-Component
function ConfirmedOverviewHeader({ campaign, campaignPlanOverview }) {
  const dispatch = useDispatch();
  const history = useHistory();

  if (!campaign || !campaignPlanOverview) {
    return null;
  }

  const { id: campaignId } = campaign || {};
  const { summary = {} } = campaignPlanOverview || {};

  // check campaign can be live
  const { isLive, isCompleted } = summary || {};
  const isMakeCampaignLive = isLive || false;
  const isMakeCampaignCompleted = isCompleted || false;

  // page redirect based on status after schedule
  const redirectUrl = getRedirectUrl(
    campaignId,
    isMakeCampaignLive,
    isMakeCampaignCompleted
  );

  // if campaign is completed (isCompleted = true), then isLive will be false in response,
  // but since to get the live metrics me have make campaign live first then make completed.
  // so changing the value of key (isMakeCampaignLive) accordingly
  const finalIsMakeCampaignLive = isMakeCampaignCompleted
    ? true
    : isMakeCampaignLive;

  function scheduleCampaign() {
    dispatch(
      changeCampaignPlanStatus(
        campaignId,
        CampaignState.SCHEDULED,
        history,
        redirectUrl,
        finalIsMakeCampaignLive,
        isMakeCampaignCompleted
      )
    );
  }

  const EnableCancelCampaign = isBuyerUserSpecific(CancelCampaign);
  const EnableScheduleCampaign = isBuyerUserSpecific(ScheduleCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex">
      {EnableCancelCampaign && <EnableCancelCampaign campaignId={campaignId} />}
      {EnableScheduleCampaign && (
        <EnableScheduleCampaign scheduleCampaign={scheduleCampaign} />
      )}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page
 */
export default function CampaignConfirmedOverviewPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  // Get campaignPlan
  const campaignPlanOverview = useSelector(
    (state) => state.campaignPlan.campaignPlanOverview
  );
  const { planByCity = {} } = campaignPlanOverview || {};

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Tg name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // target group id
  const targetGroupId = Object.values(planByCity)[0]?.targetGroupId;

  const { pageNo, pageSize } = defaultPagination;
  useEffect(() => {
    dispatch(getCampaignPlanOverview(campaignId));
    dispatch(getBasicCampaignDetails(campaignId));

    // campaign medias
    dispatch(getCampaignMedia(campaignId, pageNo, pageSize));
  }, [dispatch, campaignId]);

  // get tg info
  useGetTgInfo(targetGroupId);

  // Sidebar Settings
  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/confirm`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/confirm`,
  };

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      {/* Content-Section */}
      <div className="content-wrapper">
        <ConfirmedOverviewHeader
          campaign={campaign}
          campaignPlanOverview={campaignPlanOverview}
        />

        {/* Page-Content */}
        <div className="page-content">
          <CampaignViewCards
            tgName={tgName}
            campaign={campaign}
            campaignPlanOverview={campaignPlanOverview}
            mediaCount={campaign.mediaCount}
          />

          {/* City Details Table */}
          <CityDetailsTable planByCity={planByCity} campaign={campaign} />
        </div>
      </div>
    </>
  );
}
