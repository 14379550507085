import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getBuyerDetails,
  getUsersByBuyerId,
} from "../../../actions/buyer/BuyerActions";

// Constants and Utils
import { formatText } from "../../../common-utils/string-utils/StringUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Buyer Name And Description
function BuyerNameAndDescription({ buyerDetails = {} }) {
  const { name, description } = buyerDetails || {};
  return (
    <div className="col-8 px-0">
      <h3 className="text-truncate" title={name}>
        {name}
      </h3>
      <p>{description}</p>
    </div>
  );
}

function BuyerAddresses({ buyerDetails = {} }) {
  const { addresses = [] } = buyerDetails || {};
  const isAddressPresent = addresses.length > 0;

  return (
    <div className="col-6 px-0 my-4">
      <h4>Addresses:</h4>
      {!isAddressPresent && (
        <span className="font-italic">No Address Present</span>
      )}

      {isAddressPresent &&
        addresses.map((address, i) => {
          const { line1, line2, city, state, zipcode, contactNo } =
            address || {};
          return (
            <div key={i} className="mb-4">
              <p className="mb-0 text-truncate-3">
                {line1 && <span>{line1},&nbsp;</span>}
                {line2 && <span>{line2}</span>}
              </p>
              <p className="mb-0">
                {city && <span>{city},&nbsp;</span>}
                {state && <span>{state},&nbsp;</span>}
                {zipcode && <span>{zipcode}</span>}
              </p>
              <p className="mb-0">Phone No: {formatText(contactNo)}</p>
            </div>
          );
        })}
    </div>
  );
}

function BuyerUsersList() {
  const usersList = useSelector((state) => state.buyer.usersList);
  const usersListLoading = useSelector((state) => state.buyer.usersListLoading);
  const isUsersPresent = usersList.length > 0;

  return (
    <div className="my-4">
      <h4>{"Users:"}</h4>
      {!isUsersPresent && !usersListLoading && (
        <span className="font-italic">No User Present</span>
      )}

      {isUsersPresent &&
        usersList.map((user, i) => {
          const { id, firstName, lastName, emailId, phoneNo } = user;
          const fullName = `${firstName} ${lastName}`;
          return (
            <div key={id} className="mb-2">
              <div>
                <span>{i + 1}.&nbsp;</span>
                <span>{fullName}</span>
              </div>
              <p className="mb-0 pl-3">
                <span className="pr-2">Phone No: {formatText(phoneNo)},</span>
                <span>Email: {formatText(emailId)}</span>
              </p>
            </div>
          );
        })}
    </div>
  );
}

/**
 * Buyer Details Page
 */
export default function BuyerDetailsPage() {
  const dispatch = useDispatch();
  const { buyerId } = useParams();

  // Buyer Details
  const buyerDetails = useSelector((state) => state.buyer.buyerDetails);
  const buyerDetailsLoading = useSelector(
    (state) => state.buyer.buyerDetailsLoading
  );

  useEffect(() => {
    dispatch(getBuyerDetails(buyerId));
    dispatch(getUsersByBuyerId(buyerId));
  }, [dispatch, buyerId]);

  // Page loading
  if (buyerDetailsLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  const pageTitle = "Buyer Details";

  return (
    <div className="content-wrapper">
      <PageHeader title={pageTitle} />

      <div className="page-content">
        {/* Buyer Details */}
        <BuyerNameAndDescription buyerDetails={buyerDetails} />

        {/* Buyer Addresses */}
        <BuyerAddresses buyerDetails={buyerDetails} />

        {/* Buyer Users */}
        <BuyerUsersList />
      </div>
    </div>
  );
}
