/* eslint-disable no-case-declarations */
// Utils and Constants
import { SellerMedia } from "../../constants/action-constants/seller/SellerMediaActionConstants";
import { DefaultFilterObjects } from "../../constants/GeneralConstants";
import { filterMedia } from "../../utils/MediaUtils";

// initial state
const initialState = {
  // Seller Media by stretchIds states
  // ------------------------------------------------------------------
  sellerMediaByStretchIdsLoading: false,
  sellerMediaByStretchIdsErr: "",
  stretchToSellerMediaMap: {},
  sellerMediaIds: [],

  // seller media by city
  // ---------------------------------------------------------------------
  originalSellerMediaByCityList: [],
  sellerMediaByCityList: [],
  sellerMediaByCityLoading: false,
  sellerMediaByCityError: "",

  // Seller Media Filters
  // ---------------------------------------------------------------------
  sellerMediaFilterObj: DefaultFilterObjects.media,

  // update mavin metrics
  // ------------------------------------------------------------------
  updateSellerMediaMetricsLoading: {},

  // Seller Media Information
  // ------------------------------------------------------------------
  sellerMediaInfo: {},
  roadSegmentTrafficInfo: {},
  sellerMediaFileItems: [],
  sellerMediaInfoLoading: false,
  sellerMediaInfoError: "",

  // Update Seller Media Info
  // ------------------------------------------------------------------
  updateSellerMediaInfoLoading: false,
  updateSellerMediaInfoError: "",

  // Add Seller Media Images
  // ------------------------------------------------------------------
  addSellerMediaImagesLoading: false,
  addSellerMediaImagesError: "",

  // Remove Seller Media Image
  // ------------------------------------------------------------------
  removedImage: {},
  removeSellerMediaImageLoading: false,
  removeSellerMediaImageError: "",

  // Enable Seller Media
  // ------------------------------------------------------------------
  enableSellerMediaLoading: {},
  enableSellerMediaError: "",

  // Disable Seller Media
  // ------------------------------------------------------------------
  disableSellerMediaLoading: {},
  disableSellerMediaError: "",

  // Upload Seller Inventory
  // -----------------------------------------------------------------
  uploadInventoryLoading: false,
  uploadInventoryErr: "",

  // Download Seller Inventory Template
  // -----------------------------------------------------------------
  downloadInventoryTempLoading: false,
  downloadInventoryTempErr: "",

  // Seller Media Pdf Report
  // -----------------------------------------------------------
  downloadSellerMediaPdfLoading: false,
  downloadSellerMediaPdfError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Seller Media by stretchIds
    // -------------------------------------------------------------------------------
    case SellerMedia.GET_SELLER_MEDIA_BY_STRETCH_IDS:
      return {
        ...state,
        sellerMediaByStretchIdsLoading: true,
      };
    case SellerMedia.GET_SELLER_MEDIA_BY_STRETCH_IDS_SUCCESS: {
      const mediaIdsArray = Object.values(
        action.payload.stretchToSellerMedia
      ).map((stretchMedias) =>
        stretchMedias.map((eachMedia) => eachMedia.mediaId)
      );

      const mediaIds = mediaIdsArray.flat();
      return {
        ...state,
        stretchToSellerMediaMap: action.payload.stretchToSellerMedia,
        sellerMediaByStretchIdsLoading: false,
        sellerMediaIds: mediaIds,
      };
    }
    case SellerMedia.GET_SELLER_MEDIA_BY_STRETCH_IDS_FAILURE:
      return {
        ...state,
        sellerMediaByStretchIdsLoading: false,
        sellerMediaByStretchIdsErr: action.payload,
      };
    case SellerMedia.RESET_SELLER_MEDIA_BY_STRETCH_IDS:
      return initialState;

    // Seller media by city
    // -------------------------------------------------------------------------------
    case SellerMedia.GET_SELLER_MEDIA_BY_CITY:
      return {
        ...state,
        sellerMediaByCityLoading: true,
      };

    case SellerMedia.GET_SELLER_MEDIA_BY_CITY_SUCCESS:
      return {
        ...state,
        sellerMediaByCityLoading: false,
        originalSellerMediaByCityList: action.payload.sellerMedia,
        sellerMediaByCityList: action.payload.sellerMedia,
        sellerMediaFilterObj: DefaultFilterObjects.media,
      };

    case SellerMedia.GET_SELLER_MEDIA_BY_CITY_FAILURE:
      return {
        ...state,
        sellerMediaByCityLoading: false,
        sellerMediaByCityError: action.payload,
      };

    case SellerMedia.CLEAR_SELLER_MEDIA_BY_CITY:
      return {
        ...state,
        originalSellerMediaByCityList: [],
        sellerMediaByCityList: [],
      };

    // set Seller Media FITLERS
    // --------------------------------------------------------------------------
    case SellerMedia.SET_SELLER_MEDIA_FILTERS: {
      const { appliedFiltersObj } = action.payload;
      const filteredMediaList = filterMedia(
        state.originalSellerMediaByCityList,
        appliedFiltersObj
      );
      return {
        ...state,
        sellerMediaFilterObj: appliedFiltersObj,
        sellerMediaByCityList: filteredMediaList,
      };
    }

    // Update mavin metrics for seller media
    // -------------------------------------------------------------------------------
    case SellerMedia.UPDATE_METRICS_FOR_SELLER_MEDIA: {
      const { mediaId } = action.payload;
      return {
        ...state,
        updateSellerMediaMetricsLoading: {
          ...state.updateSellerMediaMetricsLoading,
          [mediaId]: true,
        },
      };
    }

    case SellerMedia.UPDATE_METRICS_FOR_SELLER_MEDIA_SUCCESS: {
      const { mediaId, sellerMediaList } = action.payload;

      const updatedLoadingInfo = { ...state.updateSellerMediaMetricsLoading };
      delete updatedLoadingInfo[mediaId];
      return {
        ...state,
        updateSellerMediaMetricsLoading: updatedLoadingInfo,
        sellerMediaByCityList: sellerMediaList,
      };
    }

    case SellerMedia.UPDATE_METRICS_FOR_SELLER_MEDIA_FAILURE: {
      const { error, mediaId } = action.payload;

      const updatedLoadingInfo = { ...state.updateSellerMediaMetricsLoading };
      delete updatedLoadingInfo[mediaId];

      return {
        ...state,
        updateSellerMediaMetricsLoading: updatedLoadingInfo,
        sellerMediaByCityError: error,
      };
    }

    // Seller Media Information
    // ------------------------------------------------------------------
    case SellerMedia.GET_SELLER_MEDIA_INFO:
      return {
        ...state,
        sellerMediaInfoLoading: true,
      };

    case SellerMedia.GET_SELLER_MEDIA_INFO_SUCCESS:
      const { sellerMediaInfo, roadSegmentTrafficInfo } = action.payload;

      return {
        ...state,
        sellerMediaInfoLoading: false,
        sellerMediaInfo: sellerMediaInfo,
        roadSegmentTrafficInfo: roadSegmentTrafficInfo,
        sellerMediaFileItems: sellerMediaInfo.fileItems,
      };

    case SellerMedia.GET_SELLER_MEDIA_INFO_FAILURE:
      return {
        ...state,
        sellerMediaInfoLoading: false,
        sellerMediaInfoError: action.payload,
      };

    // Update Seller Media Info
    // ------------------------------------------------------------------
    case SellerMedia.UPDATE_SELLER_MEDIA_INFO:
      return {
        ...state,
        updateSellerMediaInfoLoading: true,
      };

    case SellerMedia.UPDATE_SELLER_MEDIA_INFO_SUCCESS:
      return {
        ...state,
        updateSellerMediaInfoLoading: false,
        sellerMediaInfo: action.updatedSellerMediaInfo,
      };

    case SellerMedia.UPDATE_SELLER_MEDIA_INFO_FAILURE:
      return {
        ...state,
        updateSellerMediaInfoLoading: false,
        updateSellerMediaInfoError: action.payload,
      };

    // Add Seller Media Images
    // ------------------------------------------------------------------
    case SellerMedia.ADD_SELLER_MEDIA_IMAGES:
      return {
        ...state,
        addSellerMediaImagesLoading: true,
      };

    case SellerMedia.ADD_SELLER_MEDIA_IMAGES_SUCCESS:
      return {
        ...state,
        addSellerMediaImagesLoading: false,
        sellerMediaFileItems: action.sellerMediaFileItems,
      };

    case SellerMedia.ADD_SELLER_MEDIA_IMAGES_FAILURE:
      return {
        ...state,
        addSellerMediaImagesLoading: false,
        addSellerMediaImagesError: action.payload,
      };

    // Remove Seller Media Image
    // ------------------------------------------------------------------
    case SellerMedia.REMOVE_SELLER_MEDIA_IMAGE:
      return {
        ...state,
        removeSellerMediaImageLoading: true,
        removedImage: action.payload.image,
      };

    case SellerMedia.REMOVE_SELLER_MEDIA_IMAGE_SUCCESS:
      const removedImage = action.removedImage;
      const updatedFileItems = state.sellerMediaFileItems.filter(
        (image) => image.thumbnailUrl !== removedImage.thumbnailUrl
      );
      return {
        ...state,
        removeSellerMediaImageLoading: false,
        sellerMediaFileItems: updatedFileItems,
      };

    case SellerMedia.REMOVE_SELLER_MEDIA_IMAGE_FAILURE:
      return {
        ...state,
        removeSellerMediaImageLoading: false,
        removeSellerMediaImageError: action.payload,
      };

    // Enable Seller Media
    // ------------------------------------------------------------------
    case SellerMedia.ENABLE_SELLER_MEDIA: {
      const { mediaId } = action.payload;
      return {
        ...state,
        enableSellerMediaLoading: {
          ...state.enableSellerMediaLoading,
          [mediaId]: true,
        },
      };
    }

    case SellerMedia.ENABLE_SELLER_MEDIA_SUCCESS:
      return {
        ...state,
        enableSellerMediaLoading: {},
      };

    case SellerMedia.ENABLE_SELLER_MEDIA_FAILURE:
      return {
        ...state,
        enableSellerMediaLoading: {},
        enableSellerMediaError: action.payload,
      };

    // Disable Seller Media
    // ------------------------------------------------------------------
    case SellerMedia.DISABLE_SELLER_MEDIA: {
      const { mediaId } = action.payload;
      return {
        ...state,
        disableSellerMediaLoading: {
          ...state.disableSellerMediaLoading,
          [mediaId]: true,
        },
      };
    }

    case SellerMedia.DISABLE_SELLER_MEDIA_SUCCESS:
      return {
        ...state,
        disableSellerMediaLoading: {},
      };

    case SellerMedia.DISABLE_SELLER_MEDIA_FAILURE:
      return {
        ...state,
        disableSellerMediaLoading: {},
        disableSellerMediaError: action.payload,
      };

    // To update seller media status
    // ------------------------------------------------------------------
    case SellerMedia.UPDATE_SELLER_MEDIA_STATUS: {
      const { mediaId, isEnabled } = action.payload;

      const sellerMediaList = [...state.sellerMediaByCityList];
      const updatedMediaList = sellerMediaList.map((media) => {
        if (mediaId !== media.mediaId) {
          return media;
        }
        return {
          ...media,
          isEnabled,
        };
      });

      return {
        ...state,
        sellerMediaByCityList: updatedMediaList,
      };
    }

    // Upload Seller Inventory
    // ------------------------------------------------------------------
    case SellerMedia.SELLER_INVENTORY_UPLOAD: {
      return {
        ...state,
        uploadInventoryLoading: true,
      };
    }

    case SellerMedia.SELLER_INVENTORY_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadInventoryLoading: false,
      };

    case SellerMedia.SELLER_INVENTORY_UPLOAD_FAILURE:
      return {
        ...state,
        uploadInventoryLoading: false,
        uploadInventoryErr: action.payload,
      };

    // Download Seller Inventory Template
    // ------------------------------------------------------------------
    case SellerMedia.DOWNLOAD_SELLER_INVENTORY_TEMPLATE: {
      return {
        ...state,
        downloadInventoryTempLoading: true,
      };
    }

    case SellerMedia.DOWNLOAD_SELLER_INVENTORY_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadInventoryTempLoading: false,
      };

    case SellerMedia.DOWNLOAD_SELLER_INVENTORY_TEMPLATE_FAILURE:
      return {
        ...state,
        downloadInventoryTempLoading: false,
        downloadInventoryTempErr: action.payload,
      };

    // Download Report PDF
    //-------------------------------------------------------------------------------------
    case SellerMedia.DOWNLOAD_SELLER_MEDIA_PDF_REPORT:
      return {
        ...state,
        downloadSellerMediaPdfLoading: true,
      };

    case SellerMedia.DOWNLOAD_SELLER_MEDIA_PDF_REPORT_SUCCESS:
      return {
        ...state,
        downloadSellerMediaPdfLoading: false,
      };

    case SellerMedia.DOWNLOAD_SELLER_MEDIA_PDF_REPORT_FAILURE:
      return {
        ...state,
        downloadSellerMediaPdfLoading: false,
        downloadSellerMediaPdfError: action.payload,
      };

    default:
      return state;
  }
};
