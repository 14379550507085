// sagas.js

import { put, delay, all, takeLatest } from "redux-saga/effects";
import { Cloud } from "../../constants/action-constants/CloudActionConstants";
import {
  getLabelDetectionResultsFn,
  getPresignedUrlFn,
  startLabelDetectionFn,
  uploadVideoFn,
} from "../../apis/CloudAPI";

// Saga to get presigned URL
function* getPresignedUrl(action) {
  try {
    const { fileName, fileType } = action.payload;
    const response = yield getPresignedUrlFn(fileName, fileType);
    yield put({
      type: Cloud.GET_PRESIGNED_URL_SUCCESS,
      payload: response.url,
    });
  } catch (error) {
    yield put({
      type: Cloud.GET_PRESIGNED_URL_FAILURE,
      payload: error.message,
    });
  }
}

// Saga to upload video
function* uploadVideo(action) {
  try {
    const { file, url } = action.payload;
    yield uploadVideoFn(url, file);
    yield put({ type: Cloud.UPLOAD_VIDEO_TO_S3_SUCCESS });
  } catch (error) {
    yield put({
      type: Cloud.UPLOAD_VIDEO_TO_S3_FAILURE,
      payload: error.message,
    });
  }
}

// Saga to start label detection
function* startLabelDetection(action) {
  const { videoS3Url } = action.payload;

  try {
    const response = yield startLabelDetectionFn(videoS3Url);
    yield put({
      type: Cloud.START_LABEL_DETECTION_SUCCESS,
      payload: response.data.jobId,
    });
  } catch (error) {
    yield put({
      type: Cloud.START_LABEL_DETECTION_FAILURE,
      payload: error.message,
    });
  }
}

// Saga to fetch label detection results
function* fetchLabelDetectionResults(action) {
  try {
    const { jobId } = action.payload;
    while (true) {
      const response = yield getLabelDetectionResultsFn(jobId);
      if (response.data.JobStatus === "SUCCEEDED") {
        yield put({
          type: Cloud.GET_LABEL_DETECTION_RESULTS_SUCCESS,
          payload: response.data.Labels,
        });
        break;
      } else if (response.data.JobStatus === "FAILED") {
        yield put({
          type: Cloud.GET_LABEL_DETECTION_RESULTS_FAILURE,
          payload: "Label detection job failed.",
        });
        break;
      }
      yield delay(5000); // Poll every 5 seconds
    }
  } catch (error) {
    yield put({
      type: Cloud.GET_LABEL_DETECTION_RESULTS_FAILURE,
      payload: error.message,
    });
  }
}

// Root saga
export default function* root() {
  yield all([
    takeLatest(Cloud.GET_PRESIGNED_URL, getPresignedUrl),
    takeLatest(Cloud.UPLOAD_VIDEO_TO_S3, uploadVideo),
    takeLatest(Cloud.START_LABEL_DETECTION, startLabelDetection),
    takeLatest(Cloud.GET_LABEL_DETECTION_RESULTS, fetchLabelDetectionResults),
  ]);
}
