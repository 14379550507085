import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import { getRoadStretchesData } from "../../../apis/OrgRoadStretchAPI";

// Constants and utils
import { RoadStretch } from "../../../constants/action-constants/RoadStretchActionConstants";
import { getErrorMessage } from "../../../utils/util";

export function* getCityRoadStretches(action) {
  const {
    cityId,
    isArchived,
    arterialRoutes,
    mediaTypes,
    zones,
    freqOptLocations,
    setSelectedTab, // option key for closing tab
    isStoreCityAllStretchesCount, // for storing original response stretches count for city (1st time)
  } = action.payload;

  try {
    // api response for "road-stretches"
    const roadStretches = yield getRoadStretchesData(
      cityId,
      isArchived,
      arterialRoutes,
      mediaTypes,
      zones,
      freqOptLocations
    );

    yield put({
      type: RoadStretch.GET_CITY_ROAD_STRETCHES_SUCCESS,
      payload: { roadStretches, isStoreCityAllStretchesCount },
    });

    // conditionally closing OpenedTab by setting "index => 0"
    if (setSelectedTab) {
      setSelectedTab(0);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadStretch.GET_CITY_ROAD_STRETCHES_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(RoadStretch.GET_CITY_ROAD_STRETCHES, getCityRoadStretches),
  ]);
}
