import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  clearBuyersList,
  getBuyersList,
} from "../../../actions/buyer/BuyerActions";

export function ErrorMessage({ error }) {
  if (!error) {
    return null;
  }

  return (
    <div id="validationServer03Feedback" className="invalid-feedback">
      {error}
    </div>
  );
}

export function PermissionSection({ formikValuesAndFn }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  const inputDetails = [
    {
      id: "siteAnalyzer",
      value: "Site Analyzer",
    },
    {
      id: "cityAnalyzer",
      value: "City Analyzer",
    },
    {
      id: "campaignReport",
      value: "Campaign Report",
    },
    {
      id: "campaignCreator",
      value: "Campaign Creator",
    },
    {
      id: "campaignMonitor",
      value: "Campaign Monitor",
    },
    {
      id: "customDataLayerManager",
      value: "Custom Data Layer Manager",
    },
    {
      id: "targetGroupManager",
      value: "Target Group Manager",
    },
  ];

  function checksForPermissions(value) {
    return values?.roles?.some((role) => role === value);
  }
  return (
    <>
      <fieldset className="form-group row ">
        <legend className="col-form-label col-sm-2 float-sm-left pt-0">
          Permission
        </legend>

        <div className="col-10 form-check col-sm-10 px-0">
          {inputDetails.map((info, i) => (
            <div key={i}>
              <input
                className={`form-check-input ml-3 mr-1 ${
                  errors.roles && touched.roles && "is-invalid"
                }`}
                type="checkbox"
                id={info.id}
                name={"roles"}
                checked={checksForPermissions(info.value)}
                value={info.value}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor={info.id}>
                {info.value}
              </label>
            </div>
          ))}

          {/* error message for permission */}
          <small className="d-block text-danger mt-1 ml-3">
            {touched.roles && errors.roles}
          </small>
        </div>
      </fieldset>
    </>
  );
}

// Buyer Section
export function BuyerSection({ inputClassName = "", formik = {}, user = {} }) {
  const dispatch = useDispatch();

  // state
  const [buyerName, setBuyerName] = useState("");
  const [selectedBuyer, setSelectedBuyer] = useState({});

  // Selector
  const buyersList = useSelector((state) => state.buyer.buyersList);

  // user data
  const { buyerId, buyerName: usersBuyerName } = user || {};

  // update case
  useEffect(() => {
    if (buyerId) {
      setSelectedBuyer({
        id: buyerId,
        name: usersBuyerName,
      });
    }
  }, [JSON.stringify(user)]);

  useEffect(() => {
    dispatch(clearBuyersList());
  }, [dispatch]);

  // update formik values
  useEffect(() => {
    const { id, name } = selectedBuyer || {};
    formik.setFieldValue("buyerId", id);
    formik.setFieldValue("buyerName", name);
  }, [JSON.stringify(selectedBuyer)]);

  // Search buyer From Input Function
  function searchBrandFn({ target }) {
    const inputText = target.value;
    setBuyerName(inputText);

    if (inputText.length <= 1) {
      dispatch(clearBuyersList());
      return;
    }

    // search buyers
    dispatch(clearBuyersList());
    dispatch(getBuyersList(inputText));
  }

  // handle select brand
  function handleBrandSelect(buyer) {
    setSelectedBuyer(buyer);
    dispatch(clearBuyersList());
  }

  // clear
  function handleRemoveBrand() {
    setSelectedBuyer({});
    setBuyerName("");
    dispatch(clearBuyersList());
  }

  // checking is buyer selected
  const isBuyerSelected = Object.keys(selectedBuyer).length > 0;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Brand</label>
      <div className="col-sm-10">
        {/* buyer remove btn */}
        {isBuyerSelected && (
          <div className="d-flex align-items-center mt-1">
            <span>{selectedBuyer.name}</span>
            <button
              className="btn btn-sm btn-danger ml-4"
              onClick={handleRemoveBrand}
            >
              Remove
            </button>
          </div>
        )}

        {/* search input */}
        {!isBuyerSelected && (
          <>
            <input
              className={inputClassName}
              placeholder="Search Brand"
              value={buyerName}
              onChange={searchBrandFn}
            />

            {/* Suggested Buyer*/}
            {buyersList.length > 0 && (
              <div className="border rounded-lg mt-1 bg-alt">
                {buyersList.map((eachBuyer, i) => (
                  <li
                    key={i}
                    className="dropdown-item cursor-pointer"
                    onClick={() => handleBrandSelect(eachBuyer)}
                  >
                    {eachBuyer.name}
                  </li>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
