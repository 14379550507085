import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// API
import {
  archiveTargetGroup,
  createTargetGroup,
  getTargetGroupAttributes,
  getTargetGroupsList,
  getTg,
  restoreTargetGroup,
  updateTargetGroup,
} from "../../apis/OrgTargetGroupAPI";

// Constants and Utils
import { getErrorMessage } from "../../utils/util";
import { TargetGroup } from "../../constants/action-constants/TargetGroupActionConstants";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

export function* getTargetGroups(action) {
  const { keyWord, pageNumber, pageSize, isArchived, excludeTgsWithName } =
    action.payload;

  try {
    // This queryParam tells whether to fetch the platform related Tg also..
    const includeDefault = false;
    const data = yield getTargetGroupsList(
      includeDefault,
      keyWord,
      pageNumber,
      pageSize,
      isArchived,
      excludeTgsWithName
    );
    const tgList = data.items;
    const pagination = data.pagination;

    yield put({
      type: TargetGroup.GET_TARGET_GROUPS_SUCCESS,
      payload: { tgList, pagination },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: TargetGroup.GET_TARGET_GROUPS_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

function* getTgAttributes() {
  try {
    const attResponse = yield getTargetGroupAttributes();
    yield put({
      type: TargetGroup.GET_TARGET_GROUP_ATTRIBUTES_SUCCESS,
      payload: attResponse,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: TargetGroup.GET_TARGET_GROUP_ATTRIBUTES_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* createNewTargetGroup(action) {
  const { targetGroupBean, history } = action.payload;
  try {
    yield createTargetGroup(targetGroupBean);

    yield put({
      type: TargetGroup.CREATE_TARGET_GROUP_SUCCESS,
    });

    // after creating TG successfully, redirecting to TG-listing page
    history.push(constructRedirectPath("/org/target-group/list"));

    // Toaster Msg on Successful create tg
    toast.success("New Target Group has been successfully created");
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: TargetGroup.CREATE_TARGET_GROUP_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* updateExistingTargetGroup(action) {
  const { targetGroupBean, history, tgId, existingEditableTgName } =
    action.payload;

  try {
    yield updateTargetGroup(targetGroupBean, tgId);

    yield put({
      type: TargetGroup.UPDATE_TARGET_GROUP_SUCCESS,
    });

    // after updating TG successfully, redirecting to TG-listing page
    history.push(constructRedirectPath("/org/target-group/list"));

    // TG-update successful message
    const toasterMsg =
      targetGroupBean.name === existingEditableTgName
        ? "Target Group has been updated"
        : "New Target Group has been successfully created";
    toast.success(toasterMsg);
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: TargetGroup.UPDATE_TARGET_GROUP_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* archiveTg(action) {
  const { tgId } = action.payload;
  try {
    yield archiveTargetGroup(tgId);
    yield put({
      type: TargetGroup.UPDATE_TARGET_GROUP_STATUS,
      payload: { tgId, isArchived: true },
    });
    yield put({
      type: TargetGroup.ARCHIVE_TARGET_GROUP_SUCCESS,
      payload: { tgId },
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: TargetGroup.ARCHIVE_TARGET_GROUP_FAILURE,
      payload: { tgId, errMsg },
    });
    toast.error(errMsg);
  }
}

function* restoreTg(action) {
  const { tgId } = action.payload;
  try {
    yield restoreTargetGroup(tgId);
    yield put({
      type: TargetGroup.UPDATE_TARGET_GROUP_STATUS,
      payload: { tgId, isArchived: false },
    });
    yield put({
      type: TargetGroup.RESTORE_TARGET_GROUP_SUCCESS,
      payload: { tgId },
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: TargetGroup.RESTORE_TARGET_GROUP_FAILURE,
      payload: { tgId, errMsg },
    });
    toast.error(errMsg);
  }
}

export function* getTgInfo(action) {
  const { tgId } = action.payload;
  try {
    const tgInfo = yield getTg(tgId);

    yield put({
      type: TargetGroup.GET_TG_BASIC_INFO_SUCCESS,
      payload: { tgInfo },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: TargetGroup.GET_TG_BASIC_INFO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(TargetGroup.GET_TARGET_GROUPS, getTargetGroups),
    takeLatest(TargetGroup.GET_TARGET_GROUP_ATTRIBUTES, getTgAttributes),
    takeLatest(TargetGroup.CREATE_TARGET_GROUP, createNewTargetGroup),
    takeLatest(TargetGroup.UPDATE_TARGET_GROUP, updateExistingTargetGroup),
    takeLatest(TargetGroup.ARCHIVE_TARGET_GROUP, archiveTg),
    takeLatest(TargetGroup.RESTORE_TARGET_GROUP, restoreTg),
    takeLatest(TargetGroup.GET_TG_BASIC_INFO, getTgInfo),
  ]);
}
