/* eslint-disable no-case-declarations */
import { Buyer } from "../../constants/action-constants/BuyerActionConstants";

const initialState = {
  // buyers list
  buyersList: [],
  buyerListLoading: false,
  buyerListPagination: {},
  buyerListError: "",

  // users list by buyer id
  usersList: [],
  usersListLoading: false,

  // Buyer Add/Edit Modal
  openAddEditBuyerModal: false,
  processDataObject: {},

  // Buyer Details
  buyerDetails: {},
  buyerDetailsLoading: false,
  buyerDetailsError: "",

  // Create Buyer
  createBuyerLoading: false,
  createBuyerError: "",

  // Update Buyer
  updateBuyerLoadingMap: {},
  updateBuyerError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Buyers List
    //--------------------------------------------------------------------------------
    case Buyer.GET_BUYERS_LIST:
      return {
        ...state,
        buyerListLoading: true,
      };
    case Buyer.GET_BUYERS_LIST_SUCCESS:
      const { items, pagination } = action.payload || {};
      return {
        ...state,
        buyerListLoading: false,
        buyersList: items,
        buyerListPagination: pagination,
      };
    case Buyer.GET_BUYERS_LIST_FAILURE:
      return {
        ...state,
        buyerListLoading: false,
        buyerListError: action.payload,
      };

    case Buyer.CLEAR_BUYERS_LIST:
      return {
        ...state,
        buyerListLoading: false,
        buyersList: [],
        buyerListPagination: {},
      };

    // users by buyer id
    //--------------------------------------------------------------------------------
    case Buyer.GET_USERS_BY_BUYER_ID:
      return {
        ...state,
        usersListLoading: true,
      };
    case Buyer.GET_USERS_BY_BUYER_ID_SUCCESS:
      const usersList = action.payload.usersList;
      return {
        ...state,
        usersListLoading: false,
        usersList: usersList.items,
      };
    case Buyer.GET_USERS_BY_BUYER_ID_FAILURE:
      return {
        ...state,
        usersListLoading: false,
        error: action.payload,
      };

    // clear the users state
    case Buyer.CLEAR_BUYER_USERS:
      return {
        ...state,
        usersList: [],
      };

    // Add/Edit Buyer Modal
    // -------------------------------------------------------------------------------
    case Buyer.OPEN_ADD_AND_EDIT_BUYER_FORM:
      return {
        ...state,
        openAddEditBuyerModal: true,
        processDataObject: action.payload.processDataObject,
      };

    case Buyer.CLOSE_ADD_AND_EDIT_BUYER_FORM:
      return {
        ...state,
        openAddEditBuyerModal: false,
        processDataObject: {},
      };

    // Buyer Details
    //--------------------------------------------------------------------------------
    case Buyer.GET_BUYER_DETAILS:
      return {
        ...state,
        buyerDetailsLoading: true,
      };
    case Buyer.GET_BUYER_DETAILS_SUCCESS:
      return {
        ...state,
        buyerDetailsLoading: false,
        buyerDetails: action.payload.buyerDetails,
      };
    case Buyer.GET_BUYER_DETAILS_FAILURE:
      return {
        ...state,
        buyerDetailsLoading: false,
        buyerDetailsError: action.payload,
      };

    // Create Buyer
    //--------------------------------------------------------------------------------
    case Buyer.CREATE_BUYER:
      return {
        ...state,
        createBuyerLoading: true,
      };
    case Buyer.CREATE_BUYER_SUCCESS:
      return {
        ...state,
        createBuyerLoading: false,
      };
    case Buyer.CREATE_BUYER_FAILURE:
      return {
        ...state,
        createBuyerLoading: false,
        createBuyerError: action.payload,
      };

    // Update Buyer
    //--------------------------------------------------------------------------------
    case Buyer.UPDATE_BUYER:
      return {
        ...state,
        updateBuyerLoadingMap: {
          ...state.updateBuyerLoadingMap,
          [action.payload.buyerId]: true,
        },
      };
    case Buyer.UPDATE_BUYER_SUCCESS:
      return {
        ...state,
        updateBuyerLoadingMap: {
          ...state.updateBuyerLoadingMap,
          [action.payload.buyerId]: false,
        },
      };
    case Buyer.UPDATE_BUYER_FAILURE:
      return {
        ...state,
        updateBuyerLoadingMap: {
          ...state.updateBuyerLoadingMap,
          [action.payload.buyerId]: false,
        },
        updateBuyerError: action.payload.error,
      };

    default:
      return state;
  }
};
