import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getRoadStretchesByCityId } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";

// Utils
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import MediaDetailsTable from "../components/MediaDetailsTable";
import MapView from "../components/map-view/MapView";
import CityViewCards from "../components/CityViewCards";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";
import { continueToPlanning } from "../components/CampaignRedirect";
import { isBuyerUserSpecific } from "../../../components/hoc/MediaAgencySpecific";
import {
  CampaignDetails,
  ContinueEditing,
  Review,
  SaveAsDraft,
} from "../components/HeaderNav";

// Page Components
function DraftCityViewHeader({ campaign, cityId }) {
  const history = useHistory();
  const EnableContinueEditing = isBuyerUserSpecific(ContinueEditing);
  const EnableSaveAsDraft = isBuyerUserSpecific(SaveAsDraft);
  const EnableReview = isBuyerUserSpecific(Review);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex ">
      {EnableContinueEditing && (
        <EnableContinueEditing
          onClickAction={() => continueToPlanning(campaign.id, cityId, history)}
        />
      )}
      {EnableSaveAsDraft && <EnableSaveAsDraft />}
      {EnableReview && (
        <EnableReview campaignId={campaign.id} cityId={cityId} />
      )}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page
 */
export default function CampaignDraftPage() {
  const dispatch = useDispatch();
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { targetGroupId } = campaignPlan || {};

  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // City road stretches
  const roadStretchMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  useEffect(() => {
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getRoadStretchesByCityId(cityId, ""));
  }, [dispatch, campaignId, cityId]);

  // tg info
  useGetTgInfo(targetGroupId);

  // Sidebar Settings
  const citySelected = { cityId };
  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/draft`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/draft`,
  };

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      />

      <div className="content-wrapper">
        <DraftCityViewHeader campaign={campaign} cityId={cityId} />

        <div className="page-content">
          <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          />

          {/* Estimated Price Breakdown Table */}
          {/* <EstimatedPriceBreakDown
            tgName={tgName}
            campaign={campaign}
            campaignPlan={campaignPlan}
            submissionImpact={true}
          /> */}

          {/* Map View */}
          <MapView
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />
        </div>
      </div>
    </>
  );
}
