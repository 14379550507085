// Constants and Utils
import { LocalStorageKeys } from "../constants/GeneralConstants";
import { getItem } from "./localstorage";

/**
 * Function to check whether user is Buyer or not
 * @returns true/false
 */
export function checkIsBuyerUser() {
  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { buyerId } = user || {};

  // checking
  const isBuyerUser = buyerId ? true : false;

  return isBuyerUser;
}
