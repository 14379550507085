import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Constants and Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";
import {
  toLocaleString,
  formatText,
} from "../../common-utils/string-utils/StringUtils";
import {
  getIsSubscriptionActive,
  maskCharacter,
} from "../../common-utils/subscription-utils/SubscriptionUtil";
import { getMediaOts, getMediaLtsRating } from "../../utils/mavinMetrics";
import {
  getFrequencyValue,
  getModifiedPopulation,
  // getReachPercentage,
  getReachValueAsString,
} from "../../utils/ReachAndFrequencyUtils";
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";
import {
  LocalStorageKeys,
  OrganizationIdsMapping,
  UrlKeys,
} from "../../constants/GeneralConstants";
import { calculatePercentage } from "../../common-utils/number-utils/NumberUtils";
import { getMediaCostForCampaign } from "../../utils/MediaUtils";
import { getItem } from "../../utils/localstorage";
import { checkIsOrgSpecific } from "../../utils/OrgUtils";

// Components
import { ProgressBar } from "../progress-bar/ProgressBar";

// CSS
import "./MediaRow.scss";

// Impressions Cell
export function ImpressionsCell({ impressionsElementStyle = "", media }) {
  const { ots, tgOts } = getMediaOts(media) || {};

  // Checking Org is Subscribed or Not and is Org Type is Seller
  const isSubscribed = getIsSubscriptionActive();

  const finalImpressions = !isSubscribed
    ? maskCharacter(ots)
    : formatText(toLocaleString(ots ? ots : "NA"));

  const finalTgImpressions = !isSubscribed
    ? maskCharacter(tgOts)
    : formatText(toLocaleString(tgOts ? tgOts : "NA"));

  const tgPercentage = calculatePercentage(tgOts, ots);

  return (
    <td className={impressionsElementStyle}>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-green"></span>
        {finalTgImpressions}
        {/* <span className="float-right">{`(${tgPercentage}%)`}</span> */}
      </p>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-blue"></span>
        {finalImpressions}
      </p>

      {/* showing bars only if ots>0 */}
      {ots > 0 && (
        <>
          <ProgressBar className="bg-light-green" percentage={tgPercentage} />
          <ProgressBar className="bg-light-blue" percentage={100} />
        </>
      )}
    </td>
  );
}

export function LtsCell({ ltsElementClass, media }) {
  const ltsSummary = media.ltsSummary || {};
  const ltsRating = getMediaLtsRating(ltsSummary);
  return <td className={ltsElementClass}>{formatText(ltsRating)}</td>;
}

export function SelectionCell({
  mediaId,
  siteSelected,
  selectSite,
  unSelectSite,
}) {
  const dispatch = useDispatch();

  function toggleMediaSite(e) {
    const action = e.target.checked ? selectSite : unSelectSite;
    dispatch(action(mediaId));
  }

  return (
    <input
      type="checkbox"
      checked={!!siteSelected}
      onChange={toggleMediaSite}
    />
  );
}

export function DefaultImage() {
  return (
    <i className="far fa-images fa-4x media__default-image text-primary"></i>
  );
}

export function MediaImage({ media }) {
  let imageUrl;
  if (media.fileItems && media.fileItems.length) {
    imageUrl = media.fileItems[0].thumbnailUrl;
  }

  if (!imageUrl) {
    return <DefaultImage />;
  }

  return (
    <div className="media">
      <img className="media__site-image" src={imageUrl} alt="thumbnail-image" />
    </div>
  );
}

function MediaLink({ media = {}, redirectUrl }) {
  return (
    <Link to={constructRedirectPath(redirectUrl)} target="_blank">
      {formatText(media.title)}
    </Link>
  );
}

export function MediaMetaData({ media = {} }) {
  const {
    pricing = {},
    duration,
    type,
    height,
    width,
    litStatus,
    region,
    mediaScore,
  } = media || {};
  const { price = 0 } = pricing;

  // cost per month
  const costPerMonthPrice = price ? toLocaleString(Math.floor(price)) : 0;
  const costForCampaign = getMediaCostForCampaign(pricing, duration);
  const costForCampaignPrice = costForCampaign
    ? toLocaleString(Math.floor(costForCampaign))
    : 0;

  // mantaray
  const { mantaraySpecific } = UrlKeys;
  const isMantaraySpecific = useUrlKeyCheck(mantaraySpecific);
  const score = mediaScore ? toLocaleString(mediaScore) : 0;

  // patrika
  const user = JSON.parse(getItem(LocalStorageKeys.USER));
  const { orgId } = user || {};
  const { patrika } = OrganizationIdsMapping;
  const isPatrikaSpecific = checkIsOrgSpecific(orgId, patrika);

  return (
    <div>
      <span className="sub-text">
        {type} | {height} ft X {width} ft {litStatus ? `| ${litStatus}` : ""}
      </span>
      <span className="sub-text">{region}</span>

      {/* Pricing for Patrika */}
      {isPatrikaSpecific && (
        <p className="sub-text mb-0">
          <span className="font-weight-bold">cpm :&nbsp;</span>
          {costPerMonthPrice} |{" "}
          <span className="font-weight-bold">cfc :&nbsp;</span>
          {costForCampaignPrice}
        </p>
      )}

      {/* Pricing for Mantaray */}
      {isMantaraySpecific && (
        <p className="sub-text mb-0">
          <span className="font-weight-bold">cpm :&nbsp;</span>
          {costPerMonthPrice} |{" "}
          <span className="font-weight-bold">cfc :&nbsp;</span>
          {costForCampaignPrice} |{" "}
          <span className="font-weight-bold">score :&nbsp;</span>
          {score}
        </p>
      )}
    </div>
  );
}

export function InfoCell({ mediaElementStyle = "", media, redirectUrl }) {
  return (
    <td className={mediaElementStyle}>
      <div className="d-flex align-items-center">
        <MediaImage media={media} />
        <div className="pl-2">
          <MediaLink media={media} redirectUrl={redirectUrl} />
          <MediaMetaData media={media} />
        </div>
      </div>
    </td>
  );
}

export function ActionButtonCell({
  id,
  selectedElement,
  selectElementFn,
  unSelectElementFn,
}) {
  //Dispatch
  const dispatch = useDispatch();

  const dispatchFunction = selectedElement
    ? unSelectElementFn
    : selectElementFn;
  const buttonLabel = selectedElement ? "Added" : "Add";

  return (
    <td className="align-middle">
      <button
        className={`btn ${
          selectedElement ? "btn-outline-success" : "btn-outline-primary"
        } add-button shadow-none`}
        onClick={() => dispatch(dispatchFunction(id))}
      >
        {selectedElement ? <i className="fa fa-check mx-1"></i> : ""}
        {buttonLabel}
      </button>
    </td>
  );
}

export function ReachCell({
  reachElementStyle = "",
  reachFrequency = {},
  cityPopulation,
  showPopulation,
}) {
  // original reach, tgReach
  const { reach, tgReach } = reachFrequency || {};
  const tgReachPercentWithBaseReach = calculatePercentage(
    Number(tgReach),
    Number(reach)
  );

  // as string
  const { reach: reachString, tgReach: tgReachString } =
    getReachValueAsString(reachFrequency);

  // const { tgReachPercent } = reachFrequency || {};
  // const reachPercentage = getReachPercentage(cityPopulation, reachFrequency);

  // Checking Org is Subscribed or Not and is Org Type is Seller
  // const isSubscribed = getIsSubscriptionActive();

  // showing reach percent with two decimals
  // const finalTgReachPercent = !isSubscribed
  //   ? `(*%)`
  //   : tgReachPercent
  //   ? `(${tgReachPercent?.toFixed(2)}%)`
  //   : "";

  return (
    <td className={reachElementStyle}>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-pink"></span>
        {formatText(tgReachString)}
        {/* <span className="float-right">{finalTgReachPercent}</span> */}
      </p>
      <p className="mb-2 mt-0 d-flex align-items-center">
        <span className="d-inline-block border border-dark mr-2 square bg-light-yellow"></span>
        {formatText(reachString)}
        {/* <span className="float-right">{reachPercentage}</span> */}
      </p>

      {parseInt(reach) > 0 && (
        <>
          <ProgressBar
            className="bg-light-pink"
            percentage={tgReachPercentWithBaseReach}
          />
          <ProgressBar className="bg-light-yellow" percentage={100} />
        </>
      )}
      {showPopulation && (
        <small className="sub-text mt-1">
          {getModifiedPopulation(cityPopulation)}
        </small>
      )}
    </td>
  );
}

export function FrequencyCell({
  frequencyElementClass = "",
  reachFrequency = {},
}) {
  const frequencyValue = getFrequencyValue(reachFrequency);
  return (
    <td className={frequencyElementClass}>
      <p className="my-0">{frequencyValue}</p>
    </td>
  );
}
