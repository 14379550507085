import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import {
  closeAddAndEditBuyerForm,
  createBuyer,
  updateBuyer,
} from "../../../actions/buyer/BuyerActions";

// Utils and Constants
import { ProohFormDataTargets } from "../../constants/GeneralConstants";

// Name And Description Section
function NameAndDescriptionSection({
  inputClassName,
  formData,
  handleOnChange,
}) {
  return (
    <>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Name</label>
        <div className="col-sm-10 px-0">
          <input
            className={inputClassName}
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Description</label>
        <div className="col-sm-10 px-0">
          <input
            className={inputClassName}
            placeholder="Description"
            name="description"
            value={formData.description}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </>
  );
}

function BuyerAddressSection({ inputClassName, formData, handleOnChange }) {
  return (
    <div className="form-group row">
      <label className="col-2 col-form-label">{"Address"}</label>
      <div className="col-sm-10 px-0">
        <input
          className={inputClassName}
          placeholder="Address Line 1"
          name="line1"
          value={formData.line1}
          onChange={handleOnChange}
        />

        <input
          className={`mt-3 ${inputClassName}`}
          placeholder="Address Line 2"
          name="line2"
          value={formData.line2}
          onChange={handleOnChange}
        />

        <div className="d-flex mt-3">
          <div className="col px-0 mr-2">
            <input
              className={inputClassName}
              placeholder="City"
              name="city"
              value={formData.city}
              onChange={handleOnChange}
            />
          </div>
          <div className="col px-0 mr-2">
            <input
              className={inputClassName}
              placeholder="State"
              name="state"
              value={formData.state}
              onChange={handleOnChange}
            />
          </div>
          <div className="col px-0">
            <input
              className={inputClassName}
              placeholder="Pincode"
              name="zipcode"
              value={formData.zipcode}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function BuyerContactSection({ inputClassName, formData, handleOnChange }) {
  return (
    <div className="form-group row">
      <label className="col-2 col-form-label">{"Contact"}</label>
      <div className="col-sm-4 px-0">
        <input
          className={inputClassName}
          placeholder="Phone No."
          name="contactNo"
          value={formData.contactNo}
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
}

/**
 * Add and Edit Buyer Form
 */
export default function AddAndEditBuyerForm() {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipcode: "",
    contactNo: "",
  });

  // data
  const processDataObject = useSelector(
    (state) => state.buyer.processDataObject
  );
  const { buyer = {} } = processDataObject || {};
  const { id, name, description, addresses = [] } = buyer || {};
  const firstAddress = addresses?.[0] || {};
  const { city, contactNo, line1, line2, state, zipcode } = firstAddress || {};

  useEffect(() => {
    setFormData({
      name: name || "",
      description: description || "",
      line1: line1 || "",
      line2: line2 || "",
      city: city || "",
      state: state || "",
      zipcode: zipcode || "",
      contactNo: contactNo || "",
    });
  }, [id]);

  // functions
  function handleOnChange(e) {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  function handleSubmit() {
    const isAllInputsFilled = Object.keys(formData).every((k) => {
      if (!formData[k]) {
        toast.error("Please fill all the fields");
        return false;
      }
      return formData[k];
    });

    if (isAllInputsFilled) {
      if (id) {
        dispatch(updateBuyer(id, formData));
      } else {
        dispatch(createBuyer(formData));
      }

      // close form
      dispatch(closeAddAndEditBuyerForm());
    }
  }

  // labels
  const modalHeader = id ? "Edit Buyer" : "Create Buyer";
  const confirmBtnLabel = id ? "Edit" : "Create";

  // Input class name
  const inputClassName = "form-control shadow-none";

  return (
    <div
      className="modal fade"
      id={ProohFormDataTargets.addAndEditBuyerForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">{modalHeader}</h4>
          </div>

          {/* modal body */}
          <div className="modal-body px-5 pt-4 mt-2">
            <NameAndDescriptionSection
              inputClassName={inputClassName}
              formData={formData}
              handleOnChange={handleOnChange}
            />
            <BuyerAddressSection
              inputClassName={inputClassName}
              formData={formData}
              handleOnChange={handleOnChange}
            />
            <BuyerContactSection
              inputClassName={inputClassName}
              formData={formData}
              handleOnChange={handleOnChange}
            />
          </div>

          {/* Form Footer */}
          <div className="modal-footer border-0 px-5">
            <button
              type="button"
              className="btn btn-outline-primary rounded-lg shadow-none"
              data-dismiss="modal"
              onClick={() => dispatch(closeAddAndEditBuyerForm())}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-primary rounded-lg ml-2 shadow-none"
              data-dismiss="modal"
              onClick={handleSubmit}
            >
              {confirmBtnLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
