import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getCampaignList } from "../../../actions/campaign/CampaignBasicInfoActions";

// Constants and Utils
import { CampaignState } from "../components/planningConstants";
import { formatText } from "../../../common-utils/string-utils/StringUtils";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import { CampaignStatus } from "../components/HeaderNav";
import { CampaignRedirect } from "../components/CampaignRedirect";
import NoTableDataMessage from "../../../components/table/NoTableDataMessage";
import { Loader, TableHeaderWithAction } from "./HomePageUtils";

const tableHeaders = [
  {
    title: {
      displayName: "Campaign Name",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Client Name",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Status",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "No. of Sites",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Last Updated",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Action",
      className: "col-1",
    },
  },
];

function CampaignRow({ campaign = {} }) {
  const { title, buyerName, planStatus, mediaCount, updatedOn, id } =
    campaign || {};

  // checking campaign status as review or draft
  const {
    DRAFT,
    REVIEW,
    CONFIRMED,
    SCHEDULED,
    LIVE,
    COMPLETED,
    CANCELLED,
    STOPPED,
  } = CampaignState || {};
  const isPlanStatusDraft = planStatus === DRAFT;
  const isPlanStatusReview = planStatus === REVIEW;
  const isPlanStatusConfirmed = planStatus === CONFIRMED;
  const isPlanStatusScheduled = planStatus === SCHEDULED;
  const isPlanStatusLive = planStatus === LIVE;
  const isPlanStatusCompleted = planStatus === COMPLETED;
  const isPlanStatusCancelled = planStatus === CANCELLED;
  const isPlanStatusStopped = planStatus === STOPPED;

  // only showing DRAFT & REVIEW & CONFIRMED & SCHEDULED & LIVE & COMPLETED & CANCELLED & STOPPED
  const isShowCampaign =
    isPlanStatusDraft ||
    isPlanStatusReview ||
    isPlanStatusConfirmed ||
    isPlanStatusScheduled ||
    isPlanStatusLive ||
    isPlanStatusCompleted ||
    isPlanStatusCancelled ||
    isPlanStatusStopped;

  // showing campaign
  if (isShowCampaign) {
    return (
      <tr>
        <td>{title}</td>
        <td>{formatText(buyerName)}</td>
        <td>
          <CampaignStatus state={planStatus} />
        </td>
        <td>{mediaCount}</td>
        <td>{new Date(updatedOn).toDateString()}</td>
        <td>
          <CampaignRedirect id={id} state={planStatus} />
        </td>
      </tr>
    );
  }

  return null;
}

function CampaignsTableBody({ campaigns = [], campaignsLoading = false }) {
  const isCampaignsPresent = campaigns.length > 0;

  if (campaignsLoading) {
    return <Loader />;
  }

  if (!isCampaignsPresent) {
    return <NoTableDataMessage message="No Campaigns Present" />;
  }

  return (
    <>
      {campaigns.map((campaign) => (
        <CampaignRow key={campaign.id} campaign={campaign} />
      ))}
    </>
  );
}

/**
 * Table Component
 */
export default function CampaignsTable() {
  const dispatch = useDispatch();

  // State
  const [searchText, setSearchText] = useState("");

  // Selector States
  const campaigns = useSelector((state) => state.c_campaignList.camList);

  // Campaign Loading
  const campaignsLoading = useSelector(
    (state) => state.c_campaignList.camListLoading
  );

  useEffect(() => {
    dispatch(getCampaignList());
  }, [dispatch]);

  // Functions
  function onClickSearch() {
    dispatch(getCampaignList(searchText));
  }

  return (
    <div className="card shadow mt-5">
      <div className="card-body">
        <TableHeaderWithAction
          onClickSearch={onClickSearch}
          onSearchTextChange={({ target }) => setSearchText(target.value)}
        />

        <div className="table-responsive mt-3">
          <table className="table border table-reports">
            <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
            <tbody>
              <CampaignsTableBody
                campaigns={campaigns}
                campaignsLoading={campaignsLoading}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
