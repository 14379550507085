import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getCampaignPerformanceById } from "../../actions/campaign-performance/CampaignPerformanceActions";
import { getCampaignPlanOverview } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getCampaignMedia } from "../../../actions/campaign-media/CampaignMediaActions";

// Utils and Constants
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import { defaultPagination } from "../../../constants/UrlConstants";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import { CampaignDetails } from "../components/HeaderNav";
import CampaignViewCards from "../components/CampaignViewCards";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import CityDetailsTable from "../components/CityDetailsTable";

/**
 * Page
 */
function CampaignCompleteOverviewPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Get campaignPlan
  const campaignPlanOverview = useSelector(
    (state) => state.campaignPlan.campaignPlanOverview
  );
  const { planByCity = {} } = campaignPlanOverview || {};

  // Selector
  const campaignPerformance =
    useSelector((state) => state.campaignPerformance.campaignPerformance) || {};

  // Tg name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // target group id
  const targetGroupId = Object.values(planByCity)[0]?.targetGroupId;

  const { pageNo, pageSize } = defaultPagination;
  useEffect(() => {
    dispatch(getCampaignPlanOverview(campaignId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getCampaignPerformanceById(campaignId));

    // campaign medias
    dispatch(getCampaignMedia(campaignId, pageNo, pageSize));
  }, [dispatch, campaignId]);

  // get tg info
  useGetTgInfo(targetGroupId);

  // Sidebar Settings
  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/complete`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/complete`,
  };

  // Page Title
  const pageTitle = <CampaignDetails campaign={campaign} />;

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      {/* Content-Section */}
      <div className="content-wrapper px-0">
        <PageHeader title={pageTitle} shadow={true} />

        {/* Page-Content */}
        <div className="page-content">
          <CampaignViewCards
            tgName={tgName}
            campaign={campaign}
            campaignPlanOverview={campaignPlanOverview}
            mediaCount={campaign.mediaCount}
          />

          {/* City Details Table */}
          <CityDetailsTable
            planByCity={planByCity}
            campaign={campaign}
            campaignPerformance={campaignPerformance}
          />
        </div>
      </div>
    </>
  );
}

export default CampaignCompleteOverviewPage;
