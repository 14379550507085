import { Region } from "../../constants/action-constants/RegionActionConstants";

export const getRegionDataByCity = (cityId) => ({
  type: Region.GET_REGION_DATA_BY_CITY,
  payload: { cityId },
});

export const clearRegionDataByCity = () => ({
  type: Region.CLEAR_REGION_DATA_BY_CITY,
});

export const getRegionNames = () => ({
  type: Region.GET_REGION_NAMES,
});

export const getRegionListByType = (type, pageNo, pageSize) => ({
  type: Region.GET_REGION_LIST_BY_TYPE,
  payload: { type, pageNo, pageSize },
});
