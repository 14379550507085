export const Cloud = {
  // get presigned url
  GET_PRESIGNED_URL: "GET_PRESIGNED_URL",
  GET_PRESIGNED_URL_SUCCESS: "GET_PRESIGNED_URL_SUCCESS",
  GET_PRESIGNED_URL_FAILURE: "GET_PRESIGNED_URL_FAILURE",

  // upload Video to s3
  UPLOAD_VIDEO_TO_S3: "UPLOAD_VIDEO_TO_S3",
  UPLOAD_VIDEO_TO_S3_SUCCESS: "UPLOAD_VIDEO_TO_S3_SUCCESS",
  UPLOAD_VIDEO_TO_S3_FAILURE: "UPLOAD_VIDEO_TO_S3_FAILURE",

  // start label detection
  START_LABEL_DETECTION: "START_LABEL_DETECTION",
  START_LABEL_DETECTION_SUCCESS: "START_LABEL_DETECTION_SUCCESS",
  START_LABEL_DETECTION_FAILURE: "START_LABEL_DETECTION_FAILURE",

  //get label detection results
  GET_LABEL_DETECTION_RESULTS: "GET_LABEL_DETECTION_RESULTS",
  GET_LABEL_DETECTION_RESULTS_SUCCESS: "GET_LABEL_DETECTION_RESULTS_SUCCESS",
  GET_LABEL_DETECTION_RESULTS_FAILURE: "GET_LABEL_DETECTION_RESULTS_FAILURE",
};
