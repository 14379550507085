import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import {
  changeCampaignPlanStatus,
  getCampaignPlanOverview,
} from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { getCampaignMedia } from "../../../actions/campaign-media/CampaignMediaActions";

// Utils and Constants
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import { defaultPagination } from "../../../constants/UrlConstants";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import Spinner from "../../../components/spinner/Spinner";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import { isBuyerUserSpecific } from "../../../components/hoc/MediaAgencySpecific";
import {
  AddCityInCampaign,
  CampaignDetails,
  CancelCampaign,
  ConfirmCampaign,
} from "../components/HeaderNav";
import CityDetailsTable from "../components/CityDetailsTable";
import { CampaignState } from "../components/planningConstants";
import { addCityAction } from "../components/CampaignRedirect";
import CampaignViewCards from "../components/CampaignViewCards";

// Page-Component
function ReviewOverviewHeader({ campaign, campaignPlan }) {
  const updateStateLoading = useSelector(
    (state) => state.c_campaignBasicInfo.updateStateLoading
  );
  const dispatch = useDispatch();
  const history = useHistory();

  if (!campaign) {
    return null;
  }

  const { infoByCity = {}, id: campaignId, planStatus } = campaign;
  const { summary } = campaignPlan;

  function confirmCampaign() {
    const redirectUrl = `/campaign/${campaignId}/confirm`;
    dispatch(
      changeCampaignPlanStatus(
        campaignId,
        CampaignState.CONFIRMED,
        history,
        redirectUrl
      )
    );
  }

  function handleAddCity() {
    addCityAction(
      campaignId,
      Object.keys(infoByCity),
      summary.targetGroupId,
      history
    );
  }

  const EnableCancelCampaign = isBuyerUserSpecific(CancelCampaign);
  const EnableAddCityInCampaign = isBuyerUserSpecific(AddCityInCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex mt-2">
      <div>
        {EnableCancelCampaign && (
          <EnableCancelCampaign campaignId={campaignId} />
        )}
        {EnableAddCityInCampaign && (
          <EnableAddCityInCampaign
            onClickAction={handleAddCity}
            disabled={true}
          />
        )}
        {CampaignState.REVIEW === planStatus && (
          <ConfirmCampaign confirmAction={confirmCampaign} />
        )}
      </div>
      {updateStateLoading && <Spinner className="mx-3" />}
    </div>
  );
  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page
 */
export default function CampaignReviewOverviewPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  // Get campaignPlan
  const campaignPlanOverview = useSelector(
    (state) => state.campaignPlan.campaignPlanOverview
  );
  const { planByCity = {} } = campaignPlanOverview || {};

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Tg name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // target group id
  const targetGroupId = Object.values(planByCity)[0]?.targetGroupId;

  const { pageNo, pageSize } = defaultPagination;
  useEffect(() => {
    dispatch(getCampaignPlanOverview(campaignId));
    dispatch(getBasicCampaignDetails(campaignId));

    // campaign medias
    dispatch(getCampaignMedia(campaignId, pageNo, pageSize));
  }, [dispatch, campaignId]);

  // get tg info
  useGetTgInfo(targetGroupId);

  // Sidebar Settings
  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/review`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/review`,
  };

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      <div className="content-wrapper">
        <ReviewOverviewHeader
          campaign={campaign}
          campaignPlan={campaignPlanOverview}
        />

        {/* Page-Content */}
        <div className="page-content">
          <CampaignViewCards
            tgName={tgName}
            campaign={campaign}
            campaignPlanOverview={campaignPlanOverview}
            mediaCount={campaign.mediaCount}
          />

          {/* City Details Table */}
          <CityDetailsTable planByCity={planByCity} campaign={campaign} />
        </div>
      </div>
    </>
  );
}
