import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import {
  addOrgMediaImages,
  downloadOrgMediaPdfReport,
  getOrgMediaInfo,
} from "../../actions/org/OrgMediaActions";

// Constants and Utils
import { selectFile } from "../../common-utils/file-utils/FileUtils";
import { ImageFileTypes } from "../../constants/GeneralConstants";

// Components
import Spinner from "../../components/spinner/Spinner";
import MediaInfo from "../../mavin/components/media-info/MediaInfo";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import MediaSitePerformance from "../../mavin/components/media-site-performance/MediaSitePerformance";
import LocationSummary from "../../mavin/components/location-summary/LocationSummary";
import HourlyTrafficDataSection from "../../mavin/components/hourly-traffic-data/HourlyTrafficDataSection";
import MediaImageWithMap from "../../mavin/components/media-image-with-map/MediaImageWithMap";
import MediaReportDownload from "../../mavin/components/media-report-download/MediaReportDownload";

/**
 * Main Page - Org Media Details Page
 */
function OrgMediaDetailPage() {
  const dispatch = useDispatch();
  const { mediaId, orgId } = useParams();

  // State
  const [radius, setRadius] = useState(1000);

  // Selector
  const media = useSelector((state) => state.orgMedia.orgMediaInfo);
  const orgMediaInfoLoading = useSelector(
    (state) => state.orgMedia.orgMediaInfoLoading
  );

  // Org Media Traffic Data
  const roadSegmentTrafficInfo = useSelector(
    (state) => state.orgMedia.roadSegmentTrafficInfo
  );

  const orgMediaFileItems = useSelector(
    (state) => state.orgMedia.orgMediaFileItems
  );
  const addOrgMediaImagesLoading = useSelector(
    (state) => state.orgMedia.addOrgMediaImagesLoading
  );

  const downloadingReportLoading = useSelector(
    (state) => state.orgMedia.downloadOrgMediaPdfLoading
  );

  // Dispatch
  useEffect(() => {
    dispatch(getOrgMediaInfo(mediaId, orgId));
  }, [dispatch, mediaId]);

  // function
  async function addOrgMediaImageFn() {
    const files = await selectFile(true, ImageFileTypes);
    dispatch(addOrgMediaImages(orgId, mediaId, files));
  }

  // Page Loading
  if (orgMediaInfoLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  if (Object.keys(media).length === 0) {
    return null;
  }

  const { latitude, longitude, title } = media || {};

  // functions
  function handleOrgMediaPdfDownload() {
    dispatch(downloadOrgMediaPdfReport(orgId, mediaId, radius, title));
  }

  const pageActions = (
    <MediaReportDownload
      loading={downloadingReportLoading}
      onClick={handleOrgMediaPdfDownload}
    />
  );

  return (
    <div className="content-wrapper media-page" id="cont-org-media-details">
      {/* Page Header */}
      <PageHeader title="Media Report" actions={pageActions} shadow={true} />

      <div className="page-content">
        {/* Media info  */}
        <MediaInfo media={media} titleClassName="h2" />
        <hr className="divider"></hr>

        {/* Site performance */}
        <MediaSitePerformance
          media={media}
          title="Site Performance"
          className="py-3"
        />
        <hr className="divider"></hr>

        {/* Media image and map view*/}
        <MediaImageWithMap
          media={media}
          fileItems={orgMediaFileItems}
          loading={orgMediaInfoLoading || addOrgMediaImagesLoading}
          onClick={addOrgMediaImageFn}
        />

        {/* Traffic Data */}
        <HourlyTrafficDataSection
          media={media}
          roadSegmentTrafficInfo={roadSegmentTrafficInfo}
        />

        {/* Org Media Location Summary */}
        {latitude && longitude && (
          <LocationSummary
            latitude={latitude}
            longitude={longitude}
            radius={radius}
            setRadius={setRadius}
          />
        )}
      </div>
    </div>
  );
}

export default OrgMediaDetailPage;
