import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// APIs
import { getCampaign, updateCampaignState } from "../../apis/CampaignAPI";
import { getCampaignList } from "../../apis/CampaignAPI";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { ActionTypes } from "../../constants/ActionConstants";
import { getErrorMessage } from "../../utils/util";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";
import {
  CampaignState,
  CampaignStateText,
} from "../../prooh/pages/components/planningConstants";

export function* getCampaignBasicInfo(action) {
  const { campaignId } = action.payload;
  try {
    const campaignDetails = yield getCampaign(campaignId);

    yield put({
      type: ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO_SUCCESS,
      payload: { campaignDetails },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* updateState(action) {
  const { campaignId, state, history } = action.payload;
  try {
    yield updateCampaignState(campaignId, state);
    yield put({
      type: ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE_SUCCESS,
    });

    // redirect to homePage
    history.push(constructRedirectPath(RedirectTo.homeUrl));

    // toast (cancel & stop)
    const isCancelCampaign = state === CampaignState.CANCELLED;
    const isStopCampaign = state === CampaignState.STOPPED;
    const stateText = CampaignStateText[state] || "";
    if (isCancelCampaign || isStopCampaign) {
      toast.success(`Successfully ${stateText} The Campaign`);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export function* getCampList(action) {
  const { searchText, pageNo, pageSize } = action.payload || {};
  try {
    const result = yield getCampaignList(searchText, pageNo, pageSize);

    yield put({
      type: ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST_SUCCESS,
      payload: { campaignList: result.items },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO,
      getCampaignBasicInfo
    ),

    takeLatest(ActionTypes.CampaignDetails.UPDATE_CAMPAIGN_STATE, updateState),
    takeLatest(ActionTypes.CampaignDetails.GET_CAMPAIGN_LIST, getCampList),
  ]);
}
