// Utils and Constants
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";
import {
  DefaultPagination,
  LocalStorageKeys,
  PageSize,
} from "../constants/GeneralConstants";
import { getItem } from "../utils/localstorage";

// Urls
import {
  createBuyerUrl,
  getBuyerDetailsUrl,
  getBuyersListUrl,
  getUsersByBuyerIdUrl,
  updateBuyerUrl,
} from "../urls/BuyerURL";

export async function getBuyersListFn(keyWord, pageNumber, pageSize) {
  const user = JSON.parse(getItem(LocalStorageKeys.USER));
  const { orgId } = user || {};

  // params
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || PageSize.pageSize10,
    _org: orgId,
  };

  if (keyWord) {
    params._kw = keyWord;
  }

  return sendGetRequest(getBuyersListUrl, params);
}

export async function getUsersByBuyerIdFn(buyerId) {
  const url = getUsersByBuyerIdUrl.replace(":buyerId", buyerId);
  return sendGetRequest(url);
}

export async function getBuyerDetailsFn(buyerId) {
  return sendGetRequest(getBuyerDetailsUrl.replace(":buyerId", buyerId));
}

export async function createBuyerFn(buyerBean) {
  // adding orgId to buyerBean
  const user = JSON.parse(getItem(LocalStorageKeys.USER));
  const { orgId } = user || {};
  buyerBean.orgId = orgId;

  return sendCreateOrUpdateRequest(createBuyerUrl, buyerBean);
}

export async function updateBuyerFn(buyerId, buyerBean) {
  // adding orgId to buyerBean
  const user = JSON.parse(getItem(LocalStorageKeys.USER));
  const { orgId } = user || {};
  buyerBean.orgId = orgId;

  return sendCreateOrUpdateRequest(
    updateBuyerUrl.replace(":buyerId", buyerId),
    buyerBean,
    true,
    "PUT"
  );
}
