import React, { Fragment, useEffect, useRef } from "react";
import { Marker, Polyline, Popup, Tooltip } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { openTabContentPreviewForm } from "../../actions/campaign-planning/TabContentPreviewFormActions";

// Constants And Utils
import { India } from "../../../constants/GeneralConstants";
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { StretchSelectionButton } from "./PlanningUtils";
import { ProohFormDataTargets } from "../../constants/GeneralConstants";
import { constructTgHeatmapKey } from "../../utils/campaignPlanningUtil";

// Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import MapIcon from "../../../components/map/map-icon/MapIcon";
import MediaMarkers from "../../../components/map/markers/MediaMarkers";
import TrafficFlowArrow from "../../../components/map/traffic-flow-arrow/TrafficFlowArrow";
import TargetGroupHeatmap from "../../../components/map/target-group-heatmap/TargetGroupHeatmap";
import PoiMarkers from "../../../components/map/markers/PoiMarkers";
import MapZoomIndicator from "../../../components/map/map-zoom-indicator/MapZoomIndicator";
import { TouchPointsMarkers } from "../components/map-view/MapViewUtil";

// Section Constants
export const selected = { color: "green", weight: 5 };
export const unSelected = { color: "blue", weight: 5 };

// Section Component
function StretchTooltipMessages({
  stretchName,
  tgSpecificImpressions,
  estImpressions,
}) {
  // Selected TgId
  const tgInfo = useSelector((state) => state.orgTargetGroup.tgInfo);
  const { targetGroup } = tgInfo || {};
  const { id: selectedTgId } = targetGroup || {};

  const formattedEstImp = formatText(toLocaleString(estImpressions));
  const formattedTgImp = formatText(toLocaleString(tgSpecificImpressions));

  // Showing Tg "TG Imp" and "Generic Imp" impressions (if present)
  return (
    <div>
      <b className="d-block mb-1">{stretchName}</b>
      {selectedTgId && (
        <span>
          {"Imp TG: "}
          <b>{formattedTgImp}</b>
        </span>
      )}
      <span className="d-block">
        {"Imp Gen: "}
        <b>{formattedEstImp}</b>
      </span>
    </div>
  );
}

// Page Components
function StretchLine({ eachStretch, duration }) {
  const polylineRef = useRef();

  // Hovered Stretch Id
  const hoveredStretchId = useSelector(
    (state) => state.campaignPlanning.hoveredStretchId
  );

  const { otsAvg, otsLitAvg, targetOts, targetOtsLit, trace, id, name } =
    eachStretch || {};

  // Without selecting TG
  const estImpressions = toStringWithRoundUp(duration * (otsLitAvg ?? otsAvg));

  // With Selecting TG
  const tgSpecificImpressions = toStringWithRoundUp(
    duration * (targetOtsLit ?? targetOts)
  );

  // Selected Road-Stretch
  const stretchSelected = useSelector(
    (state) => state.planningRoadStretches.selectedStretches[id]
  );
  const pathOptions = stretchSelected ? selected : unSelected;

  // conditionally showing popup when stretch is hovered
  useEffect(() => {
    if (hoveredStretchId === id) {
      polylineRef.current.openPopup();
      return;
    }
    polylineRef.current.closePopup();
  }, [hoveredStretchId]);

  return (
    <Polyline ref={polylineRef} pathOptions={pathOptions} positions={trace}>
      {/* permanent tooltip : remove at last if not required */}
      {/* <Tooltip permanent={true} direction="top" interactive={true}>
        <StretchTooltipMessages
          stretchName={name}
          tgSpecificImpressions={tgSpecificImpressions}
          estImpressions={estImpressions}
        />
        <div className="mt-1">
          <StretchSelectionButton stretchInfo={eachStretch} />
        </div>
      </Tooltip> */}

      <Popup>
        <StretchTooltipMessages
          stretchName={name}
          tgSpecificImpressions={tgSpecificImpressions}
          estImpressions={estImpressions}
        />
        <div className="mt-2 d-flex justify-content-center">
          <StretchSelectionButton stretchInfo={eachStretch} />
        </div>
      </Popup>
    </Polyline>
  );
}

// for road-segment
function SegmentLine({ eachSegment }) {
  const { otsLitAvg, otsAvg } = eachSegment;
  const estImpressions = otsLitAvg ? otsLitAvg : otsAvg;
  const segmentId = eachSegment.id;
  const segmentPoints = eachSegment.trace || [];
  const segmentSelected = useSelector(
    (state) => state.planningRoadSegments.selectedSegments[segmentId]
  );

  const color = segmentSelected ? selected : unSelected;

  const segmentMediasInfo =
    useSelector(
      (state) => state.planningRoadSegments.roadSegmentMediaInfo[segmentId]
    ) || [];

  const marker = {
    id: eachSegment.id,
    markerText: [
      `Impressions: ${toLocaleString(estImpressions)}`,
      `Title: ${eachSegment.name}`,
    ],
  };

  return (
    <>
      <Polyline pathOptions={color} positions={segmentPoints}>
        <Popup>
          {marker.markerText.map((string, i) => (
            <b className="d-block" key={i}>
              {string}
            </b>
          ))}
        </Popup>
      </Polyline>
      {segmentMediasInfo.map((media) => (
        <MediaMarkers key={media.mediaId} media={media} />
      ))}
    </>
  );
}

// for added-poi-markers
function PoiMarker({ eachPoi, i }) {
  const exactLatLong = Object.values(eachPoi.trace);
  const poiSelected = useSelector(
    (state) => state.planningPoi.selectedPoi[exactLatLong]
  );
  const marker = {
    markerText: [
      `Latitude: ${exactLatLong[0]}`,
      `Longitude: ${exactLatLong[1]}`,
    ],
  };
  const selectedType = new MapIcon({ type: "selected-poi" });
  const unSelectedType = new MapIcon({ type: "un-selected-poi" });
  const icon = poiSelected ? selectedType : unSelectedType;

  return (
    <Marker key={i} position={exactLatLong} icon={icon}>
      <Popup>
        {marker.markerText.map((string, i) => (
          <b className="d-block" key={i}>
            {string}
          </b>
        ))}
      </Popup>
    </Marker>
  );
}

function SelectedPoiMarker({ poiInfo }) {
  const { center, name, type } = poiInfo;
  const coordinates = [center.latitude, center.longitude];
  // map-point-marker
  const icon = new MapIcon({ type });
  return (
    <Marker position={coordinates} icon={icon}>
      <Popup>
        <b className="d-block">{name}</b>
      </Popup>
    </Marker>
  );
}

function SelectedPreviewButton() {
  const dispatch = useDispatch();

  function onPreviewButtonClick() {
    dispatch(openTabContentPreviewForm());
  }

  return (
    <button
      className="btn shadow-none bg-white text-primary p-2 rounded-lg preview-button"
      data-toggle="modal"
      data-target={`#${ProohFormDataTargets.tabContentPreviewForm}`}
      onClick={onPreviewButtonClick}
      title="Click to see Selected Information"
    >
      <i className="fas fa-info-circle fa-2x"></i>
    </button>
  );
}

/**
 * Planning MapView
 */
function PlanningMapView({
  cityId,
  center,
  roadStretchData,
  segmentData,
  poiData,
}) {
  // selected poi to show in map view
  const activePOI = useSelector((state) => state.c_poiNames.activePOI);

  // all poiTypeLayer points combined array ==> TG HeatMap Data
  const allPoiPointsArr = useSelector((state) => state.geoData.allPoiPointsArr);

  // const selectedTgId =
  //   useSelector((state) => state.orgTargetGroup.tgInfo?.targetGroup?.id) || "";

  // selected tg attr map
  const selectedTgAttributesMap = useSelector(
    (state) => state.campaignPlanning.selectedTgAttributesMap
  );
  const targetGroupHeatmapKey = constructTgHeatmapKey(selectedTgAttributesMap);

  // selectedTouchPointIds
  const selectedTouchPointIds = useSelector(
    (state) => state.campaignPlanning.selectedTouchPointIds
  );

  // Campaign Plan Duration
  const duration = useSelector(
    (state) => state.campaignPlanning.durationInDays
  );

  // Show India Map
  if (!center) {
    return (
      <div className="toohl-map-container">
        <LLMap center={India.mapCenter} zoom={India.mapZoomLevel5} />
      </div>
    );
  }

  return (
    <div className="toohl-map-container">
      <LLMap center={[center.latitude, center.longitude]} zoom={11}>
        {/* Stretches */}
        {roadStretchData.map((eachStretch, i) => (
          <Fragment key={i}>
            <StretchLine
              key={eachStretch.id}
              eachStretch={eachStretch}
              duration={duration}
            />
            <TrafficFlowArrow trace={eachStretch.trace} />
          </Fragment>
        ))}

        {/* Segments */}
        {Object.values(segmentData).map((eachSegment) => (
          <SegmentLine key={eachSegment.id} eachSegment={eachSegment} />
        ))}

        {/* Planning Exact Lat-Lng Modal Poi */}
        {Object.values(poiData).map((eachPoi, i) => (
          <PoiMarker key={i} eachPoi={eachPoi} />
        ))}

        {/* Active Poi */}
        {activePOI.map((poiInfo) => (
          <SelectedPoiMarker key={poiInfo.id} poiInfo={poiInfo} />
        ))}

        {/* Heatmap */}
        {allPoiPointsArr.length > 0 && (
          <TargetGroupHeatmap
            hmkey={targetGroupHeatmapKey}
            allPoiPointsArr={allPoiPointsArr}
          />
        )}

        {/* Poi Tab Markers */}
        <PoiMarkers />

        {/* Touch Points Markers */}
        <TouchPointsMarkers
          cityId={cityId}
          touchPointIds={selectedTouchPointIds}
        />

        {/* Selected preview btn */}
        <SelectedPreviewButton />

        {/* Map Zoom Indicator */}
        <MapZoomIndicator />
      </LLMap>
    </div>
  );
}

export default PlanningMapView;
