import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Actions
import {
  clearRoadStretchData,
  getRoadStretchesByIds,
} from "../../../actions/org/OrgRoadStretchActions";
import {
  getTargetGroupAttributes,
  getTgInfo,
  resetTgGroup,
} from "../../../actions/org/OrgTargetGroupActions";
import { getSellerMediaByStretchIds } from "../../../actions/seller/SellerMediaActions";
import {
  getPoiHierarchy,
  resetPoiInfo,
} from "../../../actions/map-view/poi/PoiActions";
import {
  getTgHeatMapData,
  removeTgHeatMapData,
} from "../../../actions/geo-data/GeoDataActions";
import { resetMetaData } from "../../../actions/map-view/MetaDataActions";
import { resetMediaSites } from "../../../actions/map-view/media-sites/MediaSitesActions";
import {
  resetMediaInfluence,
  resetMediaMarkers,
} from "../../../actions/map-view/media-sites/LegendAndInfluenceActions";
import {
  resetBrandInfo,
  resetPoiInfluenceCircleRadius,
} from "../../../actions/map-view/poi/PoiSelectionActions";
import { resetSecInfo } from "../../../actions/map-view/SecActions";
import { resetDataLayerInfo } from "../../../actions/map-view/DataLayerSelectionActions";
import { resetBoundariesInfo } from "../../../actions/map-view/BoundariesActions";
import {
  clearRegionDataByCity,
  getRegionListByType,
} from "../../../actions/regions/RegionActions";
import { resetExploreSites } from "../../../actions/map-view/media-sites/ExploreSitesActions";
import { resetSelectedRoadStretches } from "../../../actions/map-view/RoadStretchesSelectionActions";
import { resetExploreSiteSelection } from "../../../actions/map-view/media-sites/ExploreSitesSelectionActions";
import { getRouteTypes } from "../../../actions/org/OrgRouteTypesActions";
import {
  getCustomDataLayers,
  getDataLayerDetails,
} from "../../../actions/org/OrgDataLayerActions";
import { unselectRoadStretch } from "../../../prooh/actions/campaign-planning/CampaignPlanningRoadStretchesActions";

// Constants
import { defaultPagination } from "../../../constants/UrlConstants";

export function useMemoCompare(next, compare) {
  const previousRef = useRef();
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

export function useGetPoiHierarchy(cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityId) {
      dispatch(getPoiHierarchy(cityId));
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useGetTgAttributes(cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityId) {
      dispatch(getTargetGroupAttributes());
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useGetRegionListByType(cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityId) {
      dispatch(getRegionListByType());
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useGetRouteTypes(cityId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (cityId) {
      dispatch(getRouteTypes());
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useGetTouchPoints(cityId) {
  const dispatch = useDispatch();

  const pageNumber = defaultPagination.pageNumber,
    pageSize = defaultPagination.pageSizeMax;

  useEffect(() => {
    if (cityId) {
      dispatch(getCustomDataLayers(false, "", pageNumber, pageSize));
      return;
    }
  }, [dispatch, cityId]);

  return null;
}

export function useUpdateRoadStretchesSelection(cityId) {
  const dispatch = useDispatch();

  // road stretches map
  const roadStretchesDataMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // selected stretches map
  const stretchSelectedMap = useSelector(
    (state) => state.planningRoadStretches.selectedStretches
  );

  useEffect(() => {
    if (cityId) {
      const filteredStretchIds = Object.keys(roadStretchesDataMap);
      const selectedStretchesIds = Object.keys(stretchSelectedMap);

      // stretch ids which we have to unselect from previously selected
      // doing this because new filtered road stretches may not have previously selected road stretches
      // so we are updating the selection of stretches
      const stretchIdsToUnSelect = [];
      selectedStretchesIds.forEach((stretchId) => {
        const isSelectedIsFilteredStretch =
          filteredStretchIds.includes(stretchId);
        if (!isSelectedIsFilteredStretch) {
          stretchIdsToUnSelect.push(stretchId);
        }
      });

      // action to unselect stretches
      stretchIdsToUnSelect.forEach((stretchId) => {
        dispatch(unselectRoadStretch(stretchSelectedMap[stretchId]));
      });
      return;
    }
  }, [dispatch, cityId, JSON.stringify(roadStretchesDataMap)]);

  return null;
}

export function useGetTgInfo(tgId) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (tgId) {
      dispatch(getTgInfo(tgId));
      return;
    }
  }, [dispatch, tgId]);

  return null;
}

export function useGetSellerMediaByStretchIds(stretchIds) {
  const dispatch = useDispatch();
  const stretchIdsLength = stretchIds.length;

  useEffect(() => {
    if (stretchIdsLength > 0) {
      dispatch(getSellerMediaByStretchIds(stretchIds));
      return;
    }
  }, [dispatch, stretchIdsLength]);

  return null;
}

export function useGetStretchByIds(stretchIds) {
  const dispatch = useDispatch();
  const stretchIdsLength = stretchIds.length;

  useEffect(() => {
    if (stretchIdsLength > 0) {
      dispatch(getRoadStretchesByIds(stretchIds));
      return;
    }
  }, [dispatch, stretchIdsLength]);

  return null;
}

export function useGetTgHeatMap(resPoiLayers, bbox) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (resPoiLayers.length > 0 && bbox) {
      dispatch(getTgHeatMapData(resPoiLayers, bbox));
      return;
    }
  }, [dispatch, JSON.stringify(resPoiLayers), bbox]);

  return null;
}

export function useGetTouchPointsDetails(dataLayerIds) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (dataLayerIds.length > 0) {
      dataLayerIds.forEach((id) => dispatch(getDataLayerDetails(id)));
      return;
    }
  }, [dispatch, JSON.stringify(dataLayerIds)]);

  return null;
}

export function useGetQueryParam(...keys) {
  const { search } = useLocation();

  if (keys.length < 1) {
    return {};
  }

  return keys.reduce((acc, queryKey) => {
    const queryValue = new URLSearchParams(search).get(queryKey);
    acc[queryKey] = queryValue;
    return acc;
  }, {});
}

/**
 * Checks the url pathname  which includes given key
 * @param {*} keys
 * @returns true or false
 */
export function useUrlKeyCheck(...keys) {
  const { pathname } = useLocation();

  const isPresent = keys.some((key) => pathname.includes(key));

  return isPresent;
}

/**
 * This function is used to reset all the states of the map view page
 * ...Media (Inventory/CampaignMedia)
 * ...TargetGroup
 * ...Boundaries
 * ...DataLayers
 * ...RoadStretches
 * ...Pois
 */
export function useResetMapState() {
  const dispatch = useDispatch();
  useEffect(() => {
    // Clear Region Data of the city..
    dispatch(clearRegionDataByCity());

    // Reset Media MetaData Info
    dispatch(resetMetaData());

    // Reset Media Details Info
    dispatch(resetMediaSites());

    // Reset Media Legend Actions (Markers and Influence Circle)
    dispatch(resetMediaMarkers());
    dispatch(resetMediaInfluence());

    // Reset Inventory Media, selection
    dispatch(resetExploreSites());
    dispatch(resetExploreSiteSelection());

    // Reset Poi Brand Info, poi Info, poi influence details
    dispatch(resetBrandInfo());
    dispatch(resetPoiInfo());
    dispatch(resetPoiInfluenceCircleRadius());

    // Reset Boundaries
    dispatch(resetBoundariesInfo());

    // Reset Target Group Heat Map Data
    dispatch(removeTgHeatMapData());

    // Reset Target Groups
    dispatch(resetTgGroup());

    // Reset DataLayers
    dispatch(resetDataLayerInfo());

    // Reset Road Stretches
    dispatch(clearRoadStretchData());

    // Reset Sec
    dispatch(resetSecInfo());

    // Reset RoadStretches selection and Road Stretch Data
    dispatch(resetSelectedRoadStretches());
    dispatch(clearRoadStretchData());
  }, [dispatch]);
  return null;
}
