export const ZonesAndSubZones = {
  GET_ZONES: "GET_ZONES",
  GET_ZONES_SUCCESS: "GET_ZONES_SUCCESS",
  GET_ZONES_FAILURE: "GET_ZONES_FAILURE",

  SELECT_ZONE: "SELECT_ZONE",
  UN_SELECT_ZONE: "UN_SELECT_ZONE",

  GET_FREQUENTLY_OPTED_LOCATIONS: "GET_FREQUENTLY_OPTED_LOCATIONS",
  GET_FREQUENTLY_OPTED_LOCATIONS_SUCCESS:
    "GET_FREQUENTLY_OPTED_LOCATIONS_SUCCESS",
  GET_FREQUENTLY_OPTED_LOCATIONS_FAILURE:
    "GET_FREQUENTLY_OPTED_LOCATIONS_FAILURE",

  CLEAR_SELECTED_REGION_AND_ZONES_MAP: "CLEAR_SELECTED_REGION_AND_ZONES_MAP",
};
