import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  selectTouchPoint,
  unSelectTouchPoint,
} from "../../actions/campaign-planning/CampaignPlanningActions";
import { getDataLayerDetails } from "../../../actions/org/OrgDataLayerActions";

// Constants
import { HeaderWithActionButton } from "./PlanningUtils";

// Components
import CheckBox from "../../../components/check-box/CheckBox";
import Spinner from "../../../components/spinner/Spinner";

// Page Components
function TouchPointsCheckboxList({ dataLayers = [] }) {
  const dispatch = useDispatch();

  // State
  const [finalDataLayers, setFinalDataLayers] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  // selectedTouchPointIds
  const selectedTouchPointIds = useSelector(
    (state) => state.campaignPlanning.selectedTouchPointIds
  );

  const dataLayerDetailsLoadingMap = useSelector(
    (state) => state.orgDataLayers.dataLayerDetailsLoadingMap
  );

  // whenever search happen, it will trigger and filter layers
  useEffect(() => {
    const filteredLayers = dataLayers.filter((layer) =>
      layer.name.toLowerCase().includes(searchedText)
    );
    setFinalDataLayers(filteredLayers);
  }, [searchedText]);

  function onCheckboxChange(id, isChecked) {
    if (!isChecked) {
      // select
      dispatch(selectTouchPoint(id));

      // get data layer details for points
      dispatch(getDataLayerDetails(id));
    } else {
      dispatch(unSelectTouchPoint(id));
    }
  }

  return (
    <>
      {/* Search input */}
      <input
        type="search"
        className="form-control shadow-none col"
        placeholder={"Search Touch Points"}
        value={searchedText}
        onChange={({ target }) => setSearchedText(target.value)}
      />
      <hr className="my-3" />

      <div className="px-3">
        {finalDataLayers.map((layer) => {
          const { id, name } = layer;
          const isChecked = selectedTouchPointIds.includes(id) || false;
          const isGetDataLoading = dataLayerDetailsLoadingMap[id] || false;
          return (
            <div key={id} className="d-flex justify-content-between mt-2">
              <p className="mb-0" title={name}>
                {name}
              </p>
              <div className="d-flex align-items-center">
                {isGetDataLoading && (
                  <Spinner className="spinner-border-sm mr-2" />
                )}

                <CheckBox
                  boxStyle="mb-0 pl-1"
                  inputBoxStyle="ml-0"
                  checkBoxKey={id}
                  showLabel={false}
                  checked={isChecked}
                  onChange={() => onCheckboxChange(id, isChecked)}
                  disabled={isGetDataLoading}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

/**
 * Touch Points Tab
 */
export default function TouchPointsTab() {
  // Touch Points
  const dataLayers = useSelector((state) => state.orgDataLayers.dataLayers);

  return (
    <>
      <HeaderWithActionButton title="Touch Point" showButton={false} />
      <TouchPointsCheckboxList dataLayers={dataLayers} />
    </>
  );
}
