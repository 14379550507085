import { UserAndRoles } from "../../constants/action-constants/org/OrgUserAndRolesActionConstants";

const initialState = {
  loading: false,
  orgUserList: [],
  orgUserPagination: {},
  error: "",

  // user by id
  orgUserInfo: {},

  // user status loading
  userStatusLoading: false,

  // update user loading
  updateOrgUserLoading: false,

  // get org user stats
  orgUserStats: {},
  orgUserStatsLoading: false,
  orgUserStatsError: "",

  // update org user price settings
  updateUserPriceSettingsLoading: false,
  updateUserPriceSettingsError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get all org users
    case UserAndRoles.GET_ORG_ALL_USERS:
      return {
        ...state,
        loading: true,
      };
    case UserAndRoles.GET_ORG_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orgUserList: action.payload.orgUsers,
        orgUserPagination: action.payload.pagination,
      };
    case UserAndRoles.GET_ORG_ALL_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // get org user by id
    case UserAndRoles.GET_ORG_USER_BY_ID: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserAndRoles.GET_ORG_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        orgUserInfo: action.payload.orgUserInfo,
      };
    }
    case UserAndRoles.GET_ORG_USER_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    // update org user
    case UserAndRoles.UPDATE_ORG_USER: {
      return {
        ...state,
        updateOrgUserLoading: true,
      };
    }
    case UserAndRoles.UPDATE_ORG_USER_SUCCESS: {
      return {
        ...state,
        updateOrgUserLoading: false,
      };
    }
    case UserAndRoles.UPDATE_ORG_USER_FAILURE: {
      return {
        ...state,
        updateOrgUserLoading: false,
        error: action.payload,
      };
    }

    // disable user
    case UserAndRoles.DISABLE_ORG_USER: {
      return {
        ...state,
        userStatusLoading: true,
      };
    }
    case UserAndRoles.DISABLE_ORG_USER_SUCCESS: {
      return {
        ...state,
        userStatusLoading: false,
      };
    }
    case UserAndRoles.DISABLE_ORG_USER_FAILURE: {
      return {
        ...state,
        userStatusLoading: false,
        error: action.payload,
      };
    }

    // enable user
    case UserAndRoles.ENABLE_ORG_USER: {
      return {
        ...state,
        userStatusLoading: true,
      };
    }
    case UserAndRoles.ENABLE_ORG_USER_SUCCESS: {
      return {
        ...state,
        userStatusLoading: false,
      };
    }
    case UserAndRoles.ENABLE_ORG_USER_FAILURE: {
      return {
        ...state,
        userStatusLoading: false,
        error: action.payload,
      };
    }

    // create user
    case UserAndRoles.CREATE_ORG_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserAndRoles.CREATE_ORG_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UserAndRoles.CREATE_ORG_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    // clear org user state
    case UserAndRoles.CLEAR_ORG_USER: {
      return {
        ...state,
        orgUserInfo: {},
      };
    }

    // get org user stats
    case UserAndRoles.GET_ORG_USER_STATS: {
      return {
        ...state,
        orgUserStatsLoading: true,
      };
    }
    case UserAndRoles.GET_ORG_USER_STATS_SUCCESS: {
      return {
        ...state,
        orgUserStatsLoading: false,
        orgUserStats: action.payload.orgUserStats,
      };
    }
    case UserAndRoles.GET_ORG_USER_STATS_FAILURE: {
      return {
        ...state,
        orgUserStatsLoading: false,
        orgUserStatsError: action.payload,
      };
    }

    // update org user price settings
    case UserAndRoles.UPDATE_ORG_USER_PRICE_SETTINGS: {
      return {
        ...state,
        updateUserPriceSettingsLoading: true,
      };
    }
    case UserAndRoles.UPDATE_ORG_USER_PRICE_SETTINGS_SUCCESS: {
      return {
        ...state,
        updateUserPriceSettingsLoading: false,
      };
    }
    case UserAndRoles.UPDATE_ORG_USER_PRICE_SETTINGS_FAILURE: {
      return {
        ...state,
        updateUserPriceSettingsLoading: false,
        updateUserPriceSettingsError: action.payload,
      };
    }

    default:
      return state;
  }
};
