/* eslint-disable no-case-declarations */
import { DataLayer } from "../../constants/action-constants/DataLayerActionConstants";

const initialState = {
  // get Data-Layers listing
  // -----------------------------------------------------------------------
  dataLayersLoading: false,
  dataLayers: [],
  dataLayerPagination: {},
  isDataLayerProcessing: {},

  // get Single Data-Layer Details
  // ---------------------------------------------------------------------------
  viewDataLayerLoading: false,
  selectedDataLayerId: null,
  dataLayerDetailsMap: {},
  dataLayerDetailsLoadingMap: {},
  viewDataLayerError: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get Data-Layers listing
    // -----------------------------------------------------------------------
    case DataLayer.GET_CUSTOM_DATA_LAYERS:
      return {
        ...state,
        dataLayersLoading: true,
      };
    case DataLayer.GET_CUSTOM_DATA_LAYERS_SUCCESS: {
      const { dataLayers, pagination } = action.payload;
      return {
        ...state,
        dataLayersLoading: false,
        dataLayers,
        dataLayerPagination: pagination,
      };
    }
    case DataLayer.GET_CUSTOM_DATA_LAYERS_FAILURE:
      return {
        ...state,
        dataLayersLoading: false,
        error: action.payload,
      };
    case DataLayer.CHANGE_DATA_LAYER_STATUS: {
      const { id } = action.payload;
      return {
        ...state,
        isDataLayerProcessing: {
          ...state.isDataLayerProcessing,
          [id]: true,
        },
      };
    }
    case DataLayer.CHANGE_DATA_LAYER_STATUS_DONE: {
      const { id, isArchived } = action.payload;
      // set loading status of the row to be false
      const dataLayers = state.dataLayers.map((dataLayer) => {
        if (id !== dataLayer.id) {
          return dataLayer;
        }
        return {
          ...dataLayer,
          isArchived: isArchived,
          updatedOn: Date.now(),
        };
      });
      return {
        ...state,
        dataLayers,
        isDataLayerProcessing: {
          ...state.isDataLayerProcessing,
          [id]: false,
        },
      };
    }

    // get Single Data-Layer Details
    // ---------------------------------------------------------------------------
    case DataLayer.GET_DATA_LAYER_DETAILS:
      return {
        ...state,
        viewDataLayerLoading: true,
        dataLayerDetailsLoadingMap: {
          ...state.dataLayerDetailsLoadingMap,
          [action.payload.id]: true,
        },
      };

    case DataLayer.GET_DATA_LAYER_DETAILS_SUCCESS: {
      const { dataLayer } = action.payload;
      const { id } = dataLayer || {};

      const updatedDetailsMap = {
        ...state.dataLayerDetailsMap,
        [id]: dataLayer,
      };

      const clonedLoadingMap = { ...state.dataLayerDetailsLoadingMap };
      delete clonedLoadingMap[id];

      return {
        ...state,
        viewDataLayerLoading: false,
        selectedDataLayerId: id,
        dataLayerDetailsMap: updatedDetailsMap,
        dataLayerDetailsLoadingMap: clonedLoadingMap,
      };
    }
    case DataLayer.GET_DATA_LAYER_DETAILS_FAILURE: {
      const { error, id } = action.payload;

      const clonedLoadingMap = { ...state.dataLayerDetailsLoadingMap };
      delete clonedLoadingMap[id];

      return {
        ...state,
        viewDataLayerLoading: false,
        viewDataLayerError: error,
        dataLayerDetailsLoadingMap: clonedLoadingMap,
      };
    }

    default:
      return state;
  }
};
