// TODO:: replace below once we have the correct mapping of poiCount wrt poiType
// generate Poi Count By PoiType
function generatePoiCountByPoiType(startNo = 1, endNo = 235) {
  const poiCountByPoiType = {};

  for (let i = startNo; i <= endNo; i++) {
    poiCountByPoiType[i] = i;
  }
  return poiCountByPoiType;
}

/**
 * PoiCountByPoiType = { poiTypeId1:poiCounts, poiTypeId2:poiCounts  }
 */
export const PoiCountByPoiType = generatePoiCountByPoiType();
