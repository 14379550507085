import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  selectRoadStretch,
  unselectRoadStretch,
} from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";
import { openPoiForm } from "../../actions/campaign-planning/PoiFormActions";

// Constants and Utils
import { ProohFormDataTargets } from "../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";
import CheckBox from "../../../components/check-box/CheckBox";

// Util Components
export function LoadingRoadStretches() {
  return (
    <div className="d-flex justify-content-center p-2 bg-alt border">
      <Spinner />
      <span className="ml-2 py-1">
        Please wait, Road Stretches are Loading...
      </span>
    </div>
  );
}

export function DataLoading({
  className = "p-2 bg-alt border",
  spinnerClassName = "",
  dataType = "",
}) {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${className}`}
    >
      <Spinner className={spinnerClassName} />
      <span className="pl-2">{dataType} Loading ...</span>
    </div>
  );
}

export function LoadingPlanningMapView() {
  return (
    <div className="d-flex justify-content-center p-2">
      <Spinner />
    </div>
  );
}

export function StretchSelectionButton({
  stretchInfo = {},
  elementStyle = "",
}) {
  const dispatch = useDispatch();

  const { id: stretchId } = stretchInfo || {};
  const stretchSelected = useSelector(
    (state) => state.planningRoadStretches.selectedStretches[stretchId]
  );

  function unSelectStretch() {
    dispatch(unselectRoadStretch(stretchSelected));
  }

  function selectStretch() {
    dispatch(selectRoadStretch(stretchInfo));
  }

  if (stretchSelected) {
    return (
      <td className={`align-middle text-right ${elementStyle}`}>
        <button
          type="button"
          className="btn added-button shadow-none btn-outline-success"
          onClick={unSelectStretch}
        >
          <i className="fa fa-check"></i> <b>Selected</b>
        </button>
      </td>
    );
  }
  return (
    <td className={`align-middle text-right ${elementStyle}`}>
      <button
        type="button"
        className="btn add-button shadow-none btn-outline-primary"
        onClick={selectStretch}
      >
        <b>Select</b>
      </button>
    </td>
  );
}

export function AddCaptiveAreaSection() {
  const dispatch = useDispatch();

  // for add-poi/captive-area popup modal
  function openPoiFormFunction() {
    dispatch(openPoiForm());
  }
  return (
    <button
      type="button"
      className="btn btn-link nav-link shadow-none p-0"
      data-toggle="modal"
      data-target={`#${ProohFormDataTargets.addCaptiveAreaForm}`}
      onClick={openPoiFormFunction}
    >
      + Add POI/Captive Area
    </button>
  );
}

// Planning Header With Action Button
export function HeaderWithActionButton({
  title,
  buttonText,
  onButtonClick = () => {},
  disabled = false,
  buttonLoading = false,
  showButton = true,
}) {
  return (
    <>
      <div className="d-flex justify-content-between p-0">
        <h2 className="mb-0">{title}</h2>
        {showButton && (
          <button
            type="button"
            className="btn btn-primary px-3 rounded-lg shadow-none"
            onClick={onButtonClick}
            disabled={disabled || buttonLoading}
          >
            <span>{buttonText}</span>
            {buttonLoading && (
              <Spinner className="spinner-border-sm ml-2 text-light" />
            )}
          </button>
        )}
      </div>
      <hr className="my-3" />
    </>
  );
}

// Arterial Route And Media Type Checkbox List
export function ArterialRouteAndMediaTypeCheckboxList({
  data = [],
  selectedMap = {},
  onCheckboxChange = () => {},
}) {
  if (data.length === 0) {
    return null;
  }

  return (
    <div className="px-3">
      {data.map((eachData, index) => (
        <div className="d-flex justify-content-between mt-2" key={index}>
          <p className="mb-0" title={eachData}>
            {eachData}
          </p>
          <CheckBox
            boxStyle="mb-0"
            checkBoxKey={eachData}
            showLabel={false}
            checked={selectedMap[eachData] || false}
            onChange={() => onCheckboxChange(eachData)}
          />
        </div>
      ))}
    </div>
  );
}
