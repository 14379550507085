import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getCampaignMedia } from "../../actions/media-selection/MediaSelectionActions";
// import { getRoadStretchesByIds } from "../../../actions/org/OrgRoadStretchActions";
// import { getSellerMediaByStretchIds } from "../../../actions/seller/SellerMediaActions";
// import { openCreateMediaFormModal } from "../../actions/media-selection/CreateMediaModalAction";
import { getRoadStretchesByCityId } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";

// Constant and Utils
import { defaultPagination } from "../../../constants/UrlConstants";
import {
  // getOtsAndCostDetails,
  getTableHeaders,
} from "../../utils/campaignPlanningUtil";
import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
// import { ProohFormDataTargets } from "../../constants/GeneralConstants";
import {
  formatText,
  // toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
// import { calculateAdjustedMop } from "../../utils/PriceFactorUtil";
// import { useGetMediaPrice } from "../../utils/HooksUtil";
import {
  getCitySpecificMediaList,
  getRoadSpecificMediaList,
  getRoadSpecificSelectedMediaCount,
  getSelectedMediaCount,
  getSelectedStretchesAndMediaCount,
  getSitesTotalImpressionsAndCost,
} from "./MediaContainerUtil";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
// import Spinner from "../../../components/spinner/Spinner";
import MediaContainer from "./MediaContainer";

function RoadNameCell({
  roadInfo = {},
  roadMedias = [],
  openMediaContainer,
  setOpenMediaContainer = () => {},
}) {
  const { segmentIds, name } = roadInfo || {};
  const roadType = segmentIds ? "Stretch" : "Segment";
  const roadNameWithType = `${roadType}: ${name}`;

  const isRoadMediasExist = roadMedias.length > 0;
  const iconClassName = openMediaContainer ? "fa-angle-down" : "fa-angle-right";

  return (
    <td className="align-middle col-3">
      <div className="d-flex align-items-center">
        {isRoadMediasExist && (
          <i
            className={`fa ${iconClassName} mr-1 cursor-pointer`}
            onClick={() => setOpenMediaContainer(!openMediaContainer)}
          />
        )}
        <span title={roadNameWithType} className="text-truncate">
          {roadNameWithType}
        </span>
      </div>
    </td>
  );
}

function SitesSelectedCell({ roadMedias = [], roadSelectedMediaCount = 0 }) {
  const countText = `${roadSelectedMediaCount}/${roadMedias.length}`;

  return (
    <td className="align-middle text-center text-primary col-2">{countText}</td>
  );
}

function TgAndGenImpressions({ estTgSpecific, estGeneric }) {
  const impressionsData = [
    { label: "TG", value: estTgSpecific, className: "mb-1" },
    { label: "Gen", value: estGeneric, className: "mb-1" },
  ];

  // TODO :: remove at last while code cleanup
  // return (
  //   <td className="align-top col-2">
  //     <div className="text-left">
  //       {estTgSpecific !== "" && (
  //         <p className="mb-1">{`Tg: ${formatText(
  //           toLocaleString(estTgSpecific)
  //         )}`}</p>
  //       )}
  //       {estGeneric !== "" && (
  //         <span>{`Gen: ${formatText(toLocaleString(estGeneric))}`}</span>
  //       )}
  //     </div>
  //   </td>
  // );

  return (
    <td className="align-top col-2">
      <div>
        {impressionsData.map((imp, i) => {
          const { label, value, className } = imp;
          const formattedValue =
            value === 0 ? 0 : formatText(toStringWithRoundUp(value));
          return (
            <p key={i} className={className}>
              {`${label}: ${formattedValue}`}
            </p>
          );
        })}
      </div>
    </td>
  );
}

// function TgAndGenCost({ estTgSpecific, estGeneric }) {
//   return (
//     <td className="align-top col-2">
//       <div className="text-left">
//         {estTgSpecific !== "" && (
//           <p className="mb-1">{`Tg: ${formatText(
//             toLocaleString(estTgSpecific)
//           )}`}</p>
//         )}
//         {estGeneric !== "" && (
//           <span>{`Gen: ${formatText(toLocaleString(estGeneric))}`}</span>
//         )}
//       </div>
//     </td>
//   );
// }

function TotalCost({ totalCost }) {
  const formattedTotalCost =
    totalCost === 0 ? 0 : formatText(toStringWithRoundUp(totalCost));

  return (
    <td className="align-top col-2">
      <p>{formattedTotalCost}</p>
    </td>
  );
}

function SelectionStatus({ roadStretchId }) {
  const selectedMediaCount = useSelector(
    (state) => state.mediaSelection.mediaStretchSelectedMap[roadStretchId]
  );

  const borderClassName = selectedMediaCount ? "border-success" : "";
  const iconName = selectedMediaCount
    ? "fa-check text-success"
    : "fa-minus text-secondary";

  return (
    <td className="text-center align-middle col-1">
      <span
        className={`border p-2 rounded-lg cursor-pointer ${borderClassName}`}
      >
        <i className={`fa ${iconName}`}></i>
      </span>
    </td>
  );
}

// Road Details Row
function RoadDetailsRow({
  roadInfo = {},
  cityPlan = {},
  duration,
  campaignMediasMap = {},
}) {
  const { cityId } = useParams();

  // State
  const [openMediaContainer, setOpenMediaContainer] = useState(true);

  // campaignMedias : selectedMediaBooleanMap : { mediaId: true, mediaId: false }
  const selectedMediaBooleanMap = useSelector(
    (state) => state.mediaSelection.selectedMedia
  );

  // if roadInfo not present
  if (!roadInfo || Object.keys(roadInfo).length === 0) {
    return null;
  }

  const {
    // targetGroupCPM,
    // genericCPM,
    roadStretchOtsMap = {},
  } = cityPlan || {};

  const {
    id: roadId,
    orgMedias: roadMedias = [],
    // avgMOP: mop,
    // occupancyAvg: occupancy,
  } = roadInfo || {};

  // road specific selected media count
  const roadSelectedMediaCount = getRoadSpecificSelectedMediaCount(
    roadMedias,
    selectedMediaBooleanMap
  );

  // ots values of current stretch
  const stretchFromCityPlan = roadStretchOtsMap[roadId];
  const { ots, otsLit, targetOts, targetOtsLit } = stretchFromCityPlan || {};
  const finalOts = otsLit ?? ots;
  const finalTargetOts = targetOtsLit ?? targetOts;

  // city specific medias
  const campaignMedias = Object.values(campaignMediasMap);
  const citySpecificMediaList = getCitySpecificMediaList(
    campaignMedias,
    cityId
  );

  // road specific media
  const roadSpecificMediaList = getRoadSpecificMediaList(
    roadId,
    citySpecificMediaList
  );

  // total cost
  const { totalEstCost } = getSitesTotalImpressionsAndCost(
    roadSpecificMediaList
  );

  // Ots calculation
  // const { estTgSpecific, estGeneric, estTotal } = getOtsAndCostDetails(
  //   roadInfo,
  //   cityPlan,
  //   duration
  // );

  // Est Price Calculation
  // const adjMop = calculateAdjustedMop(mop, occupancy, duration);
  // const pricePerImp = adjMop / estTotal;
  // const mediaCount = roadMedias ? roadMedias.length : 1;
  // const estTgCost = estTgSpecific
  //   ? pricePerImp * (targetGroupCPM / 100) * estTgSpecific * mediaCount
  //   : "";

  // const estGenCost = estGeneric
  //   ? pricePerImp * (genericCPM / 100) * estGeneric * mediaCount
  //   : pricePerImp * (genericCPM / 100) * estTotal * mediaCount;

  return (
    <>
      <tr>
        <RoadNameCell
          roadInfo={roadInfo}
          roadMedias={roadMedias}
          openMediaContainer={openMediaContainer}
          setOpenMediaContainer={setOpenMediaContainer}
        />

        <SitesSelectedCell
          roadMedias={roadMedias}
          roadSelectedMediaCount={roadSelectedMediaCount}
        />

        {/* Road Stretch Impressions*/}
        {/* <TgAndGenImpressions
          estTgSpecific={estTgSpecific ? estTgSpecific : ""}
          estGeneric={estGeneric ? estGeneric : estTotal}
        /> */}
        <TgAndGenImpressions
          estTgSpecific={finalTargetOts}
          estGeneric={finalOts}
        />

        {/* (Road Stretch * No.of Media) Impressions */}
        {/* <TgAndGenImpressions
          estTgSpecific={estTgSpecific ? estTgSpecific * mediaCount : ""}
          estGeneric={estGeneric ? estGeneric : estTotal}
        /> */}
        <TgAndGenImpressions
          estTgSpecific={finalTargetOts * roadSelectedMediaCount}
          estGeneric={finalOts * roadSelectedMediaCount}
        />

        {/* <TgAndGenCost estTgSpecific={estTgCost} estGeneric={estGenCost} /> */}
        <TotalCost totalCost={totalEstCost} />

        <SelectionStatus roadStretchId={roadId} />
      </tr>

      {openMediaContainer && (
        <tr>
          <td className="p-0" colSpan={6}>
            <MediaContainer
              roadInfo={roadInfo}
              cityPlan={cityPlan}
              roadMedias={roadMedias}
            />
          </td>
        </tr>
      )}
    </>
  );
}

function AddMediaSection() {
  // const dispatch = useDispatch();

  // create Media Loading
  // const createMediaLoading = useSelector(
  //   (state) => state.mediaSelection.createMediaLoading
  // );

  return (
    <div className="d-flex justify-content-between">
      <h4>Site Details</h4>

      {/* <div className="d-flex">
        {createMediaLoading && <Spinner className="spinner-border-sm mr-2" />}
        <span
          className="text-primary cursor-pointer"
          data-toggle="modal"
          data-target={`#${ProohFormDataTargets.sellerCreateMediaForm}`}
          onClick={() => dispatch(openCreateMediaFormModal())}
        >
          {"Add a site"}
        </span>
      </div> */}
    </div>
  );
}

/**
 * Page Section
 */
export default function RoadAndMediaSection({
  cityPlan = {},
  campaignMediasMap = {}, // campaign medias map
}) {
  const dispatch = useDispatch();
  const { campaignId, cityId } = useParams();

  // campaign city plan
  const {
    roadStretchOtsMap = {},
    startTimestamp,
    endTimestamp,
    mediaTypes = [],
  } = cityPlan || {};
  const stretchIds = Object.keys(roadStretchOtsMap);
  const stretchIdsLength = stretchIds.length;

  const roadStretchDetailsMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // selectedMediaBooleanMap : { mediaId: true, mediaId: false }
  const selectedMediaBooleanMap = useSelector(
    (state) => state.mediaSelection.selectedMedia
  );
  const selectedMediaCount = getSelectedMediaCount(selectedMediaBooleanMap);

  // selected stretch data and total mediaCount on stretch
  const { selectedStretchesMap, totalMediaCount } =
    getSelectedStretchesAndMediaCount(cityPlan, roadStretchDetailsMap);

  useEffect(() => {
    if (Object.keys(campaignMediasMap).length < 1) {
      dispatch(
        getCampaignMedia(
          campaignId,
          defaultPagination.pageNo,
          defaultPagination.pageSize
        )
      );
    }

    if (stretchIdsLength > 0) {
      dispatch(getRoadStretchesByCityId(cityId, "", [], mediaTypes));
    }
  }, [dispatch, campaignId, stretchIdsLength]);

  // uncomment if required
  // useGetMediaPrice(stretchesMediaIds);

  if (Object.keys(cityPlan).length < 1) {
    return null;
  }

  const totalDays = getDifferenceInDays(startTimestamp, endTimestamp);
  const tableHeaders = getTableHeaders(totalMediaCount, selectedMediaCount);

  return (
    <div className="mt-5">
      <AddMediaSection />

      {/* Table */}
      <div className="table-responsive">
        <table className="table">
          <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
          <tbody>
            {Object.keys(selectedStretchesMap).map((eachStretchId) => {
              const roadInfo = selectedStretchesMap[eachStretchId];
              return (
                <RoadDetailsRow
                  key={eachStretchId}
                  roadInfo={roadInfo}
                  cityPlan={cityPlan}
                  duration={totalDays}
                  campaignMediasMap={campaignMediasMap}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
