//Utils
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

//Constants
import {
  getLabelDetectionResults,
  getPresignedUrl,
  startLabelDetection,
} from "../urls/CloudURL";

export async function getPresignedUrlFn(fileName, fileType) {
  return sendGetRequest(getPresignedUrl, { fileName, fileType }, false);
}

export async function uploadVideoFn(url, file) {
  return sendCreateOrUpdateRequest(url, file, false, "PUT", {
    headers: { "Content-Type": file.type },
  });
}

export async function startLabelDetectionFn(videoS3Url) {
  return sendCreateOrUpdateRequest(
    startLabelDetection,
    {
      videoS3Url,
    },
    false
  );
}

export async function getLabelDetectionResultsFn(jobId) {
  return sendGetRequest(
    getLabelDetectionResults,
    {
      jobId,
    },
    false
  );
}
