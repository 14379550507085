// Components
import SearchInput from "../../../components/search-input/SearchInput";
import Spinner from "../../../components/spinner/Spinner";

// Table Header With Actions
export function TableHeaderWithAction({
  onClickSearch = () => {},
  onSearchTextChange = () => {},
}) {
  return (
    <div className="d-flex justify-content-between">
      <h4 className="card-title my-1">{"Campaigns"}</h4>
      <SearchInput
        placeholder="Search Campaign"
        onClickSearch={onClickSearch}
        onTextChange={onSearchTextChange}
      />
    </div>
  );
}

// Loader
export function Loader() {
  return (
    <tr className="text-center">
      <td colSpan={6}>
        <Spinner />
      </td>
    </tr>
  );
}
