import { toast } from "react-toastify";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Apis
import {
  createBuyerFn,
  getBuyerDetailsFn,
  getBuyersListFn,
  getUsersByBuyerIdFn,
  updateBuyerFn,
} from "../../apis/BuyerAPI";

// Constants and Utils
import { Buyer } from "../../constants/action-constants/BuyerActionConstants";
import { getErrorMessage } from "../../utils/util";

// Page Utils Functions
function* getUpdatedBuyerList(keyWord, pageNumber, pageSize) {
  const data = yield getBuyersListFn(keyWord, pageNumber, pageSize);
  const { items, pagination } = data || {};

  yield put({
    type: Buyer.GET_BUYERS_LIST_SUCCESS,
    payload: { items, pagination },
  });
}

function* getBuyersList(action) {
  const { keyWord, pageNumber, pageSize } = action.payload || {};

  try {
    yield getUpdatedBuyerList(keyWord, pageNumber, pageSize);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Buyer.GET_BUYERS_LIST_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* getUsersByBuyerId(action) {
  try {
    const { buyerId } = action.payload;
    const usersList = yield getUsersByBuyerIdFn(buyerId);

    yield put({
      type: Buyer.GET_USERS_BY_BUYER_ID_SUCCESS,
      payload: { usersList },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Buyer.GET_USERS_BY_BUYER_ID_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* getBuyerDetails(action) {
  const { buyerId } = action.payload || {};
  try {
    const buyerDetails = yield getBuyerDetailsFn(buyerId);

    yield put({
      type: Buyer.GET_BUYER_DETAILS_SUCCESS,
      payload: { buyerDetails },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Buyer.GET_BUYER_DETAILS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* createBuyer(action) {
  const { buyerBean } = action.payload || {};
  try {
    // create buyer
    yield createBuyerFn(buyerBean);

    // updated buyer list
    yield getUpdatedBuyerList();

    yield put({
      type: Buyer.CREATE_BUYER_SUCCESS,
    });

    toast.success("Successfully create buyer");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Buyer.CREATE_BUYER_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* updateBuyer(action) {
  const { buyerId, buyerBean } = action.payload || {};
  try {
    // update buyer
    yield updateBuyerFn(buyerId, buyerBean);

    // updated buyer list
    yield getUpdatedBuyerList();

    yield put({
      type: Buyer.UPDATE_BUYER_SUCCESS,
      payload: { buyerId },
    });

    toast.success("Successfully updated buyer");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Buyer.UPDATE_BUYER_FAILURE,
      payload: { error, buyerId },
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Buyer.GET_BUYERS_LIST, getBuyersList),
    takeLatest(Buyer.GET_USERS_BY_BUYER_ID, getUsersByBuyerId),
    takeLatest(Buyer.GET_BUYER_DETAILS, getBuyerDetails),
    takeLatest(Buyer.CREATE_BUYER, createBuyer),
    takeEvery(Buyer.UPDATE_BUYER, updateBuyer),
  ]);
}
