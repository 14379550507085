import React from "react";

// Components
import DashboardCard from "../../../components/dashboard-card/DashboardCard";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Sections
import CampaignsTable from "./CampaignsTable";

// Page Constants
const cardsInfo = [
  {
    title: "Create a Campaign",
    content:
      "Manage your campaign end to end from creation, analysis and monitoring.",
    CTALabel: "Create A Campaign",
    CTALink: "/campaign/planning",
  },
  // {
  //   title: "Pure DOOH Platform",
  //   content:
  //     "Analyze individual DOOH media sites and their estimated impressions.",
  //   CTALabel: "Create A Campaign",
  //   CTALink: "/seller/pitch/single-site-report",
  //   isDisabledBtn: true,
  // },
  {
    title: "Manage Buyers",
    content: "Create and manage buyers and it's users for the organization.",
    CTALabel: "Manage Buyers",
    CTALink: "/buyer/list",
  },
];

// Page Components
function HomeCardsSection() {
  return (
    <>
      <div className="justify-content-left row">
        {cardsInfo.map((card, i) => {
          return <DashboardCard key={i} card={card} i={i} />;
        })}
      </div>
      <hr className="divider" />
    </>
  );
}

const pageActions = (
  <>
    <p className="pt-2">Version : {process.env.REACT_APP_VERSION}</p>
    {/* <p>Last updated Thursday, March 20, 2021, 10:45 PM</p> */}
  </>
);

/**
 * Page
 */
export default function HomePage() {
  return (
    <div className="content-wrapper bg-alt min-vh-100">
      <PageHeader
        title={"Dashboard"}
        border={false}
        shadow={false}
        actions={pageActions}
      />

      <div className="page-content">
        <HomeCardsSection />
        <CampaignsTable />
      </div>
    </div>
  );
}
