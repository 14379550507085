import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { getSellerSummaryByCity } from "../../../actions/seller/SellerAnalyticsActions";

// Constants and Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

// Components
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import Filter from "../../../components/filter/Filter";
import PerformanceCards from "../../components/performance-card/PerformanceCard";
import TableHeaders from "../../../components/table/TableHeaders";
import SearchInput from "../../../components/search-input/SearchInput";
import Spinner from "../../../components/spinner/Spinner";
import { MediaInfo } from "../components/MediaRow";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Site Name",
      className: "col-5",
    },
  },

  {
    title: {
      displayName: "City",
      className: "col-2 text-right",
    },
  },

  {
    title: {
      displayName: "Campaigns",
      className: "col-2 text-right",
    },
  },

  {
    title: {
      displayName: "Earnings",
      className: "col-2 text-right",
    },
  },

  {
    title: {
      displayName: "",
      className: "col-1 text-right",
    },
  },
];

// Page Functions
function getCardInfo(sellerSummary) {
  const { mediaList = [], campaigns = {}, earningsTotal } = sellerSummary;

  const mediaCount = mediaList.length;
  const totalCampaigns = Object.keys(campaigns).length;
  return [
    {
      title: "No. of Sites",
      value: mediaCount ? toLocaleString(mediaCount) : "0",
    },
    {
      title: "Campaigns",
      value: totalCampaigns ? toLocaleString(totalCampaigns) : "0",
    },
    {
      title: "Earnings",
      value: `Rs. ${earningsTotal ? toLocaleString(earningsTotal) : "0"}`,
    },
  ];
}

// Page Components
function SellerDateSelectorSection({
  dateRange = {},
  setDateRange = () => {},
}) {
  const onSelectDateRange = (selectedDate) => {
    const startDate = selectedDate.startDate.toDate();
    const endDate = selectedDate.endDate.toDate();
    const newDateObj = { startDate, endDate };
    setDateRange(newDateObj);
  };

  return (
    <div className="d-flex">
      <h3 className="mb-0">{"Earnings"}</h3>
      <BootstrapDateRangePicker
        initialStartDate={dateRange.startDate}
        initialEndDate={dateRange.endDate}
        onApplyDates={onSelectDateRange}
        styleObject={{
          buttonClassName: "ml-5",
          border: true,
        }}
      />
    </div>
  );
}

function SellerEarningOverviewCards({ sellerSummary }) {
  const cardInfo = getCardInfo(sellerSummary);

  return (
    <div className="d-flex mt-4">
      {cardInfo.map((info, i) => (
        <PerformanceCards info={info} key={i} className={"col-2"} />
      ))}
    </div>
  );
}

function SearchAndFilterSection() {
  return (
    <>
      <h4 className="mt-5 pt-2">{"Site Details"}</h4>
      <div className="d-none align-items-center">
        <div className="col-3 px-0">
          <SearchInput placeholder="Search media sites" />
        </div>
        <Filter className="shadow-none border" />
      </div>
    </>
  );
}

function CityCell({ media }) {
  const cityName = media.cityName || "--";

  return <td className="text-right">{cityName}</td>;
}

function CampaignsCell({ mediaSummary }) {
  const campaignsCount = toLocaleString(mediaSummary.campaignsCount) || "0";
  return <td className="text-right">{campaignsCount}</td>;
}

function EarningsCell({ mediaSummary }) {
  const earningsTotal = toLocaleString(mediaSummary.earningsTotal) || "0";
  return (
    <td className="text-right">
      <p className="m-0">{`Rs. ${earningsTotal}`}</p>
    </td>
  );
}

function ActionButtonCell({ media }) {
  const mediaId = media.mediaId;

  // Sending DateRangeObj to "MediaEarning" page
  const dateRange = useSelector((state) => state.sellerAnalytics.dateRange);

  return (
    <td className="text-right">
      <Link
        to={{
          pathname: constructRedirectPath(`/seller/earnings/media/${mediaId}`),
          state: dateRange,
        }}
      >
        {"View Details"}
      </Link>
    </td>
  );
}

function SellerEarningMediaTableRow({ media, mediaSummary }) {
  return (
    <tr>
      <MediaInfo media={media} />
      <CityCell media={media} />
      <CampaignsCell mediaSummary={mediaSummary} />
      <EarningsCell mediaSummary={mediaSummary} />
      <ActionButtonCell media={media} />
    </tr>
  );
}

function SellerEarningMediaTable({ sellerSummary }) {
  const { mediaList = [], mediaSummaries = {} } = sellerSummary;

  if (mediaList.length < 1) {
    return null;
  }

  return (
    <div className="table-responsive mt-4">
      <table className="table table-media">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
        <tbody>
          {/* Table row */}
          {mediaList.map((media) => (
            <SellerEarningMediaTableRow
              key={media.mediaId}
              media={media}
              mediaSummary={mediaSummaries[media.mediaId]}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

/**
 * Page
 */
export default function SellerEarningsPage() {
  const dispatch = useDispatch();

  // State
  const [cityId, setCityId] = useState("city-delhi-ncr");
  const [dateRange, setDateRange] = useState({
    startDate: new Date("2022-01-01"),
    endDate: new Date("2022-12-31"),
  });

  const { startDate, endDate } = dateRange;

  // Selector State
  const sellerSummary = useSelector(
    (state) => state.sellerAnalytics.sellerSummary
  );
  const sellerSummaryLoading = useSelector(
    (state) => state.sellerAnalytics.sellerSummaryLoading
  );

  useEffect(() => {
    dispatch(getSellerSummaryByCity(cityId, dateRange));
  }, [dispatch, cityId, startDate, endDate]);

  // Page Loading
  if (sellerSummaryLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="p-5">
      <SellerDateSelectorSection
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      {/* Earning info card */}
      <SellerEarningOverviewCards sellerSummary={sellerSummary} />

      {/* Search and filter */}
      <SearchAndFilterSection />

      {/* Media table */}
      <SellerEarningMediaTable sellerSummary={sellerSummary} />
    </div>
  );
}
