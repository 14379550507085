import { ActionTypes } from "../../../constants/ActionConstants";
import { updateTgAttributesMap } from "../../pages/campaign-planning/CampaignPlanningUtils";
import { getStretchImpressions } from "../../utils/campaignPlanningUtil";
import { calculateAdjustedMop } from "../../utils/PriceFactorUtil";

const initialState = {
  duration: "",
  durationInDays: 1,

  planCampaignLoading: false,
  planCampaignErr: "",
  campaignId: "",

  // campaign-title
  campaignTitle: "Campaign Planning",

  // Price Factors
  tgCpm: 100,
  genCpm: 50,

  // Impressions
  genericOts: 0,
  specificOts: 0,
  totalOts: 0,

  // Impressions and Est Price
  roadStretchImpAndCostMap: {
    genericOts: 0,
    specificOts: 0,
    genericCost: 0,
    specificCost: 0,
  },

  // Restore Campaign Plan
  restorePlanLoading: false,
  restorePlanErr: "",
  restorePlanSuccess: false,

  // Region Data
  regionLoading: false,
  regionError: "",
  regionsData: {},
  cityId: "",

  // TG
  selectedTgAttributesMap: {},

  selectedMediaTypes: {
    "Head on": false,
    LHS: false,
    RHS: false,
  },

  // Manage Tg(create & update) & generate Heatmap and get tgOts for city stretches
  manageTgAndGetOtsForCityStretchesLoading: false,
  manageTgAndGetOtsForCityStretchesError: "",

  // Hovered Stretch
  hoveredStretchId: "",

  // Selected Touch Point Ids
  selectedTouchPointIds: [],
};

function toggleCheckboxSelections(obj, keys) {
  const clonedObj = { ...obj };

  keys.forEach((key) => {
    clonedObj[key] = !clonedObj[key];
  });

  return clonedObj;
}

function calculateStretchImpAndCost(
  roadStretch,
  targetGroupCPM,
  genericCPM,
  duration
) {
  const { genericImpressions, specificImpressions } =
    getStretchImpressions(roadStretch);

  const estTotal = specificImpressions
    ? specificImpressions + genericImpressions
    : genericImpressions;

  // Est Price Calculation
  const { minOperatingPriceAvg: mop, occupancyAvg: occupancy } = roadStretch;
  const adjMop = calculateAdjustedMop(mop, occupancy, duration);
  const pricePerImp = adjMop / estTotal;
  const specificCost = specificImpressions
    ? pricePerImp * (targetGroupCPM / 100) * specificImpressions
    : "";

  const genericCost = genericImpressions
    ? pricePerImp * (genericCPM / 100) * genericImpressions
    : "";

  return {
    genericOts: genericImpressions,
    specificOts: specificImpressions,
    genericCost,
    specificCost,
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CampaignPlanner.CHANGE_DURATION: {
      const { dateObj: duration, dayCount } = action.payload || {};
      const { startDate, endDate } = duration || {};
      const diffTime = Math.abs(startDate - endDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      return {
        ...state,
        duration,
        // dayCount: for dateSelector
        // diffDays: for restore campaign
        durationInDays: dayCount ? dayCount : diffDays,
      };
    }

    case ActionTypes.CampaignPlanner.PLAN_CAMPAIGN:
      return {
        ...state,
        planCampaignLoading: true,
      };

    case ActionTypes.CampaignPlanner.PLAN_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignId: action.campaignId,
        planCampaignLoading: false,
      };

    case ActionTypes.CampaignPlanner.PLAN_CAMPAIGN_FAILURE:
      return {
        ...state,
        planCampaignLoading: false,
        planCampaignErr: action.payload,
      };

    // For campaign Plan restore
    case ActionTypes.CampaignPlanner.RESTORE_CAMPAIGN_PLAN:
      return {
        ...state,
        restorePlanLoading: true,
      };

    case ActionTypes.CampaignPlanner.RESTORE_CAMPAIGN_PLAN_SUCCESS:
      return {
        ...state,
        restorePlanLoading: false,
        restorePlanSuccess: true,
      };

    case ActionTypes.CampaignPlanner.RESTORE_CAMPAIGN_PLAN_FAILURE:
      return {
        ...state,
        restorePlanLoading: false,
        restorePlanErr: action.payload,
      };

    case ActionTypes.CampaignPlanner.SET_CAMPAIGN_TITLE:
      return {
        ...state,
        campaignTitle: action.payload.titleStr,
      };

    case ActionTypes.CampaignPlanner.SET_TG_CPM:
      return {
        ...state,
        tgCpm: Number(action.payload.tgCpmStr),
      };

    case ActionTypes.CampaignPlanner.SET_GEN_CPM:
      return {
        ...state,
        genCpm: Number(action.payload.genCpmStr),
      };

    case ActionTypes.CampaignPlanner.ADD_GENERIC_OTS: {
      const newGenericOts = Number(action.payload.genericOts);
      const totalGenericOts = newGenericOts
        ? newGenericOts + state.genericOts
        : state.genericOts;
      return {
        ...state,
        genericOts: totalGenericOts,
      };
    }

    case ActionTypes.CampaignPlanner.REMOVE_GENERIC_OTS: {
      const newGenericOts = Number(action.payload.genericOts);
      const totalGenericOts = newGenericOts
        ? state.genericOts - newGenericOts
        : state.genericOts;
      return {
        ...state,
        genericOts: totalGenericOts,
      };
    }

    case ActionTypes.CampaignPlanner.ADD_SPECIFIC_OTS: {
      const newSpecificOts = Number(action.payload.specificOts);
      const totalSpecificOts = newSpecificOts
        ? newSpecificOts + state.specificOts
        : state.specificOts;
      return {
        ...state,
        specificOts: totalSpecificOts,
      };
    }

    case ActionTypes.CampaignPlanner.REMOVE_SPECIFIC_OTS: {
      const newSpecificOts = Number(action.payload.specificOts);
      const totalSpecificOts = newSpecificOts
        ? state.specificOts - newSpecificOts
        : state.specificOts;
      return {
        ...state,
        specificOts: totalSpecificOts,
      };
    }

    case ActionTypes.CampaignPlanner.ADD_TOTAL_OTS: {
      const newTotalOts = Number(action.payload.totalOts);
      const totalOts = newTotalOts
        ? newTotalOts + state.totalOts
        : state.totalOts;
      return {
        ...state,
        totalOts,
      };
    }

    case ActionTypes.CampaignPlanner.REMOVE_TOTAL_OTS: {
      const newTotalOts = Number(action.payload.totalOts);
      const totalOts = newTotalOts
        ? state.totalOts - newTotalOts
        : state.totalOts;
      return {
        ...state,
        totalOts,
      };
    }

    case ActionTypes.CampaignPlanner.ADD_IMP_AND_EST_COST: {
      const { roadStretch, targetGroupCPM, genericCPM, duration } =
        action.payload;
      const { genericOts, specificOts, genericCost, specificCost } =
        calculateStretchImpAndCost(
          roadStretch,
          targetGroupCPM,
          genericCPM,
          duration
        );

      const roadStretchImpAndCostMap = { ...state.roadStretchImpAndCostMap };
      roadStretchImpAndCostMap.genericOts =
        roadStretchImpAndCostMap.genericOts + genericOts;

      roadStretchImpAndCostMap.specificOts =
        roadStretchImpAndCostMap.specificOts + specificOts;

      roadStretchImpAndCostMap.genericCost =
        roadStretchImpAndCostMap.genericCost + genericCost;

      roadStretchImpAndCostMap.specificCost =
        roadStretchImpAndCostMap.specificCost + specificCost;
      return {
        ...state,
        roadStretchImpAndCostMap,
      };
    }

    case ActionTypes.CampaignPlanner.REMOVE_IMP_AND_EST_COST: {
      const { roadStretch, targetGroupCPM, genericCPM, duration } =
        action.payload;
      const { genericOts, specificOts, genericCost, specificCost } =
        calculateStretchImpAndCost(
          roadStretch,
          targetGroupCPM,
          genericCPM,
          duration
        );

      const roadStretchImpAndCostMap = { ...state.roadStretchImpAndCostMap };
      roadStretchImpAndCostMap.genericOts =
        roadStretchImpAndCostMap.genericOts - genericOts;

      roadStretchImpAndCostMap.specificOts =
        roadStretchImpAndCostMap.specificOts - specificOts;

      roadStretchImpAndCostMap.genericCost =
        roadStretchImpAndCostMap.genericCost - genericCost;

      roadStretchImpAndCostMap.specificCost =
        roadStretchImpAndCostMap.specificCost - specificCost;
      return {
        ...state,
        roadStretchImpAndCostMap,
      };
    }

    case ActionTypes.CampaignPlanner.GET_REGIONS:
      return {
        ...state,
        regionLoading: true,
      };

    case ActionTypes.CampaignPlanner.GET_REGIONS_SUCCESS:
      return {
        ...state,
        regionsData: action.regionsData,
        regionLoading: false,
        cityId: action.cityId,
      };

    case ActionTypes.CampaignPlanner.GET_REGIONS_FAILURE:
      return {
        ...state,
        regionLoading: false,
        regionError: action.payload,
      };

    case ActionTypes.CampaignPlanner.RESET_PLAN_CAMPAIGN:
      return initialState;

    // TG Tab Select / unselect
    case ActionTypes.CampaignPlanner.SELECT_TG_ATTRIBUTE: {
      const { tgAttribute } = action.payload;
      const updatedTgAttributesMap = updateTgAttributesMap(
        state.selectedTgAttributesMap,
        tgAttribute,
        true
      );

      return {
        ...state,
        selectedTgAttributesMap: updatedTgAttributesMap,
      };
    }
    case ActionTypes.CampaignPlanner.UN_SELECT_TG_ATTRIBUTE: {
      const { tgAttribute } = action.payload;
      const updatedTgAttributesMap = updateTgAttributesMap(
        state.selectedTgAttributesMap,
        tgAttribute,
        false
      );
      return {
        ...state,
        selectedTgAttributesMap: updatedTgAttributesMap,
      };
    }
    case ActionTypes.CampaignPlanner.RESTORE_TG_ATTRIBUTES: {
      return {
        ...state,
        selectedTgAttributesMap: action.payload.tgAttrMap,
      };
    }
    case ActionTypes.CampaignPlanner.CLEAR_TG_ATTRIBUTES: {
      return {
        ...state,
        selectedTgAttributesMap: {},
      };
    }

    case ActionTypes.CampaignPlanner.TOGGLE_MEDIA_TYPE: {
      const { mediaTypes } = action.payload;
      const updatedRoutes = toggleCheckboxSelections(
        state.selectedMediaTypes,
        mediaTypes
      );

      return {
        ...state,
        selectedMediaTypes: updatedRoutes,
      };
    }

    // Manage Tg(create & update) & generate Heatmap and get tgOts for city stretches
    case ActionTypes.CampaignPlanner
      .MANAGE_TG_AND_GET_TG_OTS_FOR_CITY_STRETCHES:
      return {
        ...state,
        manageTgAndGetOtsForCityStretchesLoading: true,
      };

    case ActionTypes.CampaignPlanner
      .MANAGE_TG_AND_GET_TG_OTS_FOR_CITY_STRETCHES_SUCCESS:
      return {
        ...state,
        manageTgAndGetOtsForCityStretchesLoading: false,
      };

    case ActionTypes.CampaignPlanner
      .MANAGE_TG_AND_GET_TG_OTS_FOR_CITY_STRETCHES_FAILURE:
      return {
        ...state,
        manageTgAndGetOtsForCityStretchesLoading: false,
        manageTgAndGetOtsForCityStretchesError: action.payload,
      };

    // Hovered Stretch Id
    case ActionTypes.CampaignPlanner.SET_HOVERED_STRETCH_ID: {
      const { stretchId } = action.payload || {};
      return {
        ...state,
        hoveredStretchId: stretchId,
      };
    }

    // Touch Point Select / unselect
    case ActionTypes.CampaignPlanner.SELECT_TOUCH_POINT: {
      const { id } = action.payload;
      return {
        ...state,
        selectedTouchPointIds: [...state.selectedTouchPointIds, id],
      };
    }
    case ActionTypes.CampaignPlanner.UN_SELECT_TOUCH_POINT: {
      const { id } = action.payload;
      const clonedTouchPointIds = [...state.selectedTouchPointIds];
      const touchPointIndex = clonedTouchPointIds.findIndex(
        (touchPointId) => touchPointId === id
      );

      // only splice array when item is found
      if (touchPointIndex > -1) {
        clonedTouchPointIds.splice(touchPointIndex, 1);
      }

      return {
        ...state,
        selectedTouchPointIds: clonedTouchPointIds,
      };
    }

    default:
      return state;
  }
};
