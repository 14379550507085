import { useState } from "react";
import { useMapEvents } from "react-leaflet";

// CSS
import "./MapZoomIndicator.scss";

/**
 * Map Zoom Indicator
 */
export default function MapZoomIndicator({
  showText = true,
  iconClassName = "fa-6x",
}) {
  const [showZoomIndicator, setShowZoomIndicator] = useState(true);

  // map event
  useMapEvents({
    // Every "ZoomEnd" we will update state in this Component only.
    zoomend() {
      setShowZoomIndicator(false);
    },

    // Click event
    click() {
      setShowZoomIndicator(false);
    },
  });

  // remove indicator
  if (!showZoomIndicator) {
    return null;
  }

  // indicator
  return (
    <div className="zoom-indicator-box">
      <div className="zoom-in-out">
        <p className="mb-0">
          <i className={`fa fa-arrow-up ${iconClassName}`} />
          <i className={`fa fa-arrow-right ${iconClassName}`} />
        </p>

        {/* message */}
        {showText && <p className="mb-0 text-center">Zoom For Detailed View</p>}

        <p className="mb-0">
          <i className={`fa fa-arrow-left ${iconClassName}`} />
          <i className={`fa fa-arrow-down ${iconClassName}`} />
        </p>
      </div>
    </div>
  );
}
