// Utils & Constants
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";
import {
  DefaultPagination,
  LocalStorageKeys,
  PageSize,
} from "../constants/GeneralConstants";
import { getItem } from "../utils/localstorage";

// Urls
import {
  getOrgAllUserListUrl,
  getOrgUserByIdUrl,
  disableOrgUserUrl,
  enableOrgUserUrl,
  createOrgUserUrl,
  updateOrgUserUrl,
  updateOrgUserRoleUrl,
  getOrgUserStatsUrl,
  updateOrgUserPriceSettingsUrl,
} from "../urls/OrgUserManageURL";

export async function getOrgAllUsersData(keyWord, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || PageSize.pageSize10,
  };

  if (keyWord) {
    params._kw = keyWord;
  }

  return sendGetRequest(getOrgAllUserListUrl, params);
}

export async function getOrgUserDataById(userId) {
  const url = getOrgUserByIdUrl.replace(":userId", userId);
  return sendGetRequest(url);
}

export async function createOrgUser(newUserObj) {
  // adding orgId to newUserObj
  const user = JSON.parse(getItem(LocalStorageKeys.USER));
  const { orgId } = user || {};
  newUserObj.organizationId = orgId;

  return sendCreateOrUpdateRequest(createOrgUserUrl, newUserObj);
}

export async function enableOrgUser(userId) {
  const url = enableOrgUserUrl.replace(":userId", userId);
  return sendCreateOrUpdateRequest(url, {}, true, "put");
}

export async function disableOrgUser(userId) {
  const url = disableOrgUserUrl.replace(":userId", userId);
  return sendCreateOrUpdateRequest(url, {}, true, "put");
}

export async function updateOrgUserInfo(orgUserInfo, userId) {
  const url = updateOrgUserUrl.replace(":userId", userId);
  return sendCreateOrUpdateRequest(url, orgUserInfo, true, "put");
}

//  TODO ::Remove this once update api is ready
// export async function updateOrgUserRoles(roles, userId) {
//   const url = updateOrgUserRoleUrl.replace(":userId", userId);
//   return sendCreateOrUpdateRequest(url, roles, true, "put");
// }

export async function getOrgUserStatsFn() {
  return sendGetRequest(getOrgUserStatsUrl);
}

export async function updateOrgUserPriceSettingsFn(cityIds) {
  const user = JSON.parse(getItem(LocalStorageKeys.USER));
  const { userId } = user || {};

  const url = updateOrgUserPriceSettingsUrl.replace(":userId", userId);
  return sendCreateOrUpdateRequest(url, { cities: cityIds }, true, "put");
}
