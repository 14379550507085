// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";
import { UrlKeys } from "../../constants/GeneralConstants";

// Components
import DashboardCard from "../../components/dashboard-card/DashboardCard";

// Page Constants
const {
  toolsSiteAnalyserPageUrl,
  toolsCityAnalyserPageUrl,
  toolsOtsPageUrl,
  toolsLtsPageUrl,
  toolsDemographicPageUrl,
  toolsRouteAnalysisPageUrl,
  toolsJunctionAnalysisPageUrl,
  toolsCameraAnalyticPageUrl,
  toolsCameraAnalyticPOCPageUrl,
  toolsReachAndFrequencyPageUrl,
  toolsTargetGroupPageUrl,
  toolsTargetGroupOtsPageUrl,
  toolsCompareLocationsPageUrl,
} = RedirectTo;

const cardsInfo = [
  {
    title: "Site Analyser",
    content: "Analyse a single Media Site",
    CTALabel: "Go to Tool",
    CTALink: toolsSiteAnalyserPageUrl,
    iconClass: "fa-4x fa fa-images opacity-25 text-primary",
  },
  {
    title: "City Analyser",
    content: "Analyse a City",
    CTALabel: "Go to Tool",
    CTALink: toolsCityAnalyserPageUrl.replace(":regionId", "IN"),
    iconClass: "fa-4x fa fa-city opacity-25 text-primary",
  },
  {
    title: "OTS",
    content:
      "Check Impressions in a Geo Location Along With Probability Split of Ots Coming From Feeder Roads",
    CTALabel: "Go to Tool",
    CTALink: toolsOtsPageUrl,
    iconClass: "fa-4x fa fa-people-arrows opacity-25 text-primary",
  },
  {
    title: "LTS",
    content: "Analyse the Likelihood to See of a Media Site",
    CTALabel: "Go to Tool",
    CTALink: toolsLtsPageUrl,
    iconClass: "fa-4x fa fa-desktop opacity-25 text-primary",
  },
  {
    title: "Demographics",
    content: "Analyse the Demographics spread of a Location",
    CTALabel: "Go to Tool",
    CTALink: toolsDemographicPageUrl,
    iconClass: "fa-4x fa fa-border-all opacity-25 text-primary",
  },
  {
    title: "Route Analysis",
    content: "Analyse a Road Stretch",
    CTALabel: "Go to Tool",
    CTALink: toolsRouteAnalysisPageUrl,
    iconClass: "fa-4x fa fa-road opacity-25 text-primary",
  },
  // TODO:: un-comment to ENABLE TOOL
  // {
  //   title: "Reach & Frequency",
  //   content: "Impressions Reach and Frequency of a Location",
  //   CTALabel: "Go to Tool",
  //   CTALink: toolsReachAndFrequencyPageUrl,
  //   iconClass: "fa-4x fa fa-infinity opacity-25 text-primary",
  // },
  // {
  //   title: "Compare Locations",
  //   content: "Compare the Key Performance Indicators of Two Locations",
  //   CTALabel: "Go to Tool",
  //   CTALink: toolsCompareLocationsPageUrl,
  //   iconClass: "fa-4x fa fa-info-circle opacity-25 text-primary",
  // },
  // {
  //   title: "Junction Analysis",
  //   content: "Analyse a Junction",
  //   CTALabel: "Go to Tool",
  //   CTALink: toolsJunctionAnalysisPageUrl,
  //   iconClass: "fa-4x fa fa-asterisk opacity-25 text-primary",
  // },
  // {
  //   title: "Camera Analytic",
  //   content: "Analyse a Location by Camera",
  //   CTALabel: "Go to Tool",
  //   CTALink: toolsCameraAnalyticPageUrl,
  //   iconClass: "fa-4x fa fa-video opacity-25 text-primary",
  // },
  // {
  //   title: "Camera Analytic",
  //   content: "Analyse a Location by Camera",
  //   CTALabel: "Go to Tool",
  //   CTALink: toolsCameraAnalyticPOCPageUrl,
  //   iconClass: "fa-4x fa fa-video opacity-25 text-primary",
  // },
];

function ToolsDashboardCards() {
  const cards = cardsInfo;

  // checking is mantaray specific
  const isMantaraySpecific = useUrlKeyCheck(UrlKeys.mantaraySpecific);

  return (
    <div className="justify-content-center row pt-4">
      {cards.map((card, i) => {
        const { CTALink } = card;

        // hiding route-analysis-tool for mantaray
        if (isMantaraySpecific && CTALink === toolsRouteAnalysisPageUrl) {
          return null;
        }

        return (
          <DashboardCard key={i} card={card} i={i} className={"text-center"} />
        );
      })}
    </div>
  );
}

/**
 * Tools Dashboard Page
 */
function ToolsDashboard() {
  return (
    <div className="content-wrapper bg-alt h-100">
      <div className="page-content">
        {/* Content Header */}
        <div className="text-center mt-3">
          <h2 className="font-weight-bolder">{"Quick Tools"}</h2>
        </div>

        {/* Tools Dashboard Cards */}
        <ToolsDashboardCards />
      </div>
    </div>
  );
}

export default ToolsDashboard;
