import { UserAndRoles } from "../../constants/action-constants/org/OrgUserAndRolesActionConstants";

export const getOrgAllUsers = (keyWord = "", pageNumber, pageSize) => ({
  type: UserAndRoles.GET_ORG_ALL_USERS,
  payload: { keyWord, pageNumber, pageSize },
});

export const getOrgUserById = (userId) => ({
  type: UserAndRoles.GET_ORG_USER_BY_ID,
  payload: { userId },
});

export const disableOrgUserById = (userId, history) => ({
  type: UserAndRoles.DISABLE_ORG_USER,
  payload: { userId, history },
});

export const enableOrgUserById = (userId, history) => ({
  type: UserAndRoles.ENABLE_ORG_USER,
  payload: { userId, history },
});

export const createOrgUser = (newUserObj, history) => ({
  type: UserAndRoles.CREATE_ORG_USER,
  payload: { newUserObj, history },
});

export const updateOrgUser = (orgUserInfo, id, history) => ({
  type: UserAndRoles.UPDATE_ORG_USER,
  payload: { orgUserInfo, id, history },
});

export const clearOrgUserState = () => ({
  type: UserAndRoles.CLEAR_ORG_USER,
});

export const getOrgUserStats = () => ({
  type: UserAndRoles.GET_ORG_USER_STATS,
});

export const updateUserPriceSettings = (cityIds) => ({
  type: UserAndRoles.UPDATE_ORG_USER_PRICE_SETTINGS,
  payload: { cityIds },
});
