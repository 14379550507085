import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// Actions
import {
  clearOrgUserState,
  disableOrgUserById,
  enableOrgUserById,
  getOrgUserById,
  updateOrgUser,
} from "../../../actions/org/OrgUserManageActions";

// Url
import { RedirectTo } from "../../../urls/PageLinksURL";

// Utils
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";
import { UrlKeys } from "../../../constants/GeneralConstants";
import { BuyerSection, ErrorMessage, PermissionSection } from "./OrgFormUtils";
import { LoadingData } from "../OrgUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Components
import OrgSettingsSidebar from "../OrgSettingsSidebar";
import Spinner from "../../../components/spinner/Spinner";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Validation schema
const formValidationSchema = yup.object({
  firstName: yup.string().required("please fill the First Name"),
  lastName: yup.string().required("please fill the Last Name"),
  // roles: yup.array().min(1, "please select permissions"),
});

// Page Components
function FooterSection() {
  //Selector
  const isLoading = useSelector(
    (state) => state.orgUserManage.updateOrgUserLoading
  );

  return (
    <div className="mt-4 float-right">
      <Link to={constructRedirectPath(RedirectTo.orgUserListUrl)}>
        <button className="btn btn-outline-primary mr-2 px-4">Cancel</button>
      </Link>
      <button
        disabled={isLoading}
        type="submit"
        className="btn btn-primary px-4 shadow-none"
      >
        Save {isLoading && <Spinner className="spinner-border-sm text-light" />}
      </button>
    </div>
  );
}

function FirstNameSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">First Name</label>
      <div className="col-sm-10">
        <input
          type="text"
          id="firstName"
          className={`${inputClassName} ${
            errors.firstName && touched.firstName && "is-invalid"
          }`}
          placeholder="Enter First Name"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage error={errors.firstName} />
      </div>
    </div>
  );
}

function LastNameSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Last Name</label>
      <div className="col-sm-10">
        <input
          type="text"
          id="lastName"
          className={`${inputClassName} ${
            errors.lastName && touched.lastName && "is-invalid"
          }`}
          placeholder="Enter Last Name"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage error={errors.lastName} />
      </div>
    </div>
  );
}

function EmailSection({ userInfo, inputClassName }) {
  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Email id </label>
      <div className="col-sm-10">
        <input
          type="text"
          placeholder="Enter Email"
          value={userInfo.emailId}
          disabled={true}
          className={inputClassName}
        />
      </div>
    </div>
  );
}

function PhoneNoSection({ userInfo, inputClassName }) {
  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Phone no.</label>
      <div className="col-sm-10">
        <input
          type="text"
          className={inputClassName}
          placeholder="Enter Phone No."
          value={userInfo.phoneNo}
          disabled={true}
        />
      </div>
    </div>
  );
}

function ManageUserForm({ userInfo }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  // checking is Prooh/Toohl Org
  const isToohlSpecific = useUrlKeyCheck(UrlKeys.toohlSpecific);

  // data
  const { user, authInfo } = userInfo || {};

  // form validation
  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      // TODO ::un comment this once update api is ready
      // roles: authInfo.roles,
    },
    validationSchema: formValidationSchema,
    onSubmit: (orgUserInfo) => {
      dispatch(updateOrgUser(orgUserInfo, id, history));
    },
  });

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    formik || {};
  const formikValuesAndFn = {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
  };

  //Input class name
  const inputClassName = "form-control shadow-none";

  return (
    <form className="col-10 mt-3" onSubmit={handleSubmit}>
      {/* First name */}
      <FirstNameSection
        formikValuesAndFn={formikValuesAndFn}
        inputClassName={inputClassName}
      />

      {/* Last name */}
      <LastNameSection
        formikValuesAndFn={formikValuesAndFn}
        inputClassName={inputClassName}
      />

      {/* Email */}
      <EmailSection userInfo={user} inputClassName={inputClassName} />

      {/* Phone no */}
      <PhoneNoSection userInfo={user} inputClassName={inputClassName} />

      {/* Buyer Field */}
      {isToohlSpecific && (
        <BuyerSection
          inputClassName={inputClassName}
          formik={formik}
          user={user}
        />
      )}

      {/* Permission */}
      {/* TODO ::uncomment this once api is ready*/}
      {/* <PermissionSection formikValuesAndFn={formikValuesAndFn} /> */}

      {/* Save and cancel button */}
      <FooterSection />
    </form>
  );
}

function OrgManageUserPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  // Selector
  const userInfo = useSelector((state) => state.orgUserManage.orgUserInfo);
  const userLoader = useSelector((state) => state.orgUserManage.loading);
  const userStatusLoader = useSelector(
    (state) => state.orgUserManage.userStatusLoading
  );

  useEffect(() => {
    dispatch(clearOrgUserState());
    dispatch(getOrgUserById(id));
  }, [dispatch, id]);

  // data
  const { user } = userInfo || {};
  const { id: userId, isEnabled } = user || {};

  const pageTitle = "Manage User";
  const PageActions = (
    <>
      {isEnabled ? (
        <button
          onClick={() => dispatch(disableOrgUserById(userId, history))}
          className="btn btn-primary shadow-none"
          disabled={userStatusLoader}
        >
          Disable user{" "}
          {userStatusLoader && <Spinner className="spinner-border-sm" />}
        </button>
      ) : (
        <button
          onClick={() => dispatch(enableOrgUserById(userId, history))}
          className="btn btn-primary shadow-none"
          disabled={userStatusLoader}
        >
          Enable user{" "}
          {userStatusLoader && <Spinner className="spinner-border-sm" />}
        </button>
      )}
    </>
  );

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={2} />
      <div className="content-wrapper">
        <PageHeader title={pageTitle} actions={PageActions} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          {userLoader && <LoadingData dataType="User Profile" />}
          {/* Form*/}
          {Object.keys(userInfo).length > 0 && (
            <ManageUserForm userInfo={userInfo} />
          )}
        </div>
      </div>
    </>
  );
}

export default OrgManageUserPage;
