import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Action
import { getOrgAllUsers } from "../../../actions/org/OrgUserManageActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Utils
import { LoadingData } from "../OrgUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { formatText } from "../../../common-utils/string-utils/StringUtils";

// Components
import OrgSettingsSidebar from "../OrgSettingsSidebar";
import TableHeaders from "../../../components/table/TableHeaders";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import Pagination from "../../../components/pagination/Pagination";
import SearchInput from "../../../components/search-input/SearchInput";

// Css
import "../OrgSettings.scss";

const tableTitle = [
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Email Id",
    },
  },
  {
    title: {
      displayName: "Phone No.",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
// ----------------------------------------------------------------------------
function TableActionsSection({ searchText, setSearchText }) {
  const dispatch = useDispatch();

  // labels
  const TXT_PLACEHOLDER_SEARCH = "Search Users";

  // Functions
  function onClickSearch() {
    dispatch(getOrgAllUsers(searchText));
  }

  function onSearchTextChange(e) {
    setSearchText(e.target.value);
  }

  return (
    <div className="table-header">
      <SearchInput
        placeholder={TXT_PLACEHOLDER_SEARCH}
        onClickSearch={onClickSearch}
        onTextChange={onSearchTextChange}
      />
    </div>
  );
}

function UsersListTable({
  orgUsersList,
  orgUsersPagination,
  onClickPagination,
}) {
  const { totalCount, pageNo, pageSize } = orgUsersPagination;

  // Selector
  const isLoading = useSelector((state) => state.orgUserManage.loading);

  // Check Page loading
  if (isLoading) {
    return <LoadingData dataType="User List" />;
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <TableHeaders tableHeaders={tableTitle} headerClass={"thead"} />
        <tbody>
          {orgUsersList.map((user) => {
            const { id, firstName, lastName, emailId, phoneNo } = user;
            const statusText = user.isEnabled ? "Active" : "InActive";
            return (
              <tr key={id}>
                <td>{`${firstName} ${lastName}`}</td>
                <td>{formatText(emailId)}</td>
                <td>{formatText(phoneNo)}</td>
                <td>{statusText}</td>
                <td>
                  <Link
                    to={constructRedirectPath(
                      RedirectTo.orgUserManageUrl.replace(":userId", id)
                    )}
                  >
                    Manage
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Table-pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={onClickPagination}
      />
    </div>
  );
}

/**
 * Page
 */
export default function OrgUsersPage() {
  const dispatch = useDispatch();

  // State
  const [searchText, setSearchText] = useState("");

  // Selector
  const orgUsersList = useSelector((state) => state.orgUserManage.orgUserList);
  const orgUsersPagination = useSelector(
    (state) => state.orgUserManage.orgUserPagination
  );

  useEffect(() => {
    dispatch(getOrgAllUsers());
  }, [dispatch]);

  // Function
  function onClickPagination(pageNumber, pageSize) {
    dispatch(getOrgAllUsers(searchText, pageNumber, pageSize));
  }

  const pageTitle = "Org :: Users & Roles";
  const pageActions = (
    <Link
      className="btn btn-primary"
      to={constructRedirectPath(RedirectTo.orgUserCreateUrl)}
    >
      Create new User
    </Link>
  );

  return (
    <>
      <OrgSettingsSidebar activeIdx={2} />

      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} actions={pageActions} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          {/** Table Header actions */}
          <TableActionsSection
            searchText={searchText}
            setSearchText={setSearchText}
          />

          {/** Table */}
          <UsersListTable
            orgUsersList={orgUsersList}
            orgUsersPagination={orgUsersPagination}
            onClickPagination={onClickPagination}
          />
        </div>
        {/** */}
      </div>
    </>
  );
}
