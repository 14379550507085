import React from "react";

function FormInput(props) {
  // action="javascript:void(-1)" :: to remove the reloading of page
  // on pressing "Enter button" for "FormInput"
  return (
    <form className="form-inline" action="javascript:void(-1)">
      <div className="form-group">
        <label id={props.content}>{props.displayName}</label>
        {props.children}
      </div>
    </form>
  );
}

function Input(props) {
  const {
    id,
    onChange = () => {},
    placeHolder,
    value,
    disabled,
    inputBoxStyle = "",
    className = "",
  } = props || {};

  function handleChange(event) {
    onChange(id, event.target.value);
  }

  const inputBoxClassName = inputBoxStyle ? inputBoxStyle : "form-control";

  return (
    <input
      type="text"
      className={`${inputBoxClassName} ${className}`}
      placeholder={placeHolder}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

export { Input, FormInput };
