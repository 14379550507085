import { ZonesAndSubZones } from "../constants/action-constants/ZonesAndSubZonesActionConstants";

const initialState = {
  // Zones
  zones: [],
  zonesPagination: {},
  zonesLoading: false,
  zonesError: "",

  // Selected Region and Zones Map
  selectedRegionAndZonesMap: {},

  frequentlyOptedLocations: [],
  frequentlyOptedLocationsPagination: {},
  frequentlyOptedLocationsLoading: false,
  frequentlyOptedLocationsError: "",
};

// remove keys from object whose values is empty array
function filterRegionAndZonesMap(regionAndZonesMap = {}) {
  const filteredMap = Object.keys(regionAndZonesMap)
    .filter(
      (key) =>
        Array.isArray(regionAndZonesMap[key]) &&
        regionAndZonesMap[key].length != 0
    )
    .reduce((acc, key) => {
      acc[key] = regionAndZonesMap[key];
      return acc;
    }, {});

  return filteredMap;
}

// update Region, Zones And SubZones Map
function updateRegionAndZonesAndSubZonesMap(
  regionAndZonesMap = {},
  zone = {},
  isSelect = true // key used for "select = true" & "unselect = false"
) {
  const { cityId, zoneId } = zone || {};

  const clonedRegionAndZonesMap = { ...regionAndZonesMap };
  const existingRegionIds = Object.keys(clonedRegionAndZonesMap);

  // append region in map
  if (!existingRegionIds.includes(cityId)) {
    clonedRegionAndZonesMap[cityId] = [];
  }

  const selectedRegionToAddOrRemoveZone = clonedRegionAndZonesMap[cityId] || [];
  const clonedSelectedRegionToAddOrRemoveZone = [
    ...selectedRegionToAddOrRemoveZone,
  ];
  const existingZoneIds = clonedSelectedRegionToAddOrRemoveZone.map(
    (zone) => zone.zoneId
  );
  const isZoneAlreadyPresent = existingZoneIds.includes(zoneId);

  const zoneIndex = clonedSelectedRegionToAddOrRemoveZone.findIndex(
    (zone) => zone.zoneId === zoneId
  );

  // update zones for "Select"
  if (isSelect) {
    // if zone not present then add the zone else update the zone
    if (!isZoneAlreadyPresent) {
      clonedSelectedRegionToAddOrRemoveZone.push(zone);
    } else {
      clonedSelectedRegionToAddOrRemoveZone[zoneIndex] = zone;
    }

    clonedRegionAndZonesMap[cityId] = clonedSelectedRegionToAddOrRemoveZone;
  }

  // update zones for "Un Select"
  if (!isSelect) {
    // only splice array when item is found
    if (zoneIndex > -1) {
      clonedSelectedRegionToAddOrRemoveZone.splice(zoneIndex, 1);
    }

    // update after removing zone
    clonedRegionAndZonesMap[cityId] = clonedSelectedRegionToAddOrRemoveZone;
  }

  // filter map by removing regions with no zones
  const filteredRegionsAndZonesMap = filterRegionAndZonesMap(
    clonedRegionAndZonesMap
  );

  // final return
  return filteredRegionsAndZonesMap;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ZonesAndSubZones.GET_ZONES:
      return {
        ...state,
        zonesLoading: true,
        zones: [],
        zonesPagination: {},
      };

    case ZonesAndSubZones.GET_ZONES_SUCCESS: {
      const { zones, pagination } = action.payload;
      return {
        ...state,
        zonesLoading: false,
        zones,
        zonesPagination: pagination,
      };
    }

    case ZonesAndSubZones.GET_ZONES_FAILURE:
      return {
        ...state,
        zonesLoading: false,
        zonesError: action.payload,
      };

    // Select & unSelect Zone
    case ZonesAndSubZones.SELECT_ZONE: {
      const { zone } = action.payload;

      const updatedRegionAndZonesMap = updateRegionAndZonesAndSubZonesMap(
        state.selectedRegionAndZonesMap,
        zone,
        true
      );

      return {
        ...state,
        selectedRegionAndZonesMap: updatedRegionAndZonesMap,
      };
    }

    case ZonesAndSubZones.UN_SELECT_ZONE: {
      const { zone } = action.payload;

      const updatedRegionAndZonesMap = updateRegionAndZonesAndSubZonesMap(
        state.selectedRegionAndZonesMap,
        zone,
        false
      );

      return {
        ...state,
        selectedRegionAndZonesMap: updatedRegionAndZonesMap,
      };
    }

    // Fetch frequently opted locations
    case ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS:
      return {
        ...state,
        frequentlyOptedLocationsLoading: true,
      };

    case ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS_SUCCESS: {
      const { frequentlyOptedLocations, pagination } = action.payload;
      return {
        ...state,
        frequentlyOptedLocationsLoading: false,
        frequentlyOptedLocations,
        frequentlyOptedLocationsPagination: pagination,
      };
    }

    case ZonesAndSubZones.GET_FREQUENTLY_OPTED_LOCATIONS_FAILURE:
      return {
        ...state,
        frequentlyOptedLocationsLoading: false,
        frequentlyOptedLocationsError: action.payload,
      };

    // clear selected Region & Zones map
    case ZonesAndSubZones.CLEAR_SELECTED_REGION_AND_ZONES_MAP: {
      return {
        ...state,
        selectedRegionAndZonesMap: {},
        zones: [],
        frequentlyOptedLocations: [],
      };
    }

    default:
      return state;
  }
};
